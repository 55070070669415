import axios from "axios";
import { CampaignPartnershipT } from "../types";

export type CampaignPartnershipUpdateAttributesT = {
  conversions?: string;
  price?: string;
  visits?: string;
};

export type updateCampaignPartnershipPropsT = {
  clientId: string;
  url: string;
  body: {
    campaign_partnership: CampaignPartnershipUpdateAttributesT;
  };
};

export const updateCampaignPartnership = async ({
  clientId,
  url,
  body,
}: updateCampaignPartnershipPropsT): Promise<CampaignPartnershipT> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .patch(url, body, config)
    .then((res) => res.data.campaign_partnership);
};
