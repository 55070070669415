import { CheckCircleFilled, FilterOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Badge, Button } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Drawer from "../../../../components/Drawer";
import FlexBox from "../../../../components/FlexBox";
import { designToken } from "../../../../helpers/antDesign";
import { getUrlWithQuery } from "../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { getMediaGroups } from "../../data-access/getMediaGroups";
import { OutputsFilterStateT } from "../../types";
import MediaGroupsContainer from "../media-groups/MediaGoupsContainer";
import Filter from "./Filter";

const FilterWrapper = styled(FlexBox)({
  justifyContent: "flex-end",
  margin: `${rem(-66)} ${rem(85)} ${rem(16)} 0`,
});

export type SectionTypeT = "cards" | "table";

type CardsT = {
  clientId: string;
};

const Cards: FC<CardsT> = ({ clientId }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [openedFilter, setOpenedFilter] = useState(false);
  const [filterState, setFilterState] = useState<
    OutputsFilterStateT | undefined
  >(undefined);
  const defaultFilterState: OutputsFilterStateT = { partnership_ids: [] };

  const { t } = useTranslate("brands.reportings.outputs");

  const {
    data: mediaGroups,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery(
    [QueryKeys.MEDIA_GROUPS, "cards", clientId],
    () =>
      getMediaGroups({
        clientId,
        apiUrl: getUrlWithQuery(getApiUrl(ApiRoutesE.MEDIA_GROUPS), {
          ...filterState,
        }),
      }),
    {
      retry: false,
      onSuccess: () => setOpenedFilter(false),
    },
  );

  useEffect(() => {
    if (!firstLoad) {
      refetch();
      setFilterState(undefined);
      return;
    }

    setFirstLoad(false);
  }, [clientId]);

  return (
    <>
      <FilterWrapper>
        <Badge
          count={
            filterState !== undefined &&
            JSON.stringify(filterState) !==
              JSON.stringify(defaultFilterState) ? (
              <CheckCircleFilled style={{ color: designToken["green-6"] }} />
            ) : undefined
          }
          color="green"
        >
          <Button
            onClick={() => setOpenedFilter(true)}
            icon={<FilterOutlined />}
          >
            {t("filter.title")}
          </Button>
        </Badge>
      </FilterWrapper>
      <MediaGroupsContainer
        clientId={clientId}
        mediaGroups={mediaGroups}
        loading={isRefetching || isLoading}
        queryStack={QueryKeys.MEDIA_GROUPS}
        addedQueryStack={QueryKeys.MEDIA_GROUPS_ADDED}
      />

      <Drawer
        open={openedFilter}
        title="Filter"
        onClose={() => setOpenedFilter(false)}
        onConfirm={{
          handle: refetch,
          label: t("filter.confirm"),
        }}
        // extraLeft={
        //   <BlueLink type="grey" text={t("clear")} onClick={clearFilter} />
        // }
      >
        <Filter setFiliterState={setFilterState} clientId={clientId} />
      </Drawer>
    </>
  );
};

export default Cards;
