import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import CheckBigIcon from "../../../../../ui/CheckBigIcon";
import { contentTypes, outputsTypeIcons } from "../../../dataConstants";
import { MediaGroupKindT } from "../../../types";
import FilterSection from "../../../ui/FilterSection";

type FilterPostTypeT = {
  postTypeState: MediaGroupKindT[];
  setPostTypeState: Dispatch<SetStateAction<MediaGroupKindT[]>>;
};

const FilterPostType: FC<FilterPostTypeT> = ({
  postTypeState,
  setPostTypeState,
}) => {
  const { t } = useTranslate("brands.reportings.outputs.filter");

  const setStateHandle = (key: MediaGroupKindT): void => {
    setPostTypeState((prevState) => {
      if (prevState) {
        const exists = prevState.some((item) => item === key);

        if (exists) {
          return prevState.filter((item) => item !== key);
        }

        return [...prevState, key];
      }

      return prevState;
    });
  };

  return (
    <FilterSection
      label={t("post_type")}
      customWidth={CustomWidthE.full}
      withWrapper={true}
    >
      <FlexBox customWidth="100%" justifyContent="space-between">
        {contentTypes.map((type, index) => (
          <CheckBigIcon
            key={type}
            icon={outputsTypeIcons[type]}
            active={postTypeState.some((item) => item === type)}
            label={t(`labels.${type}`)}
            onClickHandle={() => setStateHandle(type)}
            marginRight={index !== contentTypes.length - 1}
          />
        ))}
      </FlexBox>
    </FilterSection>
  );
};

export default FilterPostType;
