import * as React from "react";
import { FC, useContext, useState } from "react";
import Button from "../../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import Modal from "../../../../../../components/Modal";
import NoResults from "../../../../../../components/NoResults";
import Paragraph from "../../../../../../components/Paragraph";
import Spacer from "../../../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../../features/Placeholders/PlaceholderLoader";
import { useLocalSort } from "../../../../../../hooks/useLocalSort";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import Icon, { IconTypeE } from "../../../../../../ui/icons/Icon";
import TableContentWrapper from "../../../../../../ui/table/TableContentWrapper";
import { CampaignModalContext } from "../../../../context-providers/CampaignModalContextProvider";
import { CampaignPartnershipT, CampaignT } from "../../../../types";
import RemoveModal from "./RemoveModal";
import TableHead from "./TableHead";
import TableRow from "./TableRow";

type CampaignPartershipsTableT = {
  clientId: string;
  dataIsLoading: boolean;
  campaignPartnerships: CampaignPartnershipT[] | undefined;
  campaign: CampaignT | undefined;
};

export type CampaignPartnershipToRemoveT = {
  id: string;
  campaignId: string;
  removed: boolean;
};

export type CampaignPartnershipPriceT = {
  id: string;
  campaignId: string;
  price: string | null;
};

const CampaignPartershipsTable: FC<CampaignPartershipsTableT> = ({
  clientId,
  dataIsLoading,
  campaignPartnerships,
  campaign,
}) => {
  const [campaignPartnershipToRemove, setCampaignPartnershipToRemove] =
    useState<CampaignPartnershipToRemoveT | undefined>(undefined);

  const {
    sortedCollection: sortedCampaignPartnerships,
    setSortingOptions,
    sortingOptions,
  } = useLocalSort<CampaignPartnershipT>(
    "media_groups_count",
    campaignPartnerships,
  );

  const { setCampaignModal } = useContext(CampaignModalContext);
  const { t } = useTranslate("brands.reportings.campaigns.partnerships");

  const getTableContent = (): JSX.Element => {
    if (
      campaign &&
      sortedCampaignPartnerships &&
      sortedCampaignPartnerships.length > 0
    ) {
      return (
        <>
          <TableHead
            sortingOptions={sortingOptions}
            setSortingOptions={setSortingOptions}
          />
          {sortedCampaignPartnerships.map((item, index) => (
            <TableRow
              key={item.id}
              campaignId={campaign.id}
              campaignPartnership={item}
              clientId={clientId}
              last={sortedCampaignPartnerships.length - 1 === index}
              hide={
                !!(
                  campaignPartnershipToRemove &&
                  campaignPartnershipToRemove.id === item.id &&
                  campaignPartnershipToRemove.removed
                )
              }
              setCampaignPartnershipToRemove={setCampaignPartnershipToRemove}
            />
          ))}
        </>
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return (
      <TableContentWrapper blocksAreRows={true}>
        <NoResults illustrationSize={350} message={t("empty_title")} />
        <Spacer direction="vertical" />
        <Paragraph align="center" paragraph={t("empty_text")} />
        <Spacer direction="vertical" />
        {campaign && (
          <Button
            onClick={() => setCampaignModal({ ...campaign, type: "edit" })}
          >
            <Icon icon={IconTypeE.plus} size="mini" />
            <Spacer size="micro" />
            {t("new")}
          </Button>
        )}
      </TableContentWrapper>
    );
  };

  return (
    <>
      {getTableContent()}

      <Modal
        visible={!!campaignPartnershipToRemove}
        close={() => setCampaignPartnershipToRemove(undefined)}
        slim={true}
        renderChildren={() => (
          <RemoveModal
            clientId={clientId}
            campaignPartnershipToRemove={campaignPartnershipToRemove}
            setCampaignPartnershipToRemove={setCampaignPartnershipToRemove}
          />
        )}
      />
    </>
  );
};

export default CampaignPartershipsTable;
