import { QueryClient } from "react-query";
import { ClientT } from "../../entry-files/types";
import { QueryKeys } from "../../helpers/queryKeys";

type deleteClientsMutationT = (
  queryClient: QueryClient,
  clientId: string,
  afterMutationHandle?: () => void,
) => void;

export const deleteClientsMutation: deleteClientsMutationT = (
  queryClient,
  clientId,
  afterMutationHandle,
) => {
  queryClient.setQueryData<ClientT[] | undefined>(
    QueryKeys.CLIENTS,
    (prevState) => {
      if (prevState) {
        const index = prevState.findIndex((client) => client.id === clientId);

        if (index !== -1) {
          prevState.splice(index, 1);
        }

        return prevState;
      }

      return [];
    },
  );

  afterMutationHandle && afterMutationHandle();
};
