import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import FilesUploadArea from "../../../features/files-upload/FilesUploadArea";
import { PendingFileT } from "../../../hooks/usePendingFile";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";

const Wrapper = styled(FlexBox)({
  position: "absolute",
  right: rem(12),
  top: rem(12),
  zIndex: 1,
});

const ImageCount = styled(FlexBox)({
  position: "absolute",
  left: rem(12),
  top: rem(16),
  zIndex: 1,
});

const Block = styled(FlexBox)({
  width: rem(20),
  height: rem(20),
  borderRadius: rem(2),
  backgroundColor: theme.color.whiteColor,
});

const BackgroundBlock = styled(Block)({
  position: "absolute",
  top: rem(-4),
  right: rem(-4),
  opacity: 0.7,
  zIndex: -1,
});

type ImagesOptionsT = {
  imagesCount: number;
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  isStory: boolean;
};

const ImagesOptions: FC<ImagesOptionsT> = ({
  imagesCount,
  setPendingFiles,
  isStory,
}) => {
  const { t } = useTranslate("brands.reportings.media_groups");

  return (
    <>
      {imagesCount > 1 && (
        <ImageCount>
          <BackgroundBlock />
          <Block>
            <Paragraph
              paragraph={`${imagesCount}`}
              fontWeight={600}
              paragraphSize="small"
            />
          </Block>
        </ImageCount>
      )}

      {(!isStory || (isStory && imagesCount < 1)) && (
        <Wrapper>
          <Button
            type={ButtonTypeE.grey}
            size={ButtonSizeE.small}
            tooltip={t("upload_file")}
            whiteSpace="normal"
          >
            <FilesUploadArea
              setPendingFiles={setPendingFiles}
              allowMultipleUpload={!isStory}
            />
            <Icon icon={IconTypeE.upload} />
          </Button>
        </Wrapper>
      )}
    </>
  );
};

export default ImagesOptions;
