import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { MediaT, UpdatedMediaDatatT } from "../types";

type UpdatedMediaPropsT = {
  id: string;
  clientId: string;
  updatedMediaData: {
    media: UpdatedMediaDatatT;
  };
};

type UpdateMediaPayloadT = {
  media: MediaT;
};

type updateMediaT = (props: UpdatedMediaPropsT) => Promise<UpdateMediaPayloadT>;
export const updateMedia: updateMediaT = async ({
  id,
  updatedMediaData,
  clientId,
}) => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .put(getApiUrl(ApiRoutesE.MEDIAS_ITEM, id), updatedMediaData, config)
    .then((res) => res.data);
};
