import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Panel from "../../../../../components/Panel";
import Paragraph from "../../../../../components/Paragraph";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { CampaignPartnershipT, CampaignT, MediaGroupT } from "../../../types";
import CampaignPartershipsTable from "./campagin-partnerships/CampaignPartershipsTable";
import MediaGroupTable from "./campaign-media-groups/MediaGroupTable";
import Graph from "./Graph";
import MetricCard from "./MetricCard";

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  gap: rem(15),
});

const MetricsWrapper = styled(FlexBox)({
  gap: rem(20),
});

const TableTabWrapper = styled(FlexBox)({
  width: "100%",
  borderBottom: `1px solid ${theme.color.greyLightColor}`,
  paddingLeft: rem(18),
});

const Tab = styled(FlexBox)<{ active: boolean }>(({ active }) => ({
  padding: `${rem(18)} ${rem(8)}`,
  borderBottom: active ? `2px solid ${theme.color.yellowColor}` : "none",
  fontWeight: active ? 600 : 400,
  cursor: "pointer",

  ["&:hover"]: {
    backgroundColor: theme.color.greyLightColor,
  },
}));

type ActiveTableT = "campaignPartnerships" | "outputs";

type DashboardT = {
  clientId: string;
  dataIsLoading: boolean;
  campaignPartnerships: CampaignPartnershipT[];
  mediaGroups: MediaGroupT[];
  campaign: CampaignT;
};

const Dashboard: FC<DashboardT> = ({
  clientId,
  dataIsLoading,
  campaignPartnerships,
  mediaGroups,
  campaign,
}) => {
  const [activeTable, setActiveTable] = useState<ActiveTableT>(
    "campaignPartnerships",
  );
  const { t } = useTranslate("brands.reportings.campaigns.analytics");

  const medias = mediaGroups.flatMap((group) => group.medias);

  return (
    <Wrapper>
      <MetricsWrapper>
        <MetricCard
          medias={medias}
          metric="impressions"
          label={t("total_matrics.impressions")}
        />
        <MetricCard
          medias={medias}
          metric="likes"
          label={t("total_matrics.likes")}
        />
        <MetricCard
          medias={medias}
          metric="comments"
          label={t("total_matrics.comments")}
        />
      </MetricsWrapper>
      <Graph clientId={clientId} campaignId={campaign.id} />
      <Panel flexDirection="column" position="relative" alignItems="flex-start">
        <TableTabWrapper justifyContent="flex-start">
          <Tab
            active={activeTable === "campaignPartnerships"}
            onClick={() => setActiveTable("campaignPartnerships")}
          >
            <Paragraph paragraph={t("table.tabs.partnerships")} />
          </Tab>
          <Tab
            active={activeTable === "outputs"}
            onClick={() => setActiveTable("outputs")}
          >
            <Paragraph paragraph={t("table.tabs.media_groups")} />
          </Tab>
        </TableTabWrapper>
        {activeTable === "campaignPartnerships" ? (
          <CampaignPartershipsTable
            clientId={clientId}
            campaignPartnerships={campaignPartnerships}
            campaign={campaign}
            dataIsLoading={dataIsLoading}
          />
        ) : (
          <MediaGroupTable mediaGroups={mediaGroups} />
        )}
      </Panel>
    </Wrapper>
  );
};

export default Dashboard;
