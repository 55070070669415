import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { useQuery } from "react-query";
import Container from "../../../../components/Container";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import SubMenuContainer from "../../../../components/SubMenuContainer";
import { getClients } from "../../../../data-access/getClients";
import Clients from "../../../../features/clients/Clients";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { NavigationKeyE, useRouterNavigate } from "../../router";

const Wrapper = styled(FlexBox)({
  padding: `${rem(20)} ${rem(12)} 0 ${rem(12)}`,
});

const ClientsWrapper = styled(FlexBox)({
  width: "100%",
  justifyContent: "stretch",
  marginBottom: rem(20),
});

const NavigationItem = styled(FlexBox)<{ active: boolean }>(({ active }) => ({
  padding: `${rem(8)} 0`,
  cursor: "pointer",
  color: active ? theme.color.primaryColor : theme.color.blackColor,
  fontWeight: active ? 600 : 400,

  "&:hover": {
    fontWeight: 600,
  },
}));

const ReportingContainer: FC = ({ children }) => {
  const navigate = useRouterNavigate();
  const path = window.location.pathname;

  const { t } = useTranslate("brands.reportings.partnerships");

  const { data: clients, isLoading: isClientsLoading } = useQuery(
    QueryKeys.CLIENTS,
    getClients,
  );

  return (
    <Container
      renderSubmenu={() => (
        <SubMenuContainer>
          <Wrapper
            flexDirection="column"
            customWidth={CustomWidthE.full}
            alignItems="flex-start"
          >
            <ClientsWrapper>
              <Clients clients={clients || []} isLoading={isClientsLoading} />
            </ClientsWrapper>
            <NavigationItem
              onClick={() => navigate(NavigationKeyE.Partnerships)}
              active={path.match(NavigationKeyE.Partnerships) !== null}
            >
              {t("navigation.influencers")}
            </NavigationItem>
            <NavigationItem
              onClick={() => navigate(NavigationKeyE.MediaGroups, "cards")}
              active={path.match(NavigationKeyE.MediaGroups) !== null}
            >
              {t("navigation.outputs")}
            </NavigationItem>
            <NavigationItem
              onClick={() => navigate(NavigationKeyE.Campaigns)}
              active={path.match(NavigationKeyE.Campaigns) !== null}
            >
              {t("navigation.campaigns")}
            </NavigationItem>
          </Wrapper>
        </SubMenuContainer>
      )}
    >
      {children}
    </Container>
  );
};

export default ReportingContainer;
