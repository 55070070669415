import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export const removeMedia = async (
  mediaId: string,
  clientId: string,
): Promise<{}> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .delete(getApiUrl(ApiRoutesE.MEDIA_DELETE, mediaId), config)
    .then((res) => res.data);
};
