import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ApprovalRequestWithAuthorT } from "../../../types";
import { PartnershipT } from "../../types";

export const createApprovalRequestMutation = (
  queryClient: QueryClient,
  data: ApprovalRequestWithAuthorT,
  partnership: PartnershipT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<PartnershipT[] | undefined>(
    QueryKeys.PARTNERSHIPS,
    (prevState) => {
      if (prevState) {
        const currentPartnershipIndex = prevState.findIndex(
          (item) => item.id === partnership.id,
        );

        if (currentPartnershipIndex > -1) {
          const currentData = prevState[currentPartnershipIndex];
          const updatedPartnership = {
            ...currentData,
            approval_requests: [...currentData.approval_requests, data],
          };
          const updatedState = [...prevState];

          updatedState[currentPartnershipIndex] = updatedPartnership;
          return updatedState;
        }
      }

      return prevState;
    },
  );

  afterMutationHandle && afterMutationHandle();
};
