import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { useCardCoverHeight } from "../../../../../hooks/useCardCoverHeight";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { EmptyStateWrapper } from "../../../../../ui/UploadEmptyStateArea";
import { COVER_OPTIONS } from "./CoverImages";

const Wrapper = styled(FlexBox)({
  margin: `0 ${rem(4)}`,
  width: `calc(100% - ${rem(8)})`,
});

const Content = styled(FlexBox)({
  padding: `0 ${rem(12)}`,
});

type EmptyStateT = {
  wrapperWidth?: number;
};

const NewCardEmptyState: FC<EmptyStateT> = ({ wrapperWidth }) => {
  const { t } = useTranslate("brands.reportings.media_groups.empty_state");

  const { coverHeight } = useCardCoverHeight(
    wrapperWidth,
    COVER_OPTIONS.defaltHeight,
    COVER_OPTIONS.aspectRatio,
  );

  return (
    <Wrapper>
      <EmptyStateWrapper height={coverHeight}>
        <Content flexDirection="column">
          <Icon
            icon={IconTypeE.upload}
            size={"monster"}
            color={theme.color.textGreyDarkColor}
          />
          <Spacer direction="vertical" size="small" />
          <Heading
            heading={t("add_files")}
            headingType={HeadingTypeT.h4}
            spaceBottom={6}
            color={theme.color.textGreyDarkColor}
          />
          <Paragraph
            paragraph={t("description")}
            align="center"
            paragraphSize="micro"
          />
          <Spacer direction="vertical" size="small" />
          <Paragraph
            paragraph={t("supported_formats")}
            align="center"
            paragraphSize="micro"
            color={theme.color.textGreyColor}
          />
          <Paragraph
            paragraph={t("formats")}
            align="center"
            paragraphSize="micro"
            color={theme.color.textGreyColor}
          />
          <Spacer direction="vertical" size="small" />
          <Button type={ButtonTypeE.outline} size={ButtonSizeE.micro}>
            {t("browse")}
          </Button>
        </Content>
      </EmptyStateWrapper>
    </Wrapper>
  );
};

export default NewCardEmptyState;
