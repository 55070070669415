import { Button, Table as AntTable } from "antd";
import { ColumnsType } from "antd/es/table";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import NoResults from "../../../../components/NoResults";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Popover from "../../../../components/Popover";
import Spacer from "../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { getDateTime } from "../../../../helpers/date";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import TableContentWrapper from "../../../../ui/table/TableContentWrapper";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { CompetitiveAnalysisT } from "../../types";

export type PartnershipToRemoveT = {
  id: string;
  removed: boolean;
};

interface DataType {
  key: string;
  analysis: CompetitiveAnalysisT;
}

type TableT = {
  dataIsLoading: boolean;
  onAddNewCampaignClickHandle: () => void;
  competitiveAnalyses?: CompetitiveAnalysisT[];
};

const Table: FC<TableT> = ({
  dataIsLoading,
  onAddNewCampaignClickHandle = () => {},
  competitiveAnalyses,
}) => {
  const navigate = useRouterNavigate();

  const { t } = useTranslate("brands.competitive_analyses.index");

  const showStatus = (
    competitiveAnalysis: CompetitiveAnalysisT,
  ): JSX.Element => {
    switch (competitiveAnalysis.status) {
      case "no_data":
        return (
          <>
            <Paragraph paragraph={t("no_data")} />
            <Spacer size="mini" />
            <Popover
              renderTrigger={() => (
                <FlexBox>
                  <Icon
                    icon={IconTypeE.infoCircle}
                    color={theme.color.textGreyColor}
                    size="small"
                    zIndex={"auto"}
                  />
                </FlexBox>
              )}
              content={t("no_data_info_html")}
              width={200}
            />
          </>
        );
      case "pending":
        return <Paragraph paragraph={t("pending")} />;
      case "finished":
        return (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                NavigationKeyE.CompetitiveAnalyses,
                `${competitiveAnalysis.id}/influencers`,
              )
            }
          >
            {t("show")}
          </Button>
        );
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.handle"),
      dataIndex: "title",
      render: (_, record) => record.analysis.handle,
      sorter: (a, b) => a.analysis.handle.localeCompare(b.analysis.handle),
    },
    {
      title: t("table.created_at"),
      dataIndex: "created_at",
      render: (_, record) =>
        getDateTime(record.analysis.created_at, "shortDate"),
      sorter: (a, b) =>
        a.analysis.created_at.localeCompare(b.analysis.created_at),
    },
    {
      title: t("table.results"),
      dataIndex: "handles",
      render: (_, record) => record.analysis.results.length,
    },
    {
      title: undefined,
      dataIndex: "action",
      width: "20%",
      render: (_, record) => showStatus(record.analysis),
    },
  ];

  const getTableContent = (): JSX.Element => {
    if (competitiveAnalyses && competitiveAnalyses.length > 0) {
      const data: DataType[] = competitiveAnalyses.map((analysis) => ({
        key: analysis.id,
        analysis: analysis,
      }));

      return (
        <AntTable
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return (
      <TableContentWrapper blocksAreRows={true}>
        <NoResults illustrationSize={350} message={t("no_results.heading")} />
        <Spacer direction="vertical" />
        <Paragraph align="center" paragraph={t("no_results.paragraph")} />
        <Spacer direction="vertical" />
        {/* <Button onClick={onAddNewCampaignClickHandle}>
          <Icon icon={IconTypeE.plus} size="mini" />
          <Spacer size="micro" />
          {t("no_results.create_first")}
        </Button> */}
      </TableContentWrapper>
    );
  };

  return (
    <>
      <Panel flexDirection="column" position="relative">
        {getTableContent()}
      </Panel>
    </>
  );
};

export default Table;
