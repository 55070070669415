import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import Button, { ButtonSizeE } from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Spacer from "../../../../components/Spacer";
import { ClientsContext } from "../../../../context-providers/ClientsContextProvider";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { CampaignModalContext } from "../../context-providers/CampaignModalContextProvider";
import { getCampaigns } from "../../data-access/getCampaigns";
import ReportingContainer from "../components/ReportingContainer";
import Table from "./table/Table";

const CampaignsContainer: FC = () => {
  const { setCampaignModal } = useContext(CampaignModalContext);
  const { currentClientId } = useContext(ClientsContext) as {
    currentClientId: string;
  };

  const { t } = useTranslate("brands.reportings.campaigns");

  const {
    data: campaigns,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(QueryKeys.CAMPAIGNS, () => getCampaigns(currentClientId));

  useEffect(() => {
    refetch();
  }, [currentClientId]);

  return (
    <ReportingContainer>
      <FlexBox
        customWidth={CustomWidthE.full}
        alignItems="flex-end"
        flexDirection="column"
      >
        <Button
          onClick={() => setCampaignModal("empty")}
          size={ButtonSizeE.small}
        >
          <Icon icon={IconTypeE.plus} size="small" />
          <Spacer size={"mini"} />
          {t("create.title")}
        </Button>
        <Spacer direction="vertical" />
      </FlexBox>
      <Table
        clientId={currentClientId}
        campaigns={campaigns}
        dataIsLoading={isLoading || isRefetching}
        onAddNewCampaignClickHandle={() => setCampaignModal("empty")}
      />
    </ReportingContainer>
  );
};

export default CampaignsContainer;
