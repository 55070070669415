import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { SessionT, SubscriptionEntitlementFeaturesT } from "../../../types";

type updateCurrentUsageMutationT = (
  queryClient: QueryClient,
  feature_id: SubscriptionEntitlementFeaturesT,
  increment?: number,
) => void;

export const updateCurrentUsageMutation: updateCurrentUsageMutationT = (
  queryClient,
  feature_id,
  increment = 1,
): void => {
  queryClient.setQueryData<SessionT | undefined>(
    QueryKeys.SESSION,
    (prevState) => {
      if (prevState) {
        const index =
          prevState.subscription?.current_period_usage?.usage?.findIndex(
            (usage) => usage.feature_id === feature_id,
          );

        if (
          index &&
          index > -1 &&
          prevState.subscription?.current_period_usage?.usage
        ) {
          const used =
            prevState.subscription.current_period_usage.usage[index].used;

          const newUsage = {
            ...prevState.subscription.current_period_usage.usage[index],
            used: used + increment,
          };

          const newUsages =
            prevState.subscription.current_period_usage.usage.map(
              (usage, i) => {
                if (i === index) {
                  return newUsage;
                }

                return usage;
              },
            );

          return {
            ...prevState,
            subscription: {
              ...prevState.subscription,
              current_period_usage: {
                ...prevState.subscription.current_period_usage,
                usage: newUsages,
              },
            },
          };
        }
      }

      return prevState;
    },
  );
};
