import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import { Carousel } from "react-responsive-carousel";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { theme } from "../../../../../styling/theme";
import CarouselLightBoxArrow from "../../../../../ui/carousel/CarouselLightBoxArrow";
import { ClearCarouselWrapper } from "../../../../../ui/carousel/ClearCarouselWrapper";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { removeMediaFileMutation } from "../../../data-access/mutation/removeMediaFileMutation";
import { removeMediaImage } from "../../../data-access/removeMediaImage";
import { MediaFileT, QueryStackT } from "../../../types";
import LightBoxItem from "./LightBoxItem";

export type MediaContentStateT = {
  files: MediaFileT[];
  mediaId: string;
  partnershipId: string;
  parentCurrentImage: number;
  setParentCurrentImage: Dispatch<SetStateAction<number>>;
  queryStack: QueryStackT;
};

type MediaContentModalT = {
  mediaContentState: MediaContentStateT;
  close: () => void;
};

const MediaContentModal: FC<MediaContentModalT> = ({
  mediaContentState,
  close,
}) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const { clientId } = useContext(MediaGroupsHelperContext);
  const { t } = useTranslate("brands.reportings.media_groups.buttons");
  const {
    files,
    mediaId,
    parentCurrentImage,
    setParentCurrentImage,
    queryStack,
  } = mediaContentState;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    () => removeMediaImage(`${files[currentImage].id}`, clientId),
    {
      onSuccess: (data) => {
        const { mediaFileId } = data;

        if (files.length === 1 || currentImage === 0) {
          close();
        }

        removeMediaFileMutation(
          queryClient,
          { mediaFileId, mediaId },
          queryStack,
          () => files.splice(currentImage, 1),
        );

        if (parentCurrentImage === currentImage) {
          setParentCurrentImage(0);
        }

        setCurrentImage((prevState) => (prevState < 0 ? prevState - 1 : 0));
      },
      onSettled: () => {
        setRemoveConfirm(false);
      },
    },
  );

  useEffect(() => {
    if (parentCurrentImage !== currentImage) {
      setCurrentImage(parentCurrentImage);
    }
  }, [parentCurrentImage]);

  const removeOnClickHandle = (): void => {
    if (removeConfirm) {
      mutate();

      return;
    }

    setRemoveConfirm(true);
  };

  return (
    <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
      <FlexBox justifyContent="space-between" customWidth={CustomWidthE.full}>
        <FlexBox customWidth={CustomWidthE.quarter} justifyContent="flex-start">
          <Button
            onClick={removeOnClickHandle}
            type={removeConfirm ? ButtonTypeE.error : ButtonTypeE.grey}
            size={ButtonSizeE.micro}
            disabled={isLoading}
          >
            <Icon
              icon={IconTypeE.bin}
              size="small"
              color={
                removeConfirm ? theme.color.whiteColor : theme.color.textColor
              }
            />
            <Spacer size="mini" />
            {t(removeConfirm ? "confirm_remove_image" : "remove_image")}
          </Button>
        </FlexBox>
        <Paragraph paragraph={`${currentImage + 1} / ${files.length}`} />
        <FlexBox customWidth={CustomWidthE.quarter} justifyContent="flex-end">
          <Button
            onClick={() => {
              removeConfirm && setRemoveConfirm(false);
              close();
            }}
            type={ButtonTypeE.clear}
            size={ButtonSizeE.micro}
          >
            <Icon icon={IconTypeE.cross} size="mini" />
          </Button>
        </FlexBox>
      </FlexBox>
      <Spacer direction="vertical" size="micro" />
      <ClearCarouselWrapper flexDirection="column">
        <Carousel
          showArrows={files.length > 1}
          showThumbs={false}
          emulateTouch={true}
          useKeyboardArrows={true}
          selectedItem={currentImage}
          onChange={(index) => setCurrentImage(index)}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <CarouselLightBoxArrow
              disable={hasPrev}
              visible={files.length > 1}
              onClickHandle={() => {
                removeConfirm && setRemoveConfirm(false);
                clickHandler();
              }}
              side="left"
            />
          )}
          renderArrowNext={(clickHandler, hasNext) => (
            <CarouselLightBoxArrow
              disable={hasNext}
              visible={files.length > 1}
              onClickHandle={() => {
                removeConfirm && setRemoveConfirm(false);
                clickHandler();
              }}
              side="right"
            />
          )}
        >
          {files.map((file) => (
            <LightBoxItem key={file.id} url={file.file_url} />
          ))}
        </Carousel>
      </ClearCarouselWrapper>
    </FlexBox>
  );
};

export default MediaContentModal;
