import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { CampaignT } from "../../types";

export const removeCampaignMutation = (
  queryClient: QueryClient,
  campaignId: string,
  afterMutationHandle: () => void,
): void => {
  queryClient.setQueryData<CampaignT[] | undefined>(
    QueryKeys.CAMPAIGNS,
    (prevState) => {
      if (prevState) {
        return prevState.filter((item) => item.id !== campaignId);
      }

      return prevState;
    },
  );

  afterMutationHandle();
};
