import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export const getCampaignPPTX = async (
  clientId: string,
  id: string,
): Promise<{}> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .get(`${getApiUrl(ApiRoutesE.CAMPAIGN_EXPORT, id)}.pptx`, config)
    .then((res) => res.data);
};
