import styled from "@emotion/styled";
import { Button, Tooltip } from "antd";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Drawer from "../../../../components/Drawer";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { ClientsContext } from "../../../../context-providers/ClientsContextProvider";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { EmptyStatePlaceholder } from "../../../../ui/icons/PlaceHolders";
import { SubscriptionContext } from "../../context-providers/SubscriptionsContextProvider";
import { createPartnership } from "../../data-access/createPartnership";
import { getPartnerships } from "../../data-access/getPartnerships";
import { createPartnershipMutation } from "../../data-access/mutation/createPartnershipMutation";
import { updateCurrentUsageMutation } from "../../data-access/mutation/updateCurrentUsageMutation";
import LimitHeader from "../../ui/LimitHeader";
import ReportingContainer from "../components/ReportingContainer";
import AddNewModal from "./AddNewModal";
import Table from "./table/Table";

const EmptyStateWrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "50%",
  padding: rem(50),
  gap: rem(24),
});

const PartnershipsContainer: FC = () => {
  const [addNewModal, setAddNewModal] = useState<boolean>(false);
  const [instagramHandles, setInstagramHandles] = useState<string[]>([]);
  const [tiktokHandles, setTiktokHandles] = useState<string[]>([]);

  const { currentClientId: clientId } = useContext(ClientsContext) as {
    currentClientId: string;
  };

  const { session } = useContext(ApplicationContext);
  const { getFeatureUsage } = useContext(SubscriptionContext);

  const usage = getFeatureUsage(session, "reporting-influencers");
  const overLimit = usage
    ? instagramHandles.length + tiktokHandles.length + usage.used > usage.quota
    : false;

  const { t } = useTranslate("brands.reportings.partnerships");

  const { data, isLoading, isRefetching, refetch } = useQuery(
    QueryKeys.PARTNERSHIPS,
    () => getPartnerships(clientId),
  );

  const queryClient = useQueryClient();
  const { mutate, isLoading: isCreateLoading } = useMutation(
    () =>
      createPartnership(
        {
          partnership: {
            instagram_handles: instagramHandles.join(","),
            tiktok_handles: tiktokHandles.join(","),
          },
        },
        clientId,
      ),
    {
      onSuccess: (data) => {
        createPartnershipMutation(queryClient, data.partnerships, () =>
          setAddNewModal(false),
        );
        updateCurrentUsageMutation(
          queryClient,
          "reporting-influencers",
          instagramHandles.length + tiktokHandles.length,
        );
        setInstagramHandles([]);
        setTiktokHandles([]);
      },
    },
  );

  const submitButton = (): JSX.Element => {
    return (
      <Tooltip title={overLimit && t("form.overlimit")}>
        <Button
          type="primary"
          disabled={
            (instagramHandles.length === 0 && tiktokHandles.length === 0) ||
            isCreateLoading ||
            overLimit
          }
          onClick={() => mutate()}
          loading={isCreateLoading}
        >
          {t("form.submit")}
        </Button>
      </Tooltip>
    );
  };

  useEffect(() => {
    refetch();
  }, [clientId]);

  return (
    <ReportingContainer>
      {data && (
        <>
          <LimitHeader
            title={t("title")}
            onClickHandle={setAddNewModal}
            submitTitle={t("form.title")}
            entitlementFeature="reporting-influencers"
          />
          <Table
            clientId={clientId}
            partnerships={data}
            dataIsLoading={isLoading || isRefetching}
            emptyState={
              <EmptyStateWrapper>
                <EmptyStatePlaceholder />
                <Paragraph paragraph={t("form.empty_title")} fontWeight={600} />
                <AddNewModal
                  instagramHandles={instagramHandles}
                  tiktokHandles={tiktokHandles}
                  setInstagramHandles={setInstagramHandles}
                  setTiktokHandles={setTiktokHandles}
                  clientId={clientId}
                />
                {submitButton()}
              </EmptyStateWrapper>
            }
          />
        </>
      )}

      <Drawer
        open={addNewModal}
        title={t("form.title")}
        onClose={() => setAddNewModal(false)}
        footer={
          <FlexBox justifyContent="flex-end" gap={rem(12)}>
            <Button onClick={() => setAddNewModal(false)}>
              {t("form.cancel")}
            </Button>
            {submitButton()}
          </FlexBox>
        }
      >
        <AddNewModal
          instagramHandles={instagramHandles}
          tiktokHandles={tiktokHandles}
          setInstagramHandles={setInstagramHandles}
          setTiktokHandles={setTiktokHandles}
          clientId={clientId}
        />
      </Drawer>
    </ReportingContainer>
  );
};

export default PartnershipsContainer;
