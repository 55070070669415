import { QueryClient } from "react-query";
import { ClientT, SessionT } from "../../entry-files/types";
import { QueryKeys } from "../../helpers/queryKeys";

type updateClientsMutationT = (
  queryClient: QueryClient,
  data: ClientT,
  type: "update" | "create",
  afterMutationHandle?: () => void,
) => void;

export const updateClientsMutation: updateClientsMutationT = (
  queryClient,
  data,
  type,
  afterMutationHandle,
) => {
  queryClient.setQueryData<ClientT[] | undefined>(
    QueryKeys.CLIENTS,
    (prevState) => {
      if (prevState) {
        if (type === "update") {
          const index = prevState.findIndex((client) => client.id === data.id);

          if (index !== -1) {
            prevState[index] = data;
          } else {
            prevState.push(data);
          }

          return prevState;
        }

        if (type === "create") {
          return [...prevState, data];
        }
      }

      return [data];
    },
  );

  queryClient.setQueryData<SessionT | undefined>(
    QueryKeys.SESSION,
    (prevState) => {
      if (prevState) {
        const clients = queryClient.getQueryData<ClientT[] | undefined>(
          QueryKeys.CLIENTS,
        );

        if (clients) {
          return {
            ...prevState,
            clients: clients,
          };
        }
      }

      return prevState;
    },
  );

  afterMutationHandle && afterMutationHandle();
};
