import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { PartnershipT } from "../../types";
import { updateCurrentUsageMutation } from "./updateCurrentUsageMutation";

export const removePartnershipMutation = (
  queryClient: QueryClient,
  partnershipId: string,
): void => {
  queryClient.setQueryData<PartnershipT[] | undefined>(
    QueryKeys.PARTNERSHIPS,
    (prevState) => {
      if (prevState) {
        return prevState.filter((item) => item.id !== partnershipId);
      }

      return prevState;
    },
  );

  updateCurrentUsageMutation(queryClient, "reporting-influencers", -1);
};
