import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { getDateTime } from "../../../../../helpers/date";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import TableColumn from "../../../../../ui/table/TableColumn";
import { CampaignT } from "../../../types";

type CampaignInfoColumnT = {
  campaign: CampaignT;
};

const CampaignInfoColumn: FC<CampaignInfoColumnT> = ({ campaign }) => {
  const { title, from, to, media_groups_count, partnership_ids } = campaign;
  const { t } = useTranslate("brands.reportings.campaigns");

  const partnershipsCount = partnership_ids.length;

  return (
    <TableColumn
      columnWidth={CustomWidthE.custom40}
      padding={rem(16)}
      overflow="hidden"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
      cursor="inherit"
    >
      <FlexBox alignItems="flex-start" flexDirection="column" cursor="inherit">
        <Paragraph
          paragraph={title}
          fontWeight={600}
          paragraphSize="big"
          truncate={50}
          cursor="inherit"
        />
        <Spacer size={"mini"} direction="vertical" />
        <FlexBox>
          {(media_groups_count || media_groups_count === 0) && (
            <>
              <FlexBox title={t("outputs_count")}>
                <Icon
                  icon={IconTypeE.image}
                  color={theme.color.textGreyColor}
                  size="small"
                />
                <Spacer size="micro" />
                <Paragraph
                  paragraph={`${media_groups_count}`}
                  color={theme.color.textGreyColor}
                  paragraphSize="small"
                />
              </FlexBox>
              <Spacer />
            </>
          )}

          <FlexBox title={t("influencers_count")}>
            <Icon
              icon={IconTypeE.users}
              color={theme.color.textGreyColor}
              size="small"
            />
            <Spacer size="micro" />
            <Paragraph
              paragraph={`${partnershipsCount}`}
              color={theme.color.textGreyColor}
              paragraphSize="small"
            />
          </FlexBox>
          <Spacer />

          {from && to && (
            <FlexBox title={t("duration")}>
              <Icon
                icon={IconTypeE.calendar}
                color={theme.color.textGreyColor}
                size="small"
              />
              <Spacer size="micro" />
              <Paragraph
                paragraph={`${getDateTime(from, "shortDate")} - ${getDateTime(
                  to,
                  "shortDate",
                )}`}
                color={theme.color.textGreyColor}
                paragraphSize="small"
              />
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </TableColumn>
  );
};

export default CampaignInfoColumn;
