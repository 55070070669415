import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { TopBar } from "../../../../../ui/cards-ui/styles";
import Dropdown from "../../../../../ui/Dropdown";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { mergeMediaGroups } from "../../../data-access/mergeMediaGroups";
import { mergeMediaToMediaGroupMutation } from "../../../data-access/mutation/mergeMediaToMediaGroupMutation";
import { moveMediaToMediaGroupMutation } from "../../../data-access/mutation/moveMediaToMediaGroupMutation";
import { updateMedia } from "../../../data-access/updateMedia";
import { outputsTypeIcons } from "../../../dataConstants";
import { MediaGroupT, MediaT, PartnershipNetworkT } from "../../../types";

const IconWrapper = styled(FlexBox)({
  cursor: "pointer",
});

const DropdownWrapper = styled(FlexBox)({
  padding: `${rem(16)} ${rem(12)}`,
  cursor: "pointer",
  minWidth: "100%",
  justifyContent: "flex-start",

  ["&:hover"]: {
    backgroundColor: theme.color.backgroundColor,
  },
});

type CardHeaderT = {
  media: MediaT;
  mediaGroup: MediaGroupT;
  storyCounter?: string;
  network: PartnershipNetworkT;
  liveAddedMedia?: boolean;
};

const CardHeader: FC<CardHeaderT> = React.memo(
  ({
    media,
    mediaGroup,
    storyCounter = undefined,
    network,
    liveAddedMedia,
  }) => {
    const { kind, id } = mediaGroup;
    const [active, setActive] = useState(false);
    const [existedMediaGroup, setExistedMediaGroup] = useState<
      MediaGroupT | undefined
    >();
    const [existedMediaGroupMerge, setExistedMediaGroupMerge] = useState<
      MediaGroupT | undefined
    >();

    const {
      setMediaGroupToRemove,
      setMediaToRemove,
      setExistedOutputsState,
      addedQueryStack,
      queryStack,
      campaignId,
      clientId,
    } = useContext(MediaGroupsHelperContext);
    const currentQueryStack = liveAddedMedia ? addedQueryStack : queryStack;

    const queryClient = useQueryClient();
    const { mutate } = useMutation(updateMedia, {
      onSuccess: () => {
        if (existedMediaGroup) {
          setTimeout(() => {
            moveMediaToMediaGroupMutation(
              queryClient,
              currentQueryStack,
              media,
              existedMediaGroup,
              clearStatesAfterRemoving,
            );
          }, 300);
        }
      },
    });
    const { mutate: mutateMerge } = useMutation(mergeMediaGroups, {
      onSuccess: () => {
        if (existedMediaGroupMerge) {
          setTimeout(() => {
            mergeMediaToMediaGroupMutation(
              queryClient,
              currentQueryStack,
              mediaGroup,
              existedMediaGroupMerge,
              clearStatesAfterRemoving,
            );
          }, 300);
        }
      },
    });

    const { t } = useTranslate("brands.reportings");
    const label = t(`labels.${kind}`);

    const onRemoveMediaGroupClickHandle = (): void => {
      if (campaignId) {
        setMediaGroupToRemove({
          campaignId,
          mediaGroupId: id,
          removed: false,
          queryStack: currentQueryStack,
        });
      }
    };

    const onRemoveMediaClickHandle = (): void => {
      if (campaignId) {
        setMediaToRemove({
          id: media.id,
          mediaGroupId: id,
          removed: false,
          queryStack: currentQueryStack,
        });
      }
    };

    const onMoveMediaClickHandle = (): void => {
      if (campaignId) {
        setExistedOutputsState({
          modalAttributes: [
            {
              variant: "media_group",
              requestParams: {
                type: "story",
                campaign_id: campaignId,
                kinds: ["story_set"],
                partnership_ids: [mediaGroup.partnership.id],
                exclude_ids: [mediaGroup.id],
              },
            },
          ],
          setExistedMediaGroup,
        });
      }
    };

    const onMoveMediaGroupClickHandle = (): void => {
      if (campaignId) {
        setExistedOutputsState({
          modalAttributes: [
            {
              variant: "media_group",
              requestParams: {
                type: "story",
                campaign_id: campaignId,
                kinds: ["story_set"],
                partnership_ids: [mediaGroup.partnership.id],
                exclude_ids: [mediaGroup.id],
              },
            },
          ],
          setExistedMediaGroup: setExistedMediaGroupMerge,
        });
      }
    };

    const clearStatesAfterRemoving = (): void => {
      setExistedMediaGroup(undefined);
      setExistedMediaGroupMerge(undefined);
    };

    const getStoryOptions = (): JSX.Element => {
      return (
        <>
          <DropdownWrapper onClick={onMoveMediaGroupClickHandle}>
            <Icon icon={IconTypeE.loadArrow} size={"small"} />
            <Spacer size="mini" />
            <Paragraph
              paragraph={t("media_groups.buttons.move.story_set")}
              cursor="pointer"
            />
          </DropdownWrapper>
          {mediaGroup.medias.length > 1 && (
            <>
              <DropdownWrapper onClick={onMoveMediaClickHandle}>
                <Icon icon={IconTypeE.loadArrow} size={"small"} />
                <Spacer size="mini" />
                <Paragraph
                  paragraph={t("media_groups.buttons.move.story")}
                  cursor="pointer"
                />
              </DropdownWrapper>
              <DropdownWrapper onClick={onRemoveMediaClickHandle}>
                <Icon icon={IconTypeE.bin} size={"small"} />
                <Spacer size="mini" />
                <Paragraph
                  paragraph={t("media_groups.buttons.remove.story")}
                  cursor="pointer"
                />
              </DropdownWrapper>
            </>
          )}
        </>
      );
    };

    useEffect(() => {
      if (existedMediaGroup) {
        mutate({
          clientId,
          id: media.id,
          updatedMediaData: {
            media: {
              ...media,
              media_group_id: existedMediaGroup.id,
            },
          },
        });
      }
    }, [existedMediaGroup]);

    useEffect(() => {
      if (existedMediaGroupMerge && campaignId) {
        mutateMerge({
          clientId,
          id: campaignId,
          payload: {
            media_group_merge: {
              source_id: mediaGroup.id,
              target_id: existedMediaGroupMerge.id,
            },
          },
        });
      }
    }, [existedMediaGroupMerge]);

    return (
      <TopBar customWidth={CustomWidthE.full} justifyContent="space-between">
        <FlexBox>
          <Icon
            icon={
              network === "instagram"
                ? IconTypeE.socInstagram
                : IconTypeE.socTiktok
            }
            size="small"
          />
          <Spacer size="micro" />
          <Icon icon={outputsTypeIcons[kind]} size="small" />
          <Spacer size="micro" />
          <Paragraph
            paragraph={`${label} ${storyCounter ? storyCounter : ""}`}
            fontWeight={500}
          />
        </FlexBox>
        <Spacer />
        {campaignId && (
          <FlexBox position="relative">
            <IconWrapper onClick={() => setActive(!active)}>
              <Icon icon={IconTypeE.dots} size="small" />
            </IconWrapper>
            <Dropdown
              active={active}
              width={rem(190)}
              setInactive={() => setActive(false)}
            >
              <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
                {kind === "story_set" && getStoryOptions()}
                <DropdownWrapper onClick={onRemoveMediaGroupClickHandle}>
                  <Icon icon={IconTypeE.bin} size={"small"} />
                  <Spacer size="mini" />
                  <Paragraph
                    paragraph={t(`media_groups.buttons.remove.${kind}`)}
                    cursor="pointer"
                  />
                </DropdownWrapper>
              </FlexBox>
            </Dropdown>
          </FlexBox>
        )}
      </TopBar>
    );
  },
);

export default CardHeader;
