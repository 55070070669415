import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export const removeMediaImage = async (
  mediaFileId: string,
  clientId: string,
): Promise<any> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.delete(getApiUrl(ApiRoutesE.MEDIA_FILE, mediaFileId), config);
};
