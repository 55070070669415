import styled from "@emotion/styled";
import debounce from "lodash/debounce";
import React, { FC, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Input from "../../../../../../features/Input";
import { getUrlWithQuery } from "../../../../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../../../../helpers/routes";
import { theme } from "../../../../../../styling/theme";
import { updateCampaignPartnershipMutation } from "../../../../data-access/mutation/updateCampaignPartnershipMutation";
import {
  CampaignPartnershipUpdateAttributesT,
  updateCampaignPartnership,
} from "../../../../data-access/updateCampaignPartnership";

const StyledInput = styled(Input)({
  borderColor: theme.color.greyLightColor,
  textAlign: "center",

  ["&:hover"]: {
    borderColor: theme.color.textGreyDarkColor,
  },
});

type LiveInputT = {
  attribute: keyof CampaignPartnershipUpdateAttributesT;
  campaignId: string;
  campaignPartnershipId: string;
  clientId: string;
  value: number | null;
};

const LiveInput: FC<LiveInputT> = ({
  attribute,
  campaignId,
  campaignPartnershipId,
  clientId,
  value,
}) => {
  const [valueState, setValueState] = useState<string>(value ? `${value}` : "");

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    () =>
      updateCampaignPartnership({
        clientId,
        url: getUrlWithQuery(
          getApiUrl(
            ApiRoutesE.CAMPAIGN_PARTNERSHIP_ITEM,
            campaignPartnershipId,
          ),
          { campaign_id: campaignId },
        ),
        body: {
          campaign_partnership: {
            [attribute]: valueState,
          },
        },
      }),
    {
      onSuccess: (data) => {
        updateCampaignPartnershipMutation(queryClient, data);
      },
    },
  );

  const mutateValueHandle = useRef(debounce(() => mutate(), 500)).current;

  useEffect(() => {
    if (valueState !== `${value}`) {
      mutateValueHandle();
    }
  }, [valueState]);

  return (
    <StyledInput
      value={valueState ? valueState : ""}
      setValue={setValueState}
      regExp={/^[0-9]*$/}
    />
  );
};

export default LiveInput;
