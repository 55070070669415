require("blueimp-file-upload/js/jquery.fileupload");
require("select2");
require("blueimp-file-upload/js/jquery.fileupload");
require("blueimp-file-upload/js/jquery.fileupload-ui");
require("bootstrap-tagsinput/dist/bootstrap-tagsinput");
require("bootstrap-datepicker/dist/js/bootstrap-datepicker.min");
require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.en-GB.min");
require("cocoon-js");
require("./morris/index");

require("./scripts/image_cropper");
require("./scripts/responsive");
require("./scripts/popup");
require("./scripts/modal");
require("./scripts/networkAuth");
require("./scripts/direct_upload");
require("./scripts/jQueryScripts");

const mixpanel = require("mixpanel-browser");
window.mixpanel = mixpanel;

import { basicTriggerInit } from "./scripts/basicTrigger";
import { forceReloadInit } from "./scripts/forceReload";
import { textAreaHeightInit } from "./scripts/textAreaHeight";
import { turboLinksInit } from "./turboLinks";
import { flashMessagesInit } from "./scripts/flashMessages";
import { networkTabsInit } from "./scripts/networkTabs";
import { localStorageVisibilityInit } from "./scripts/localStorageVisibility";
import smoothscroll from "smoothscroll-polyfill";

$(document).on("turbolinks:load", function () {
  mixpanel.init(
    window.TRACKING_PROJECT_TOKEN,
    { debug: false, track_pageview: true, persistence: 'localStorage', ignore_dnt: true }
  );
  mixpanel.identify(window.TRACKING_UUID);

  $(
    "input[data-role=tagsinput], select[multiple][data-role=tagsinput]"
  ).tagsinput();
  $("select.select2").select2({ width: "100%" });

  textAreaHeightInit();
  basicTriggerInit();
  forceReloadInit();
  flashMessagesInit();
  networkTabsInit();

  smoothscroll.polyfill();
});

$(document).on("change", ".tac_payment", function () {
  if ($(this).is(":checked")) {
    $(this).closest("form").find("input[type=submit]").attr("disabled", null);
  } else {
    $(this).closest("form").find("input[type=submit]").attr("disabled", "");
  }

  localStorageVisibilityInit();
});

$(document).on("change", "#tac-invitation", function () {
  if ($(this).is(":checked")) {
    $("#privacy-invitation").attr("checked", true);
    $("#google-invitation-submit").attr("disabled", null);
    $("#tooltip-invitation").removeClass("tooltip-trigger");
  } else {
    $("#privacy-invitation").attr("checked", false);
    $("#google-invitation-submit").attr("disabled", "");
    $("#tooltip-invitation").addClass("tooltip-trigger");
  }
});

$(document).on("change", "#tac-registration", function () {
  if ($(this).is(":checked")) {
    $("#tac-privacy").attr("checked", true);
    $("#invitation-submit").attr("disabled", null);
    $("#tooltip-tac").removeClass("tooltip-trigger");
  } else {
    $("#tac-privacy").attr("checked", false);
    $("#invitation-submit").attr("disabled", "");
    $("#tooltip-tac").addClass("tooltip-trigger");
  }
});
