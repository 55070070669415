import { Tabs, TabsProps } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useQueryClient } from "react-query";
import {
  ButtonSizeE,
  ButtonTypeE,
  StyledButton,
} from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import Dropdown, {
  DropdownAnchorWrapper,
  DropdownItem,
} from "../../../../ui/Dropdown";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { setMediaGroupPendingState } from "../../data-access/mutation/setMediaGroupPendingState";
import { CampaignOutputsFilterStateT, SortingStateT } from "../../types";
import SortAndFilter from "./filter/SortAndFilter";
import { SectionTypeT } from "./SectionsSwitcher";

type OptionsSectionT = {
  activeTab: SectionTypeT;
  onTabChangeHandle: (key: string) => void;
  filterState: CampaignOutputsFilterStateT | undefined;
  setFilterState: Dispatch<
    SetStateAction<CampaignOutputsFilterStateT | undefined>
  >;
  sortingState: SortingStateT;
  setSortingState: Dispatch<SetStateAction<SortingStateT>>;
  clientId: string;
  campaignId: string;
};

const OptionsSection: FC<OptionsSectionT> = ({
  activeTab,
  onTabChangeHandle,
  filterState,
  setFilterState,
  sortingState,
  setSortingState,
  clientId,
  campaignId,
}) => {
  const [downloadHover, setDownloadHover] = useState(false);

  const { t } = useTranslate("brands.reportings.media_groups");

  const tabItems: TabsProps["items"] = [
    {
      key: "media_groups",
      label: t("outputs"),
    },
    {
      key: "analytics",
      label: t("analytics"),
    },
  ];

  const queryClient = useQueryClient();

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      justifyContent="space-between"
      position="relative"
      zIndex={2}
    >
      <Tabs
        items={tabItems}
        onChange={onTabChangeHandle}
        activeKey={activeTab}
      />
      <Spacer />
      {activeTab === "media_groups" && (
        <FlexBox>
          <FlexBox
            justifyContent="stretch"
            position="relative"
            onMouseEnter={() => setDownloadHover(true)}
            onMouseLeave={() => setDownloadHover(false)}
          >
            <StyledButton
              buttonType={ButtonTypeE.default}
              size={ButtonSizeE.small}
            >
              <Icon icon={IconTypeE.plusSquare} />
              <Spacer size="mini" />
              {t("add_new_card.add_media_group")}
            </StyledButton>
            <Dropdown active={downloadHover} width={CustomWidthE.full}>
              <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
                <DropdownAnchorWrapper
                  onClick={() => setMediaGroupPendingState(queryClient, true)}
                >
                  <DropdownItem justifyContent="flex-start">
                    <Icon icon={IconTypeE.socInstagram} />
                    <Spacer size="mini" />
                    <Paragraph
                      paragraph={"Instagram"}
                      paragraphSize="small"
                      fontWeight={600}
                    />
                  </DropdownItem>
                </DropdownAnchorWrapper>
                <DropdownAnchorWrapper
                  onClick={() =>
                    setMediaGroupPendingState(queryClient, true, "tiktok")
                  }
                >
                  <DropdownItem justifyContent="flex-start">
                    <Icon icon={IconTypeE.socTiktok} />
                    <Spacer size="mini" />
                    <Paragraph
                      paragraph={"TikTok"}
                      paragraphSize="small"
                      fontWeight={600}
                    />
                  </DropdownItem>
                </DropdownAnchorWrapper>
              </FlexBox>
            </Dropdown>
          </FlexBox>
          <Spacer />
          <SortAndFilter
            filterState={filterState}
            setFiliterState={setFilterState}
            sortingState={sortingState}
            setSortingState={setSortingState}
            clientId={clientId}
            campaignId={campaignId}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default OptionsSection;
