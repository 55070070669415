import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CampaignT, PayloadCampaignT } from "../types";

export const updateCampaign = async (
  campaignId: string,
  payload: {
    campaign: PayloadCampaignT;
  },
  clientId: string,
): Promise<CampaignT> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .patch(getApiUrl(ApiRoutesE.CAMPAIGN, campaignId), payload, config)
    .then((res) => res.data.campaign);
};
