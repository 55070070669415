import * as React from "react";
import { FC, useContext } from "react";
import { Navigate } from "react-router-dom";
import Button from "../../components/Button";
import FlexBox from "../../components/FlexBox";
import NoResults from "../../components/NoResults";
import Panel from "../../components/Panel";
import Spacer from "../../components/Spacer";
import { ClientsContext } from "../../context-providers/ClientsContextProvider";
import ReportingContainer from "../../entry-files/company-root/features/components/ReportingContainer";
import { NavigationKeyE } from "../../entry-files/company-root/router";
import { useTranslate } from "../../hooks/useTranslate";
import Icon, { IconTypeE } from "../../ui/icons/Icon";
import { IllustrationTypeE } from "../../ui/Illustrations";

const ClientsNotExist: FC = () => {
  const { t } = useTranslate("brands.reportings.clients");
  const { currentClientId, setClientModal } = useContext(ClientsContext);

  return (
    <ReportingContainer>
      {!currentClientId ? (
        <Panel>
          <FlexBox flexDirection="column">
            <Spacer direction="vertical" size="big" />
            <NoResults
              illustrationSize={250}
              illustration={IllustrationTypeE.lost_walking}
              message={t("not_exist.description")}
            />
            <Spacer direction="vertical" />
            <Button onClick={() => setClientModal({ type: "create" })}>
              <Icon icon={IconTypeE.plus} size="mini" />
              <Spacer size="micro" />
              {t("not_exist.add_first")}
            </Button>
            <Spacer direction="vertical" size="big" />
          </FlexBox>
        </Panel>
      ) : (
        <Navigate to={NavigationKeyE.Partnerships} replace />
      )}
    </ReportingContainer>
  );
};

export default ClientsNotExist;
