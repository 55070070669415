import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { CampaignPartnershipT, CampaignT } from "../../types";

export const removeCampaignPartnershipMutation = (
  queryClient: QueryClient,
  campaignPartnershipId: string,
  afterMutationHandle: () => void,
): void => {
  queryClient.setQueryData<CampaignPartnershipT[] | undefined>(
    QueryKeys.CAMPAIGN_PARTNERSHIPS,
    (prevState) => {
      if (prevState) {
        return prevState.filter((item) => item.id !== campaignPartnershipId);
      }

      return prevState;
    },
  );

  // mutation of parnerships_count in current Campaign
  queryClient.setQueryData<CampaignT | undefined>(
    QueryKeys.CAMPAIGN_DETAIL,
    (prevState) => {
      const partnershipCount = prevState?.partnership_ids.length;

      if (prevState && partnershipCount) {
        return {
          ...prevState,
          partnerships_count: partnershipCount - 1,
        };
      }

      return prevState;
    },
  );

  afterMutationHandle();
};
