import { DeleteOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Drawer, Popconfirm, Table as AntTable } from "antd";
import { ColumnsType } from "antd/es/table";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import AvatarTile from "../../../../../components/AvatarTile";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Panel from "../../../../../components/Panel";
import { ApplicationContext } from "../../../../../context-providers/ApplicationContextProvider";
import { ClientT } from "../../../../../entry-files/types";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { getDateTime } from "../../../../../helpers/date";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { removePartnershipMutation } from "../../../data-access/mutation/removePartnershipMutation";
import { removePartnership } from "../../../data-access/removePartnership";
import { PartnershipT } from "../../../types";
import ApprovalRequestModal from "./ApprovalRequestModal";
import AutocollectInsights from "./AutocollectInsights";

const DeleteButton = styled(Button)({
  visibility: "hidden",
});

const StyledTable = styled(AntTable)({
  ".ant-table-row": {
    "&:hover, &:focus-within": {
      [`${DeleteButton}`]: {
        visibility: "visible",
      },
    },
  },
});

interface DataType {
  key: string;
  partnership: PartnershipT;
}

export type PartnershipToRemoveT = {
  id: string;
  removed: boolean;
};

type TableT = {
  clientId: string;
  dataIsLoading: boolean;
  partnerships?: PartnershipT[];
  emptyState: JSX.Element;
};

const Table: FC<TableT> = ({
  clientId,
  dataIsLoading,
  partnerships,
  emptyState,
}) => {
  const [partnershipForApproval, setPartnershipForApproval] = useState<
    PartnershipT | undefined
  >();
  const [client, setClient] = useState<ClientT | undefined>(undefined);

  const { session } = useContext(ApplicationContext);

  const { t } = useTranslate("brands.reportings.partnerships");

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.influencer"),
      dataIndex: "handle",
      render: (_, record) => {
        const { handle, network, display_name, avatar_url } =
          record.partnership;
        return (
          <FlexBox justifyContent="flex-start">
            <AvatarTile
              handle={handle}
              network={network}
              name={display_name ? display_name : handle}
              imageUrl={avatar_url}
            />
          </FlexBox>
        );
      },
      sorter: (a, b) =>
        a.partnership.handle.localeCompare(b.partnership.handle),
    },
    {
      title: t("table.outputs"),
      dataIndex: "outputs",
      render: (_, record) => record.partnership.media_groups_kinds,
    },
    {
      title: t("table.created_at"),
      dataIndex: "created_at",
      render: (_, record) =>
        getDateTime(record.partnership.created_at, "shortDate"),
      sorter: (a, b) =>
        a.partnership.created_at.localeCompare(b.partnership.created_at),
    },
    {
      title: t("table.autocollect"),
      dataIndex: "autocollect",
      render: (_, record) => (
        <>
          {client && (
            <AutocollectInsights
              client={client}
              partnership={record.partnership}
            />
          )}
        </>
      ),
    },
    {
      dataIndex: "actions",
      render: (_, record) => (
        <FlexBox justifyContent="flex-end" gap={rem(8)}>
          {record.partnership.network !== "tiktok" &&
            !record.partnership.insights && (
              <Button
                size="small"
                onClick={() => setPartnershipForApproval(record.partnership)}
              >
                {t("table.request")}
              </Button>
            )}
          <Popconfirm
            title={t("remove_modal.title")}
            description={t("remove_modal.question")}
            onConfirm={() => removePartnershipHandle(record.partnership.id)}
            okText={t("remove_modal.confirm")}
            cancelText={t("remove_modal.close")}
            okButtonProps={{ disabled: isLoading }}
          >
            <DeleteButton type="text" icon={<DeleteOutlined />} />
          </Popconfirm>
        </FlexBox>
      ),
    },
  ];

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(removePartnership, {
    onSuccess: (_, variables) => {
      setTimeout(() => {
        removePartnershipMutation(queryClient, variables.partnershipId);
      }, 300);
    },
  });

  const removePartnershipHandle = (id: string): void => {
    const data = { partnershipId: id, clientId };

    mutate(data);
  };

  useEffect(() => {
    setClient(session?.clients.find((client) => client.id === clientId));
  }, [session, clientId]);

  const getTableContent = (): JSX.Element => {
    if (client && partnerships && partnerships.length > 0) {
      const data: DataType[] = partnerships.map((partnership) => ({
        key: partnership.id,
        partnership: partnership,
      }));

      return (
        <StyledTable
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return emptyState;
  };

  return (
    <>
      <Panel flexDirection="column" position="relative">
        {getTableContent()}
      </Panel>

      <Drawer
        open={!!partnershipForApproval}
        title={t("approval_request_modal.title")}
        onClose={() => setPartnershipForApproval(undefined)}
      >
        <>
          {client && partnershipForApproval && (
            <ApprovalRequestModal
              client={client}
              partnership={partnershipForApproval}
              close={() => setPartnershipForApproval(undefined)}
            />
          )}
        </>
      </Drawer>

      {/* <Modal
        visible={!!partnershipForApproval}
        close={() => setPartnershipForApproval(undefined)}
        renderChildren={(_, close) => (
          <>
            {client && partnershipForApproval && (
              <ApprovalRequestModal
                client={client}
                partnership={partnershipForApproval}
                close={close}
              />
            )}
          </>
        )}
      /> */}
    </>
  );
};

export default Table;
