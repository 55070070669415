import * as React from "react";
import { FC } from "react";
import Button, { ButtonTypeE } from "./Button";
import FlexBox, { CustomWidthE } from "./FlexBox";
import Heading, { HeadingTypeT } from "./Heading";
import Paragraph from "./Paragraph";
import Spacer from "./Spacer";

type ModalDialogT = {
  buttonCloseTitle: string;
  buttonConfirmTitle: string;
  confirmButtonType: ButtonTypeE;
  disabledConfirmButton?: boolean;
  onCloseHandle: () => void;
  onConfirmHandle: () => void;
  subtitle: string;
  title: string;
};

const ModalDialog: FC<ModalDialogT> = ({
  buttonCloseTitle,
  buttonConfirmTitle,
  confirmButtonType,
  disabledConfirmButton,
  onCloseHandle,
  onConfirmHandle,
  subtitle,
  title,
}) => {
  return (
    <FlexBox flexDirection="column" alignItems="stretch">
      <Heading headingType={HeadingTypeT.h3} heading={title} spaceBottom={10} />
      <Paragraph paragraph={subtitle} />
      <Spacer size="big" direction="vertical" />
      <FlexBox customWidth={CustomWidthE.full}>
        <Button
          type={ButtonTypeE.darkGrey}
          customWidth={CustomWidthE.full}
          onClick={onCloseHandle}
        >
          {buttonCloseTitle}
        </Button>
        <Spacer />
        <Button
          type={confirmButtonType}
          customWidth={CustomWidthE.full}
          disabled={disabledConfirmButton}
          onClick={onConfirmHandle}
        >
          {buttonConfirmTitle}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default ModalDialog;
