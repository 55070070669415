import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { getDateTime } from "../../../../../helpers/date";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import RadioButton from "../../../../../ui/RadioButton";
import { getCampaignAnalytics } from "../../../data-access/getCampaignAnalytics";
import {
  CampaignAnalyticsStatisticsT,
  CampaignAnalyticsT,
} from "../../../types";

const Wrapper = styled(FlexBox)({
  height: "400px",
  width: "100%",
  minWidth: "50%",
  gap: rem(10),
  flexDirection: "column",
  backgroundColor: theme.color.whiteColor,
  padding: `${rem(50)} ${rem(15)} ${rem(15)}`,
  border: `1px solid ${theme.color.greyLightColor}`,
  borderRadius: rem(8),
});

const RadioButtonWrapper = styled(FlexBox)({
  gap: rem(5),
  cursor: "pointer",
});

const SetupWrapper = styled(FlexBox)({
  width: "100%",
});

const GraphTypes = styled(FlexBox)({
  gap: rem(10),
});

type GraphDatasetT = {
  data: number[];
  borderColor: string;
  backgroundColor: string;
};

type GraphDataT = {
  labels: string[];
  datasets: GraphDatasetT[];
};

type StatisticsT = keyof CampaignAnalyticsStatisticsT;

type DataKindT = "cumulative" | "incremental";

type GraphT = {
  clientId: string;
  campaignId: string;
};

const Graph: FC<GraphT> = ({ clientId, campaignId }) => {
  const [dataKind, setDataKind] = useState<DataKindT>("cumulative");
  const [currentStatistics, setCurrentStatistics] =
    useState<StatisticsT>("impressions");

  type getColorsReturnT = { borderColor: string; backgroundColor: string };
  const getColors = (statistics = currentStatistics): getColorsReturnT => {
    switch (statistics) {
      case "impressions":
        return {
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        };
      case "likes":
        return {
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        };
      case "comments":
        return {
          borderColor: theme.color.yellowLightColor,
          backgroundColor: theme.color.yellowColor,
        };
    }
  };

  const [graphData, setGraphData] = useState<GraphDataT>({
    labels: ["Data"],
    datasets: [
      {
        data: [0],
        ...getColors(),
      },
    ],
  });

  const { data, refetch } = useQuery(
    `${QueryKeys.CAMPAIGNS_ANALYTICS}-${campaignId}`,
    () =>
      getCampaignAnalytics({
        clientId,
        apiUrl: getUrlWithQuery(
          getApiUrl(ApiRoutesE.CAMPAIGNS_ANALYTICS, campaignId),
          { kind: dataKind },
        ),
      }),
    {
      enabled: false,
    },
  );

  const { t } = useTranslate("brands.reportings.campaigns.analytics.graph");

  const graphTypes: StatisticsT[] = ["impressions", "likes", "comments"];
  const dataKinds: DataKindT[] = ["cumulative", "incremental"];

  const formatGraphData = (data: CampaignAnalyticsT[]): void => {
    const labels = data.map((item) => getDateTime(item.date, "shortDate"));

    const dataset: GraphDatasetT = {
      ...getColors(),
      data: data.map((item) => item.statistics[currentStatistics]),
    };

    setGraphData({ labels: labels, datasets: [dataset] });
  };

  useEffect(() => {
    refetch();
  }, [dataKind]);

  useEffect(() => {
    data && data.length > 0 && formatGraphData(data);
  }, [currentStatistics, data]);

  return (
    <Wrapper justifyContent="flex-end" alignItems="flex-end">
      <SetupWrapper justifyContent="space-between">
        <GraphTypes>
          {dataKinds.map((kind) => (
            <RadioButtonWrapper key={kind} onClick={() => setDataKind(kind)}>
              <RadioButton active={dataKind === kind} />
              <Paragraph paragraph={t(kind)} />
            </RadioButtonWrapper>
          ))}
        </GraphTypes>
        <GraphTypes>
          {graphTypes.map((type) => (
            <RadioButtonWrapper
              key={type}
              onClick={() => setCurrentStatistics(type)}
            >
              <RadioButton
                active={currentStatistics === type}
                color={getColors(type).borderColor}
              />
              <Paragraph paragraph={t(type)} />
            </RadioButtonWrapper>
          ))}
        </GraphTypes>
      </SetupWrapper>
      {graphData && (
        <Line
          data={graphData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: { legend: { display: false } },
          }}
        />
      )}
    </Wrapper>
  );
};

export default Graph;
