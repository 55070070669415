import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Tooltip } from "antd";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { SubscriptionEntitlementFeaturesT } from "../../types";
import { SubscriptionContext } from "../context-providers/SubscriptionsContextProvider";

const HeaderWrapper = styled(FlexBox)({
  padding: `${rem(10)} ${rem(10)} ${rem(20)} ${rem(10)}`,
});

const Header = styled(FlexBox)({
  gap: rem(5),
});

const Limit = styled(FlexBox)<{
  reached: boolean;
}>(({ reached }) => ({
  color: reached ? theme.color.errorColor : theme.color.blackColor,
}));

type LimitHeaderT = {
  title: string;
  onClickHandle: (value: boolean) => void;
  submitTitle: string;
  entitlementFeature: SubscriptionEntitlementFeaturesT;
  disableCreate?: boolean;
  disableCreateMessage?: string;
};

const LimitHeader: FC<LimitHeaderT> = ({
  title,
  onClickHandle,
  submitTitle,
  entitlementFeature,
  disableCreate = false,
  disableCreateMessage,
}) => {
  const { entitlementValue, session } = useContext(ApplicationContext);
  const { openPortal, getFeatureUsage, setSubscription } =
    useContext(SubscriptionContext);
  const { t } = useTranslate("brands.subscriptions");

  const subscription = session?.subscription || undefined;

  const usage = getFeatureUsage(session, entitlementFeature);
  const monthCount = (usage && usage.used) || 0;
  const limit = (entitlementValue(entitlementFeature) as number) || 0;
  const limitReached = monthCount >= limit;

  useEffect(() => {
    limitReached && setSubscription(subscription);
  }, [subscription]);

  return (
    <HeaderWrapper
      customWidth={CustomWidthE.full}
      justifyContent="space-between"
      flexDirection="row"
    >
      <Header>
        <Paragraph paragraph={title} fontWeight={600} paragraphSize="large" />
        <Limit reached={limitReached}>
          ({monthCount}/{limit})
        </Limit>
        {limitReached && (
          <Button
            onClick={() => openPortal("edit_subscription")}
            icon={<PlusOutlined />}
          >
            {t("buy_addon")}
          </Button>
        )}
      </Header>
      <Tooltip title={disableCreate && disableCreateMessage}>
        <Button
          onClick={() => onClickHandle(true)}
          disabled={limitReached || disableCreate}
          icon={<PlusOutlined />}
          type="primary"
        >
          {submitTitle}
        </Button>
      </Tooltip>
    </HeaderWrapper>
  );
};

export default LimitHeader;
