import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export const hideCampaignMediaGroup = async (
  campaignMediaGroupId: string,
  campaignId: string,
  clientId: string,
): Promise<{}> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .patch(
      getApiUrl(
        ApiRoutesE.CAMPAIGN_MEDIA_GROUPS_HIDE,
        campaignMediaGroupId,
        campaignId,
      ),
      {},
      config,
    )
    .then((res) => res.data);
};
