import { useContext } from "react";
import {
  TranslationsContext,
  TranslationsContextT,
} from "../context-providers/TranslationsContextProvider";

type useTranslateT = (path?: string) => TranslationsContextT;
export const useTranslate: useTranslateT = (path) => {
  const { t } = useContext(TranslationsContext);

  return {
    t: path ? (key, options) => t(`${path}.${key}`, options) : t,
  };
};
