import axios from "axios";
import { CampaignAnalyticsT } from "../types";

type GetCampaignAnalyticsPropsT = {
  clientId: string;
  apiUrl: string;
};

type getCampaignAnalyticsT = (
  props: GetCampaignAnalyticsPropsT,
) => Promise<CampaignAnalyticsT[]>;
export const getCampaignAnalytics: getCampaignAnalyticsT = async ({
  clientId,
  apiUrl,
}) => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.get(apiUrl, config).then((res) => res.data.campaign_analytics);
};
