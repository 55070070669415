import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { ApplicationContext } from "../../../../../context-providers/ApplicationContextProvider";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import Dropdown, {
  DropdownAnchorWrapper,
  DropdownItem,
} from "../../../../../ui/Dropdown";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import SpinLoader from "../../../../../ui/SpinLoader";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableItemWrapper from "../../../../../ui/table/TableItemWrapper";
import { CampaignModalContext } from "../../../context-providers/CampaignModalContextProvider";
import { NavigationKeyE, useRouterNavigate } from "../../../router";
import { CampaignT } from "../../../types";
import PPTExport from "../../campaign-detail/PPTExport";
import CampaignInfoColumn from "./CampaignInfoColumn";
import HashtagsColumn from "./HashtagsColumn";
import MentionsColumn from "./MentionsColumn";
import { CampaignToRemoveT } from "./Table";

type TableRowT = {
  clientId: string;
  campaign: CampaignT;
  last: boolean;
  hide: boolean;
  setCampaignToRemove?: Dispatch<SetStateAction<CampaignToRemoveT | undefined>>;
};

const TableRow: FC<TableRowT> = ({
  campaign,
  clientId,
  last,
  hide,
  setCampaignToRemove,
}) => {
  const [downloadHover, setDownloadHover] = useState(false);
  const [pptxIsGenerating, setPptxIsGenerating] = useState(false);

  const { session } = useContext(ApplicationContext);
  const client = session?.clients.find((client) => client.id === clientId);
  const client_mentions = client?.instagram_mentions.concat(
    client?.tiktok_mentions,
  );
  const client_hashtags = client?.instagram_hashtags.concat(
    client?.tiktok_hashtags,
  );

  const { id, hashtags } = campaign;

  const basePath = getApiUrl(ApiRoutesE.CAMPAIGN_EXPORT, id);

  const { t } = useTranslate("brands.reportings.campaigns.table");
  const { setCampaignModal } = useContext(CampaignModalContext);
  const navigate = useRouterNavigate();

  return (
    <TableItemWrapper
      last={last}
      temporaryHide={hide}
      customHeight={rem(80)}
      renderChildren={() => (
        <>
          <FlexBox
            customWidth={CustomWidthE.custom80}
            onClick={() =>
              navigate(NavigationKeyE.Campaigns, `${id}/media_groups`)
            }
            cursor="pointer"
          >
            <CampaignInfoColumn campaign={campaign} />
            <MentionsColumn mentions={client_mentions} />
            <HashtagsColumn
              hashtags={
                hashtags && hashtags?.length > 0 ? hashtags : client_hashtags
              }
            />
          </FlexBox>
          <TableColumn
            columnWidth={CustomWidthE.fifth}
            justifyContent="flex-end"
            padding={rem(16)}
            alignItems="flex-start"
          >
            <FlexBox>
              <Button
                onClick={() => setCampaignModal({ ...campaign, type: "edit" })}
                type={ButtonTypeE.grey}
                size={ButtonSizeE.micro}
              >
                <Icon icon={IconTypeE.edit} />
                <Spacer size="mini" />
                {t("edit")}
              </Button>
              <Spacer size={"micro"} />
              <FlexBox
                position="relative"
                onMouseEnter={() => setDownloadHover(true)}
                onMouseLeave={() => setDownloadHover(false)}
              >
                <Button type={ButtonTypeE.grey} size={ButtonSizeE.micro}>
                  <FlexBox>
                    {pptxIsGenerating ? (
                      <SpinLoader />
                    ) : (
                      <Icon icon={IconTypeE.download} size="small" />
                    )}
                    <Spacer size="mini" />
                    {t("download")}
                  </FlexBox>
                </Button>
                <Dropdown active={downloadHover} width={rem(100)}>
                  <FlexBox
                    flexDirection="column"
                    customWidth={CustomWidthE.full}
                  >
                    <DropdownAnchorWrapper href={`${basePath}.csv`}>
                      <DropdownItem>
                        <Icon icon={IconTypeE.csv} color={"#1E7145"} />
                        <Spacer size="mini" />
                        <Paragraph
                          paragraph={".csv"}
                          paragraphSize="small"
                          fontWeight={600}
                        />
                      </DropdownItem>
                    </DropdownAnchorWrapper>
                    <DropdownAnchorWrapper href={`${basePath}.xlsx`}>
                      <DropdownItem>
                        <Icon icon={IconTypeE.xlsx} color={"#1E7145"} />
                        <Spacer size="mini" />
                        <Paragraph
                          paragraph={".xlsx"}
                          paragraphSize="small"
                          fontWeight={600}
                        />
                      </DropdownItem>
                    </DropdownAnchorWrapper>
                    <PPTExport
                      clientId={clientId}
                      campaignId={id}
                      pptxIsGenerating={pptxIsGenerating}
                      setPptxIsGenerating={setPptxIsGenerating}
                    >
                      <Icon icon={IconTypeE.pptx} color={"#D24826"} />
                      <Spacer size="mini" />
                      <Paragraph
                        paragraph={".pptx"}
                        paragraphSize="small"
                        fontWeight={600}
                      />
                    </PPTExport>
                  </FlexBox>
                </Dropdown>
              </FlexBox>
              {setCampaignToRemove && (
                <>
                  <Spacer size={"micro"} />
                  <Button
                    onClick={() =>
                      setCampaignToRemove({
                        id,
                        removed: false,
                      })
                    }
                    type={ButtonTypeE.grey}
                    size={ButtonSizeE.micro}
                  >
                    <Icon icon={IconTypeE.bin} />
                  </Button>
                </>
              )}
            </FlexBox>
          </TableColumn>
        </>
      )}
    />
  );
};

export default TableRow;
