import axios from "axios";
import { ExistedOutputT } from "../types";

export const getExistedOutputs = async (
  clientId: string,
  apiUrl: string,
): Promise<ExistedOutputT[]> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.get(apiUrl, config).then((res) => res.data.outputs);
};
