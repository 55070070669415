import styled from "@emotion/styled";
import { Select as AntSelect, SelectProps as AntSelectProps } from "antd";
import { omit } from "lodash";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../components/FlexBox";
import { rem, theme } from "../styling/theme";

const Wrapper = styled(FlexBox)({
  backgroundColor: theme.color.greyLightColor,
  borderRadius: rem(8),
});

const AddonWrapper = styled(FlexBox)({
  padding: `${rem(4)} ${rem(11)}`,
});

interface SelectProps {
  addonBefore?: string;
}

const Select: FC<AntSelectProps & SelectProps> = (props) => {
  const addonBefore = props.addonBefore;
  const options = omit(props, ["addonBefore"]) as AntSelectProps;

  return (
    <Wrapper customWidth={CustomWidthE.full}>
      {addonBefore && <AddonWrapper>{addonBefore}</AddonWrapper>}
      <AntSelect {...options} />
    </Wrapper>
  );
};

export default Select;
