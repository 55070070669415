import * as React from "react";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ClientT } from "../entry-files/types";
import { ClientModalStateT } from "../features/clients/ClientModal";
import { SESSION_STORAGE_KEY } from "../features/clients/Clients";

type ClientsContextT = {
  currentClientId: string | undefined;
  setCurrentClientId: Dispatch<SetStateAction<string | undefined>>;
  clientIsLoading: boolean;
  setClientIsLoading: Dispatch<SetStateAction<boolean>>;
  clientUpdated: boolean;
  setClientUpdated: Dispatch<SetStateAction<boolean>>;
  clientModal: ClientModalStateT | undefined;
  setClientModal: Dispatch<SetStateAction<ClientModalStateT | undefined>>;
  setAndStoreClientId: (clients: ClientT[]) => void;
};

export const ClientsContext = createContext<ClientsContextT>({
  currentClientId: undefined,
  setCurrentClientId: () => {},
  clientIsLoading: true,
  setClientIsLoading: () => {},
  clientUpdated: false,
  setClientUpdated: () => {},
  clientModal: undefined,
  setClientModal: () => {},
  setAndStoreClientId: () => {},
});

export const ClientsContextProvider: FC = ({ children }) => {
  const [clientIsLoading, setClientIsLoading] = useState(true);
  const [currentClientId, setCurrentClientId] = useState<string | undefined>();
  const [clientUpdated, setClientUpdated] = useState(false);
  const [clientModal, setClientModal] = useState<
    ClientModalStateT | undefined
  >();

  const setAndStoreClientId = (clients: ClientT[]): void => {
    if (clients.length === 0) {
      return;
    }

    const sessionClient = sessionStorage.getItem(SESSION_STORAGE_KEY);
    const sessionClientIsRelevant = clients.find(
      (client) => client.id === sessionClient,
    );

    if (sessionClientIsRelevant && !currentClientId) {
      setCurrentClientId(sessionClientIsRelevant.id);
      return;
    }

    if (!sessionClientIsRelevant) {
      setCurrentClientId(clients[0].id);
      return;
    }
  };

  useEffect(() => {
    if (currentClientId) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, currentClientId);
    }
  }, [currentClientId]);

  return (
    <ClientsContext.Provider
      value={{
        clientIsLoading,
        setClientIsLoading,
        currentClientId,
        setCurrentClientId,
        clientUpdated,
        setClientUpdated,
        clientModal,
        setClientModal,
        setAndStoreClientId,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
