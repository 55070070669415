import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { useCardCoverHeight } from "../../../../../hooks/useCardCoverHeight";
import { PendingFileT } from "../../../../../hooks/usePendingFile";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import UploadEmptyStateArea from "../../../../../ui/UploadEmptyStateArea";
import { MediaKindT } from "../../../types";

type NoImageWrapperStyleT = {
  customHeight: string;
};

const NoImageWrapper = styled(FlexBox)<NoImageWrapperStyleT>(
  ({ customHeight }) => ({
    height: customHeight,
    margin: `0 ${rem(4)}`,
    width: `calc(100% - ${rem(8)})`,
    borderRadius: rem(6),
    backgroundColor: theme.color.greyLightColor,
  }),
);

const NoPreview = styled(FlexBox)({
  padding: rem(24),
});

const Wrapper = styled(FlexBox)({
  margin: `0 ${rem(4)}`,
  width: `calc(100% - ${rem(8)})`,
});

const Content = styled(FlexBox)({
  padding: `0 ${rem(12)}`,
});

type CoverOptionsT = {
  defaltHeight: number;
  aspectRatio: number[];
};

type EmptyStateT = {
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  options: CoverOptionsT;
  kind: MediaKindT;
  wrapperWidth?: number;
};

const EmptyState: FC<EmptyStateT> = ({
  setPendingFiles,
  options,
  kind,
  wrapperWidth,
}) => {
  const { t } = useTranslate("brands.reportings.media_groups.empty_state");

  const { coverHeight } = useCardCoverHeight(
    wrapperWidth,
    options.defaltHeight,
    options.aspectRatio,
  );

  // prepare for "preview missing and upload disable" use case
  const isDisabledAndPrviewMissing = false;

  if (isDisabledAndPrviewMissing) {
    return (
      <NoImageWrapper
        customWidth={CustomWidthE.full}
        customHeight={rem(coverHeight)}
      >
        <NoPreview flexDirection="column">
          <Icon
            icon={IconTypeE.eyeStrike}
            color={theme.color.textLightGreyColor}
            size="big"
          />
          <Spacer direction="vertical" size="small" />
          <Paragraph
            paragraph={t("missing_image")}
            color={theme.color.textLightGreyColor}
            fontWeight={500}
            align="center"
          />
        </NoPreview>
      </NoImageWrapper>
    );
  }

  return (
    <Wrapper position="relative">
      <UploadEmptyStateArea
        setPendingFiles={setPendingFiles}
        height={coverHeight}
        allowMultipleUpload={kind !== "story"}
      >
        <>
          <Content flexDirection="column">
            <Icon
              icon={IconTypeE.upload}
              size={"monster"}
              color={theme.color.textGreyDarkColor}
            />
            <Spacer direction="vertical" size="small" />
            <Heading
              heading={t("add_files")}
              headingType={HeadingTypeT.h4}
              spaceBottom={6}
              color={theme.color.textGreyDarkColor}
            />
            <Paragraph
              paragraph={t("description")}
              align="center"
              paragraphSize="micro"
            />
            <Spacer direction="vertical" size="small" />
            <Paragraph
              paragraph={t("supported_formats")}
              align="center"
              paragraphSize="micro"
              color={theme.color.textGreyColor}
            />
            <Paragraph
              paragraph={t("formats")}
              align="center"
              paragraphSize="micro"
              color={theme.color.textGreyColor}
            />
            <Spacer direction="vertical" size="small" />
            <Button type={ButtonTypeE.outline} size={ButtonSizeE.micro}>
              {t("browse")}
            </Button>
          </Content>
        </>
      </UploadEmptyStateArea>
    </Wrapper>
  );
};

export default EmptyState;
