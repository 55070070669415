import { LinkOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Divider, Form, Input } from "antd";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import AvatarTile from "../../../../../components/AvatarTile";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import LoaderComponent from "../../../../../components/Loader";
import Paragraph from "../../../../../components/Paragraph";
import Select from "../../../../../features/Select";
import { designToken } from "../../../../../helpers/antDesign";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { ApprovalRequestWithAuthorT, ClientT } from "../../../../types";
import { ToastContext } from "../../../context-providers/ToastContextProvider";
import { createApprovalRequests } from "../../../data-access/createApprovalRequest";
import { getApprovalRequests } from "../../../data-access/getApprovalRequests";
import { createApprovalRequestMutation } from "../../../data-access/mutation/createApprovalRequestMutation";
import { sendNotificationApprovalRequest } from "../../../data-access/sendNotificationApprovalRequest";
import { PartnershipT } from "../../../types";
import AutocollectInsights from "./AutocollectInsights";

const StyledDivider = styled(Divider)({
  margin: 0,
});

type ApprovalRequestModalT = {
  client: ClientT;
  partnership: PartnershipT;
  close: () => void;
};

const ApprovalRequestModal: FC<ApprovalRequestModalT> = ({
  client,
  partnership,
  close,
}) => {
  const { id, handle, network, display_name, avatar_url, approval_requests } =
    partnership;
  const allApprovedMentions = approval_requests.flatMap(
    (ar) => ar.approved_mentions,
  );
  const [approvalRequest, setApprovalRequest] = useState<
    ApprovalRequestWithAuthorT | undefined
  >();
  const alreadySent =
    !!approvalRequest && !!approvalRequest.notification_last_sent_at;
  const notRequestedMentions = client.instagram_mentions.filter(
    (mention) => !allApprovedMentions.includes(mention),
  );

  const [form] = Form.useForm();

  const { openToast } = useContext(ToastContext);
  const { t } = useTranslate(
    "brands.reportings.partnerships.approval_request_modal",
  );

  const queryClient = useQueryClient();
  const { refetch: create, isLoading: isCreateLoading } = useQuery(
    `${QueryKeys.APPROVAL_REQUEST}-create-${id}`,
    () =>
      createApprovalRequests(
        {
          approval_request: {
            partnership_id: id,
            mentions: form.getFieldValue("mentions"),
          },
        },
        client.id,
      ),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        if (data) {
          createApprovalRequestMutation(queryClient, data, partnership);
          setApprovalRequest(data);
        }
      },
    },
  );

  const {
    refetch: sendEmail,
    isLoading: isSendEmailLoading,
    isError: isSendEmailError,
  } = useQuery(
    `${QueryKeys.APPROVAL_REQUEST}-sendEmail-${id}}`,
    () =>
      createApprovalRequests(
        {
          approval_request: {
            partnership_id: id,
            mentions: form.getFieldValue("mentions"),
            email: form.getFieldValue("email"),
          },
        },
        client.id,
      ),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        if (data) {
          createApprovalRequestMutation(queryClient, data, partnership);
          setApprovalRequest(data);
        }
        close();
        openToast({
          type: "success",
          message: t("notifications.send.success"),
        });
      },
    },
  );

  const {
    refetch: sendAgain,
    isLoading: isSendAgainLoading,
    isError: isSendAgainError,
  } = useQuery(
    `${QueryKeys.APPROVAL_REQUEST}-sendAgain-${id}`,
    () =>
      sendNotificationApprovalRequest(
        approvalRequest?.id,
        {
          approval_request_notification: {
            email: form.getFieldValue("email"),
          },
        },
        client.id,
      ),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        if (data) {
          setApprovalRequest(data);
          openToast({
            type: "success",
            message: t("notifications.send_again.success"),
          });
        }
        close();
      },
    },
  );

  const { data: existingData, isLoading: isExisitingLoading } = useQuery(
    `${QueryKeys.APPROVAL_REQUEST}-${id}}`,
    () =>
      getApprovalRequests(
        client.id,
        getUrlWithQuery(getApiUrl(ApiRoutesE.APPROVAL_REQUESTS), {
          partnership_id: id,
        }),
      ),
  );

  useEffect(() => {
    if (existingData?.length) {
      const data = existingData[0];
      setApprovalRequest(data);
      form.setFieldValue("email", data.email ? data.email : "");
    }
  }, [existingData]);

  const writeToClipboard = (
    approvalRequest: ApprovalRequestWithAuthorT,
  ): Promise<any> => {
    openToast({
      type: "success",
      message: t("notifications.create.success"),
    });

    const link = `${origin}/approval_requests/${approvalRequest.id}`;
    return navigator.clipboard.writeText(link);
  };

  const onCopyHandle = async (): Promise<any> => {
    if (approvalRequest) {
      return writeToClipboard(approvalRequest);
    }

    const observable = await create();
    return observable.data && writeToClipboard(observable.data);
  };

  const getContent = (): JSX.Element => {
    if (isExisitingLoading) {
      return (
        <FlexBox customWidth={CustomWidthE.full}>
          <LoaderComponent />
        </FlexBox>
      );
    }

    return (
      <FlexBox flexDirection="column" alignItems="flex-start" gap={rem(16)}>
        <AvatarTile
          handle={handle}
          name={display_name ? display_name : handle}
          network={network}
          imageUrl={avatar_url}
        />
        <AutocollectInsights client={client} partnership={partnership} />
        <StyledDivider />
        <FlexBox alignItems="flex-start" flexDirection="column" gap={rem(4)}>
          <Paragraph
            paragraph={t("how_it_works.title")}
            paragraphSize="big"
            fontWeight={600}
          />
          <Paragraph
            paragraph={t("how_it_works.description")}
            color={designToken.colorTextSecondary}
          />
        </FlexBox>
        <Form
          form={form}
          initialValues={{ mentions: notRequestedMentions }}
          onFinish={alreadySent ? sendAgain : sendEmail}
          onFinishFailed={(f) => console.log(f)}
          requiredMark={false}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Form.Item
            name="mentions"
            label={t("mentions.label")}
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              disabled={alreadySent}
              options={notRequestedMentions.map((m) => ({
                label: m,
                value: m,
              }))}
              placeholder={t("mentions.placeholder")}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("email.label")}
            rules={[{ required: true, type: "email" }]}
          >
            <Input placeholder={t("email.placeholder")} />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSendEmailLoading || isSendAgainLoading}
            style={{ width: "100%" }}
          >
            {t(alreadySent ? "send_again" : "submit")}
          </Button>
        </Form>
        <Button
          style={{ width: "100%" }}
          onClick={onCopyHandle}
          loading={isCreateLoading}
          icon={<LinkOutlined />}
        >
          {t("copy.label")}
        </Button>
      </FlexBox>
    );
  };

  return (
    <FlexBox flexDirection="column" alignItems="flex-start">
      {getContent()}
    </FlexBox>
  );
};

export default ApprovalRequestModal;
