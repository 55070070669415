import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import Button, { ButtonSizeE, ButtonTypeE } from "../../components/Button";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import Paragraph from "../../components/Paragraph";
import VisibleWrapper from "../../components/VisibleWrapper";
import { ClientsContext } from "../../context-providers/ClientsContextProvider";
import { ClientT } from "../../entry-files/types";
import { rem, theme } from "../../styling/theme";
import Icon, { IconTypeE } from "../../ui/icons/Icon";
import { ClientModalStateT } from "./ClientModal";

const EditButtonWrapper = styled(VisibleWrapper)({
  position: "absolute",
  right: rem(0),
  top: "50%",
  transform: "translateY(-50%)",
  width: "auto",
});

const Wrapper = styled(FlexBox)({
  cursor: "pointer",
});

type DropDownClientItemT = {
  client: ClientT;
  setClientModal: Dispatch<SetStateAction<ClientModalStateT | undefined>>;
  setDropdownHover: Dispatch<SetStateAction<boolean>>;
};

const DropDownClientItem: FC<DropDownClientItemT> = ({
  client,
  setClientModal,
  setDropdownHover,
}) => {
  const [hover, setHover] = useState(false);
  const { setCurrentClientId } = useContext(ClientsContext);

  const onEditClickHandle = (): void => {
    setClientModal({ type: "update", client });
    setDropdownHover(false);
  };

  return (
    <Wrapper
      flexDirection="column"
      alignItems="flex-start"
      customWidth={CustomWidthE.full}
      onClick={() => setCurrentClientId(client.id)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      position="relative"
    >
      <EditButtonWrapper visible={hover}>
        <Button
          stopPropagation={true}
          onClick={() => onEditClickHandle()}
          type={ButtonTypeE.darkGrey}
          size={ButtonSizeE.micro}
        >
          <Icon icon={IconTypeE.edit} size="small" />
        </Button>
      </EditButtonWrapper>

      <Paragraph
        paragraph={client.name}
        fontWeight={600}
        paragraphSize="small"
        truncate={20}
      />
      <Paragraph
        paragraph={`@` + client.instagram_mentions.join(", @")}
        paragraphSize="micro"
        color={theme.color.textGreyColor}
        truncate={20}
      />
    </Wrapper>
  );
};

export default DropDownClientItem;
