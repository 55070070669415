import styled from "@emotion/styled";
import { rem } from "../../styling/theme";
import TableColumn from "./TableColumn";

const TableColumnWrapper = styled(TableColumn)<{ justifyContent?: string }>(
  ({ justifyContent = "flex-end" }) => ({
    justifyContent: justifyContent,
    padding: `${rem(10)} ${rem(4)}`,
  }),
);

export default TableColumnWrapper;
