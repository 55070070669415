import * as React from "react";
import { FC } from "react";
import {
  createBrowserRouter,
  LinkProps,
  Navigate,
  NavigateOptions,
  useNavigate,
} from "react-router-dom";
import ClientsNotExist from "../../features/clients/ClientsNotExist";
import CampaignDetailContainer from "./features/campaign-detail/CampaignDetailContainer";
import CampaignsContainer from "./features/campaigns/CampaignsContainer";
import CompanyRoot from "./features/CompanyRoot";
import CompetitiveAnalysesContainer from "./features/competitive-analyses/CompetitiveAnalysesContainer";
import CompetitiveAnalysisDetailContainer from "./features/competitive-analysis-detail/CompetitiveAnalysisDetailContainer";
import InfluencerDatabaseDetailContainer from "./features/influencer-database-detail/InfluencerDatabaseDetailContainer";
import InfluencerDatabaseContainer from "./features/influencer-database/InfluencerDatabaseContainer";
import OutputsContainer from "./features/outputs/OutputsContainer";
import OverlapAnalisisDetailContainer from "./features/overlap-detail/OverlapAnalisisDetailContainer";
import OverlapAnalysesContainer from "./features/overlaps/OverlapAnalysesContainer";
import PartnershipsContainer from "./features/partnerships/PartnershipsContainer";
import ReportingRoot from "./features/ReportingRoot";
import SubscriptionContainer from "./features/subscription-plans/SubscriptionContainer";
import UsersContainer from "./features/user-management/UsersContainer";
import WizardContainer from "./features/wizard/WizardContainer";

export enum NavigationKeyE {
  Database = "/brands/database",
  OverlapAnalyses = "/brands/overlap_analyses",
  CompetitiveAnalyses = "/brands/competitive_analyses",
  Partnerships = "/brands/reporting/influencers",
  MediaGroups = "/brands/reporting/media_groups",
  Campaigns = "/brands/reporting/campaigns",
  Subscription = "/brands/subscription",
  Users = "/brands/users",
  EmptyClients = "/brands/reporting/clients-not-exist",
  Wizard = "/brands/wizard",
}

export function withCondition<T>(
  Component: FC<any>,
  condition: boolean,
  redirectTo: string,
) {
  return function InnerComponent(props: T) {
    return condition ? (
      <Component {...props} />
    ) : (
      <Navigate to={redirectTo} replace />
    );
  };
}

export const router = createBrowserRouter([
  {
    path: `${NavigationKeyE.Wizard}/*`,
    element: <WizardContainer />,
  },
  {
    path: NavigationKeyE.Subscription,
    element: <SubscriptionContainer />,
  },
  {
    path: "/",
    element: <CompanyRoot />,
    children: [
      {
        index: true,
        path: NavigationKeyE.Database,
        element: <InfluencerDatabaseContainer />,
      },
      {
        path: `${NavigationKeyE.Database}/:id/*`,
        element: <InfluencerDatabaseDetailContainer />,
      },
      {
        path: NavigationKeyE.OverlapAnalyses,
        element: <OverlapAnalysesContainer />,
      },
      {
        path: `${NavigationKeyE.OverlapAnalyses}/:id`,
        element: <OverlapAnalisisDetailContainer />,
      },
      {
        path: NavigationKeyE.CompetitiveAnalyses,
        element: <CompetitiveAnalysesContainer />,
      },
      {
        path: `${NavigationKeyE.CompetitiveAnalyses}/:id/*`,
        element: <CompetitiveAnalysisDetailContainer />,
      },
      {
        path: NavigationKeyE.Users,
        element: <UsersContainer />,
      },
      {
        path: NavigationKeyE.EmptyClients,
        element: <ClientsNotExist />,
      },
      {
        path: "/brands/reporting",
        element: <ReportingRoot />,
        children: [
          {
            path: NavigationKeyE.Partnerships,
            element: <PartnershipsContainer />,
          },
          {
            path: `${NavigationKeyE.MediaGroups}/*`,
            element: <OutputsContainer />,
          },
          {
            path: NavigationKeyE.Campaigns,
            element: <CampaignsContainer />,
          },
          {
            path: `${NavigationKeyE.Campaigns}/:id/*`,
            element: <CampaignDetailContainer />,
          },
        ],
      },
    ],
  },
]);

type RouteLinkProps = Omit<LinkProps, "to"> & { to: NavigationKeyE };
export declare const Link: React.ForwardRefExoticComponent<
  RouteLinkProps & React.RefAttributes<HTMLAnchorElement>
>;

type useRouterNavigateReturnT = (
  path: NavigationKeyE,
  id?: string | number,
  options?: NavigateOptions | undefined,
) => void;
export const useRouterNavigate = (): useRouterNavigateReturnT => {
  const navigate = useNavigate();

  const routerNavigate = (
    path: NavigationKeyE,
    id?: string | number,
    options?: NavigateOptions | undefined,
  ): void => {
    const uri = id ? `${path}/${id}` : path;
    navigate(uri, options);
  };

  return routerNavigate;
};
