import axios from "axios";

type removeCampaignPartnershipPropsT = {
  url: string;
  clientId: string;
};

export const removeCampaignPartnership = async ({
  url,
  clientId,
}: removeCampaignPartnershipPropsT): Promise<{}> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.delete(url, config).then((res) => res.data);
};
