import * as React from "react";
import { FC } from "react";
import { GridWrapper } from "../../../../../components/GridWrapper";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { cardFields, disabledFiledsMap } from "../../../dataConstants";
import { FieldTypeT, MediaT } from "../../../types";
import InputColumn from "./InputColumn";
import InputDate from "./InputDate";
import InputUrl from "./InputUrl";

type InsightsFormT = {
  media: MediaT;
  liveAddedMedia?: boolean;
  isUrlPresent?: boolean;
  network: string;
};

const InsightsForm: FC<InsightsFormT> = ({
  media,
  liveAddedMedia,
  isUrlPresent = true,
  network,
}) => {
  const { kind, id, published_at, url, origin } = media;
  const { t } = useTranslate("brands.reportings.media_groups");

  const fields: FieldTypeT[] =
    kind === "video" ? cardFields[kind][network] : cardFields[kind];

  return (
    <GridWrapper sidesMargin={2}>
      {isUrlPresent && (
        <InputUrl
          id={id}
          label={t(`form.labels.url`)}
          url={url}
          liveAddedMedia={liveAddedMedia}
          permanentDisabled={origin === "private" || origin === "public"}
        />
      )}
      <InputDate
        id={id}
        label={t(`form.labels.published_at`)}
        date={published_at}
        liveAddedMedia={liveAddedMedia}
        permanentDisabled={origin === "private" || origin === "public"}
      />
      {fields.map((key) => {
        const label_key =
          kind == "video" && key == "impressions" ? "views" : key;

        return (
          <InputColumn
            key={key}
            id={id}
            inputKey={key}
            label={t(`form.labels.${label_key}`)}
            value={media[key]}
            liveAddedMedia={liveAddedMedia}
            permanentDisabled={
              !!disabledFiledsMap[origin].find((item) => item === key)
            }
          />
        );
      })}
    </GridWrapper>
  );
};

export default InsightsForm;
