import * as React from "react";
import { FC, useContext, useEffect } from "react";
import Modal from "../../components/Modal";
import { ClientsContext } from "../../context-providers/ClientsContextProvider";
import { ClientT } from "../../entry-files/types";
import { rem } from "../../styling/theme";
import ClientModal from "./ClientModal";
import ClientsList from "./ClientsList";

export const SESSION_STORAGE_KEY = "CLIENT_ID";

type ClientsT = {
  clients: ClientT[];
  isLoading: boolean;
};

const Clients: FC<ClientsT> = ({ clients, isLoading }) => {
  const {
    currentClientId,
    clientModal,
    setClientModal,
    setClientIsLoading,
    setAndStoreClientId,
  } = useContext(ClientsContext);

  const currentClient =
    clients && clients.find((client) => client.id === currentClientId);

  useEffect(() => {
    if (clients && clients.length > 0) {
      setAndStoreClientId(clients);
    }
  }, [clients]);

  useEffect(() => {
    setClientIsLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <ClientsList clients={clients} currentClient={currentClient} />

      <Modal
        visible={!!clientModal}
        close={() => setClientModal(undefined)}
        contentPadding={rem(24)}
        slim={true}
        renderChildren={(visible, close) => (
          <>
            {!!clientModal && (
              <ClientModal
                clientModalState={clientModal}
                close={close}
                visible={visible}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export default Clients;
