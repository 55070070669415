import * as React from "react";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  InfluencerNavigationDetailItemT,
  InfluencerNavigationDetailStepT,
  InfluencerNavigationItemT,
  InfluencerNavigationStateT,
} from "../../types";

type InfluencerNavigationContextT = {
  navigationState: InfluencerNavigationStateT | undefined;
  setNavigationState: Dispatch<
    SetStateAction<InfluencerNavigationStateT | undefined>
  >;
};

export const InfluencerNavigationContext =
  createContext<InfluencerNavigationContextT>({
    navigationState: "approvals",
    setNavigationState: () => {},
  });

const isOfType = (value: string): value is InfluencerNavigationItemT =>
  ["approvals", "approval_requests"].includes(value);

const isInfluencerNavigationDetailStep = (
  value: string,
): value is InfluencerNavigationDetailStepT =>
  ["welcome", "security", "tutorials", "approval"].includes(value);

export const isInfluencerNavigationDetailItem = (
  value: InfluencerNavigationDetailItemT | InfluencerNavigationItemT,
): value is InfluencerNavigationDetailItemT => {
  return (value as InfluencerNavigationDetailItemT).key !== undefined;
};

const getCurrentStep = (): InfluencerNavigationDetailStepT => {
  const step = window.location.href.split("#")[1] || "welcome";
  if (step.length > 0 && isInfluencerNavigationDetailStep(step)) {
    return step;
  }

  return "welcome";
};

export const getCurrentUrlKey = (): InfluencerNavigationStateT => {
  const pathName = window.location.pathname;
  const queryResult = pathName
    .split("/")
    .filter((item) => !["", "influencers", "reporting"].includes(item));

  if (queryResult.length > 0 && isOfType(queryResult[0])) {
    if (queryResult[0] === "approval_requests" && queryResult[1]) {
      return {
        key: queryResult[0],
        id: queryResult[1],
        step: getCurrentStep(),
      };
    }
    return queryResult[0];
  }

  return "approvals";
};

export const InfluencerNavigationContextProvider: FC = ({ children }) => {
  const [navigationState, setNavigationState] =
    useState<InfluencerNavigationStateT>();

  const setCurrentKeyToNavigationState = (): void => {
    const currentUrlKey = getCurrentUrlKey();

    if (currentUrlKey) {
      setNavigationState(currentUrlKey);
    }
  };

  useEffect(() => {
    setCurrentKeyToNavigationState();
  }, []);

  useEffect(() => {
    if (!navigationState) {
      setCurrentKeyToNavigationState();
      return;
    }

    if (navigationState !== getCurrentUrlKey()) {
      if (isInfluencerNavigationDetailItem(navigationState)) {
        window.history.pushState(
          "",
          "",
          `/${navigationState.key}/${navigationState.id}#${navigationState.step}`,
        );
        return;
      }
      window.history.pushState(
        "",
        "",
        `/influencers/reportings/${navigationState}`,
      );
    }
  }, [navigationState]);

  return (
    <InfluencerNavigationContext.Provider
      value={{
        navigationState,
        setNavigationState,
      }}
    >
      {children}
    </InfluencerNavigationContext.Provider>
  );
};
