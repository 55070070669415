import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { BillingAddressT, SessionT } from "../../types";

type PayloadT = {
  user: {
    name: string;
    brand: {
      company_name: string;
      billing_address_attributes?: BillingAddressT;
    };
  };
};

export const updateUser = async (
  name: string,
  company_name: string,
  billing_address?: BillingAddressT | undefined,
): Promise<SessionT> => {
  const payload: PayloadT = {
    user: { name: name, brand: { company_name: company_name } },
  };
  if (billing_address) {
    payload.user.brand.billing_address_attributes = billing_address;
  }

  return axios
    .patch(getApiUrl(ApiRoutesE.CURRENT_USER), payload)
    .then((res) => res.data.session);
};
