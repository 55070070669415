import axios from "axios";
import { MediaGroupT } from "../types";

type GetMediaGroupsPropsT = {
  clientId: string;
  apiUrl: string;
};

type getMediaGroupsT = (props: GetMediaGroupsPropsT) => Promise<MediaGroupT[]>;
export const getMediaGroups: getMediaGroupsT = async ({ clientId, apiUrl }) => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.get(apiUrl, config).then((res) => res.data.media_groups);
};
