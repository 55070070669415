import * as React from "react";
import { FC } from "react";
import Button from "../../../../components/Button";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import { ApprovalPageStateT } from "../../types";
import ApprovalContent from "../../ui/ApprovalContent";

type EmailMissingT = {
  setApprovalPageState: (
    value: React.SetStateAction<ApprovalPageStateT>,
  ) => void;
};

const EmailMissing: FC<EmailMissingT> = ({ setApprovalPageState }) => {
  const { t } = useTranslate("influencer_root.approval_request.email");

  return (
    <ApprovalContent
      heading={t("title")}
      text={
        <>
          <Paragraph
            align="inherit"
            paragraph={t("note")}
            color={theme.color.textGreyDarkColor}
            fontWeight={500}
          />
          <Spacer direction="vertical" />
        </>
      }
    >
      <Button onClick={() => setApprovalPageState("email_missing")}>
        {t("confirm")}
      </Button>
    </ApprovalContent>
  );
};

export default EmailMissing;
