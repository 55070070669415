export const calculateMonthlyAmount = (
  period_unit: "month" | "year",
  amount: number,
): string => {
  if (period_unit === "year") {
    return (amount / 12).toString();
  }

  return amount.toString();
};
