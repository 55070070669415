import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export const getHashtags = async (clientId: string): Promise<string[]> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .get(getApiUrl(ApiRoutesE.HASHTAGS), config)
    .then((res) => res.data.hashtags);
};
