import styled from "@emotion/styled";
import { Form, FormProps } from "antd";
import { countries } from "jsvat";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox from "../../../../components/FlexBox";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { useTranslate } from "../../../../hooks/useTranslate";
import { SubscriptionPayloadT } from "../../../types";
import { SubscriptionContext } from "../../context-providers/SubscriptionsContextProvider";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { acceptSubscriptionProposal } from "../../data-access/acceptSubscriptionProposal";
import { createSubscriptionMutation } from "../../data-access/mutation/createSubscriptionMutation";
import { prepareSubscriptionProposalCheckout } from "../../data-access/prepareSubscriptionProposalCheckout";
import { updateUser } from "../../data-access/updateUser";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { SubscriptionProposalT } from "../../types";
import CheckoutForm from "./CheckoutForm";

const Wrapper = styled(FlexBox)({
  width: "100%",
  height: "100%",
  flexDirection: "column",
  alignItems: "stretch",
});

type FieldType = {
  company: string;
  address: string;
  city: string;
  zip: string;
  country: string;
  vat: string;
  tac: boolean;
  invoice_emails?: string[];
};

type SubscriptionProposalPropsT = {
  subscriptionProposal: SubscriptionProposalT;
};

const SubscriptionProposal: FC<SubscriptionProposalPropsT> = ({
  subscriptionProposal,
}) => {
  const { session } = useContext(ApplicationContext);
  const { chargebee, setSubscription } = useContext(SubscriptionContext);
  const { openToast } = useContext(ToastContext);
  const navigate = useRouterNavigate();

  const subscription = session?.subscription || undefined;
  const address = session?.brand?.billing_address;
  const payment_type = session?.payment_method || "card";

  const { t } = useTranslate("brands.wizard.checkout");

  const toastAndRedirect = (): void => {
    setTimeout(() => {
      navigate(NavigationKeyE.Database);
    }, 1000);
    openToast({
      type: "success",
      message: t(`messages.success_${payment_type}`, {
        link: NavigationKeyE.Subscription,
      }),
      button: {
        text: t("messages.button"),
        onClick: () => navigate(NavigationKeyE.Subscription),
      },
    });
  };

  const queryClient = useQueryClient();
  const { mutate: saveSubscription, isLoading: isLoadingSave } = useMutation(
    (payload: SubscriptionPayloadT) => acceptSubscriptionProposal(payload),
    {
      onSuccess: (data) => {
        createSubscriptionMutation(queryClient, data, () => toastAndRedirect());
      },
      onError: () => {
        openToast({
          type: "error",
          message: t("messages.error"),
        });
      },
    },
  );
  const openChargebeeCheckout = (): void => {
    chargebee.openCheckout({
      layout: "in_app",
      hostedPage: () => prepareSubscriptionProposalCheckout(),
      error: () => {
        openToast({
          type: "error",
          message: t("messages.error"),
        });
      },
      success: (hostedPageId: string) => {
        saveSubscription({ hosted_page_id: hostedPageId });
        setTimeout(() => {
          chargebee.closeAll();
        }, 1000);
      },
    });
  };
  const { mutate: storeInvoiceDetails, isLoading: isLoadingUpdate } =
    useMutation(
      (params: FieldType) =>
        updateUser(session?.nick_or_name || "", params.company, {
          firm_name: params.company,
          street: params.address,
          city: params.city,
          zip_code: params.zip,
          country_code: countries.find((c) => c.name === params.country)
            ?.codes[0] as string,
          firm_dic: params.vat,
        }),
      {
        onSuccess: () => {
          if (payment_type === "card") {
            openChargebeeCheckout();
          } else {
            saveSubscription({ hosted_page_id: null });
          }
        },
        onError: () => {
          openToast({
            type: "error",
            message: t("messages.error"),
          });
        },
      },
    );

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    storeInvoiceDetails(values);
  };

  useEffect(() => {
    setSubscription(subscription);
  }, [subscription]);

  return (
    <Wrapper>
      <Form
        name="checkout"
        layout="vertical"
        labelCol={{ span: 8 }}
        style={{ width: "100%", height: "100%", display: "flex" }}
        disabled={isLoadingSave || isLoadingUpdate}
        initialValues={{
          company: session?.brand?.company_name,
          address: address?.street,
          city: address?.city,
          zip: address?.zip_code,
          country:
            address?.country_code &&
            countries.find((c) => c.codes[0] === address?.country_code)?.name,
          vat: address?.firm_dic,
        }}
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <CheckoutForm
          subscriptionProposal={subscriptionProposal}
          payment_type={payment_type}
        />
      </Form>
    </Wrapper>
  );
};

export default SubscriptionProposal;
