import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Popover, Table as AntTable } from "antd";
import { ColumnsType } from "antd/es/table";
import * as React from "react";
import { FC, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import NoResults from "../../../../components/NoResults";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { designToken } from "../../../../helpers/antDesign";
import { getDateTime } from "../../../../helpers/date";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import TableContentWrapper from "../../../../ui/table/TableContentWrapper";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { removeOverlapAnalysisMutation } from "../../data-access/mutation/removeOverlapAnalysisMutation";
import { removeOverlapAnalysis } from "../../data-access/removeOverlapAnalysis";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { OverlapAnalysisT } from "../../types";

export type PartnershipToRemoveT = {
  id: string;
  removed: boolean;
};

interface DataType {
  key: string;
  overlap: OverlapAnalysisT;
}

type TableT = {
  dataIsLoading: boolean;
  onAddNewCampaignClickHandle: () => void;
  overlapAnalyses?: OverlapAnalysisT[];
};

const Table: FC<TableT> = ({
  dataIsLoading,
  onAddNewCampaignClickHandle = () => {},
  overlapAnalyses,
}) => {
  const { t } = useTranslate("brands.overlap_analyses.index");

  const { openToast } = useContext(ToastContext);
  const navigate = useRouterNavigate();

  const queryClient = useQueryClient();
  const { mutate: destroy } = useMutation(
    (id: string) => removeOverlapAnalysis(id),
    {
      onSuccess: (_, id) => {
        removeOverlapAnalysisMutation(queryClient, id);
        openToast({
          type: "success",
          message: t("remove_success"),
        });
      },
    },
  );

  const showStatus = (overlapAnalysis: OverlapAnalysisT): JSX.Element => {
    switch (overlapAnalysis.status) {
      case "no_data":
        return (
          <Popover content={t("no_data_info_html")}>
            <FlexBox justifyContent="flex-start">
              <Paragraph paragraph={t("no_data")} />
              <Spacer size="mini" />
              <Icon
                icon={IconTypeE.infoCircle}
                color={theme.color.textGreyColor}
                size="small"
                zIndex={"auto"}
              />
            </FlexBox>
          </Popover>
        );
      case "pending":
        return <Paragraph paragraph={t("pending")} />;
      case "finished":
        return (
          <Button
            type="primary"
            onClick={() =>
              navigate(NavigationKeyE.OverlapAnalyses, overlapAnalysis.id)
            }
          >
            {t("show")}
          </Button>
        );
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.title"),
      dataIndex: "title",
      render: (_, record) => record.overlap.title,
      sorter: (a, b) => a.overlap.title.localeCompare(b.overlap.title),
    },
    {
      title: t("table.handles"),
      dataIndex: "handles",
      render: (_, record) => record.overlap.handles.join(", "),
    },
    {
      title: t("table.created_at"),
      dataIndex: "created_at",
      render: (_, record) =>
        getDateTime(record.overlap.created_at, "shortDate"),
      sorter: (a, b) =>
        a.overlap.created_at.localeCompare(b.overlap.created_at),
    },
    {
      title: undefined,
      dataIndex: "action",
      width: "20%",
      render: (_, record) => (
        <FlexBox justifyContent="space-between">
          {showStatus(record.overlap)}
          {record.overlap.status === "no_data" && (
            <Popconfirm
              title={t("delete_confirmation")}
              onConfirm={() => destroy(record.overlap.id)}
              okText={t("modal.ok")}
              cancelText={t("modal.cancel")}
            >
              <Button
                type="text"
                icon={<DeleteOutlined />}
                style={{ color: designToken.colorTextTertiary }}
              />
            </Popconfirm>
          )}
        </FlexBox>
      ),
    },
  ];

  const getTableContent = (): JSX.Element => {
    if (overlapAnalyses && overlapAnalyses.length > 0) {
      const data: DataType[] = overlapAnalyses.map((overlap) => ({
        key: overlap.id,
        overlap: overlap,
      }));

      return (
        <AntTable
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return (
      <TableContentWrapper blocksAreRows={true}>
        <NoResults illustrationSize={350} message={t("no_results.heading")} />
        <Spacer direction="vertical" />
        <Paragraph align="center" paragraph={t("no_results.paragraph")} />
        <Spacer direction="vertical" />
        {/* <Button onClick={onAddNewCampaignClickHandle}>
          <Icon icon={IconTypeE.plus} size="mini" />
          <Spacer size="micro" />
          {t("no_results.create_first")}
        </Button> */}
      </TableContentWrapper>
    );
  };

  return <Panel>{getTableContent()}</Panel>;
};

export default Table;
