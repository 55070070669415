import styled from "@emotion/styled";
import { Divider, Dropdown, MenuProps } from "antd";
import * as React from "react";
import { FC, useContext, useState } from "react";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import { ClientsContext } from "../../context-providers/ClientsContextProvider";
import { ClientT } from "../../entry-files/types";
import { useTranslate } from "../../hooks/useTranslate";
import { rem, theme } from "../../styling/theme";
import { DropdownItem } from "../../ui/Dropdown";
import Icon, { IconTypeE } from "../../ui/icons/Icon";
import DropDownClient from "./DropDownClientItem";

const DropdownWrapper = styled.div({
  boxShadow: `0px 2px 9px rgb(0 0 0 / 10%)`,

  ".ant-dropdown": {
    padding: 0,
  },
});

const DropdownInnerWrapper = styled(FlexBox)({
  flexDirection: "column",
  justifyContent: "flex-start",
  overflow: "scroll",
  maxHeight: "60dvh",
  width: "100%",

  "ul.ant-dropdown-menu": {
    padding: 0,
    boxShadow: "none",
    width: "100%",

    ".ant-dropdown-menu-item": {
      borderBottom: `1px solid ${theme.color.greyColor}`,
      borderRadius: 0,
    },
  },
});

const AddClientWrapper = styled(DropdownItem)({
  cursor: "pointer",
  width: "100%",
});

const CurrentClientWrapper = styled(FlexBox)<{ isActive: boolean }>(
  ({ isActive }) => ({
    width: "100%",
    padding: `${rem(4)} ${rem(8)}`,
    borderRadius: rem(4),
    backgroundColor: isActive
      ? theme.color.greyLightColor
      : theme.color.whiteColor,
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    border: `1px solid ${theme.color.greyColor}`,

    [theme.media.maxMd]: {
      width: "100%",
    },
  }),
);

type ClientsListT = {
  clients: ClientT[];
  currentClient?: ClientT;
};

const ClientsList: FC<ClientsListT> = ({ clients, currentClient }) => {
  const [dropdownHover, setDropdownHover] = useState(false);

  const { t } = useTranslate("brands.reportings.clients");
  const { setClientModal } = useContext(ClientsContext);

  const items: MenuProps["items"] = clients.map((client) => {
    return {
      key: client.id,
      label: (
        <DropDownClient
          key={client.id}
          client={client}
          setClientModal={setClientModal}
          setDropdownHover={setDropdownHover}
        />
      ),
      onClick: () => {
        console.log("clicked");
      },
    };
  });

  return (
    <FlexBox customWidth={CustomWidthE.full}>
      <Dropdown
        menu={{ items }}
        open={dropdownHover}
        onOpenChange={() => setDropdownHover((prev) => !prev)}
        placement="bottomLeft"
        dropdownRender={(menu) => (
          <DropdownWrapper>
            <DropdownInnerWrapper>
              {React.cloneElement(menu as React.ReactElement)}
            </DropdownInnerWrapper>
            <Divider style={{ margin: 0 }} />
            <AddClientWrapper
              justifyContent="center"
              onClick={() => {
                setDropdownHover(false);
                setClientModal({ type: "create" });
              }}
            >
              <FlexBox cursor="pointer">
                <Icon icon={IconTypeE.plus} size="mini" />
                <Spacer size="micro" />
                <Paragraph
                  paragraphSize="small"
                  paragraph={t("add")}
                  fontWeight={600}
                />
              </FlexBox>
            </AddClientWrapper>
          </DropdownWrapper>
        )}
      >
        <CurrentClientWrapper
          isActive={dropdownHover}
          justifyContent="space-between"
          cursor="pointer"
        >
          <FlexBox
            flexDirection="column"
            alignItems="flex-start"
            cursor="pointer"
          >
            <Paragraph
              color={theme.color.textGreyDarkColor}
              paragraph={`${t("current")}:`}
              fontWeight={500}
              paragraphSize="micro"
            />
            <Paragraph
              color={theme.color.textGreyDarkColor}
              paragraph={currentClient ? currentClient.name : t("no_client")}
              fontWeight={600}
              paragraphSize="small"
              truncate={20}
            />
          </FlexBox>
          <Icon
            icon={IconTypeE.arrowBottom}
            color={theme.color.textGreyDarkColor}
            size="micro"
          />
        </CurrentClientWrapper>
      </Dropdown>
    </FlexBox>
  );
};

export default ClientsList;
