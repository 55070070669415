import axios from "axios";
import { ApprovalRequestWithAuthorT } from "../../types";

export const getApprovalRequests = async (
  clientId: string,
  apiUrl: string,
): Promise<ApprovalRequestWithAuthorT[]> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.get(apiUrl, config).then((res) => res.data.approval_requests);
};
