import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { InfiniteData } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Modal from "../../../../../components/Modal";
import NoResults from "../../../../../components/NoResults";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { useLocalSort } from "../../../../../hooks/useLocalSort";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { getRGBaColor, rem, theme } from "../../../../../styling/theme";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableContentWrapper from "../../../../../ui/table/TableContentWrapper";
import { NetworkProfilesResponseT, NetworkProfileT } from "../../../../types";
import { ExistedOutputT } from "../../../types";
import OutputsModal from "../../../ui/OutputsModal";
import { FilterDataTotalsT, SearchQueryT } from "../types";
import ProfileRow from "./ProfileRow";
import TableHeader from "./TableHeader";

const rgbaYellow = getRGBaColor(theme.color.yellowColor, 0.25);

const LoaderContainer = styled(FlexBox)({
  height: rem(4),
  width: "100%",
});

const Loader = styled.div({
  width: "100%",
  height: "4px",
  display: "inline-block",
  backgroundColor: theme.color.primaryColor,
  backgroundImage: `linear-gradient(45deg, ${rgbaYellow} 25%, transparent 25%, transparent 50%, ${rgbaYellow} 50%, ${rgbaYellow} 75%, transparent 75%, transparent)`,
  fontSize: "30px",
  backgroundSize: "1em 1em",
  boxSizing: "border-box",
  animation: "barStripe 1s linear infinite",
});

const StickyHeader = styled(FlexBox)({
  flexDirection: "column",
  width: "100%",
  position: "sticky",
  top: rem(0),
  zIndex: 2,
  backgroundColor: theme.color.whiteColor,

  [theme.media.maxSm]: {
    borderRadius: `0 0 ${rem(8)} ${rem(8)}`,
    borderBottom: `1px solid ${theme.color.greyLightColor}`,
    top: rem(64),
  },
});

const LoadMoreTableBottom = styled(TableColumn)({
  width: "100%",
  justifyContent: "center",
  cursor: "pointer",
  borderBottomLeftRadius: rem(8),
  borderBottomRightRadius: rem(8),
  backgroundColor: theme.color.whiteColor,
  borderRadius: `0 0 ${rem(8)} ${rem(8)}`,
  borderTop: `1px solid ${theme.color.greyLightColor}`,
  gap: rem(5),

  ":hover": {
    backgroundColor: theme.color.greyLightColor,
  },

  [theme.media.maxSm]: {
    borderTop: "none",
    borderRadius: rem(8),
  },
});

const RowsWrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  backgroundColor: "#F7F7F9",
});

type TableT = {
  data?: InfiniteData<NetworkProfilesResponseT>;
  dataIsLoading: boolean;
  onAddNewProfileHandle: () => void;
  onLoadMoreHandle: () => void;
  hasNextPage: boolean | undefined;
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
  totalsCount: FilterDataTotalsT;
  listNetworkProfiles?: NetworkProfileT[];
};

const Table: FC<TableT> = ({
  data,
  dataIsLoading,
  onAddNewProfileHandle = () => {},
  onLoadMoreHandle = () => {},
  hasNextPage,
  searchQuery,
  setFilterSearchQuery,
  totalsCount,
  listNetworkProfiles,
}) => {
  const [networkProfileIds, setNetworkProfileIds] = useState<string[]>([]);
  const [outputs, setOutputs] = useState<ExistedOutputT[] | null>(null);

  const sortBy = searchQuery.sort.split(":")[0];
  const { sortedCollection, setSortingOptions, setSortedCollection } =
    useLocalSort<NetworkProfileT>(sortBy, listNetworkProfiles);

  const { t } = useTranslate("brands.influencer_database.table");

  const profileRows = (profiles: NetworkProfileT[]): JSX.Element => {
    return (
      <>
        {profiles.map((profile, index) => (
          <ProfileRow
            key={index}
            networkProfile={profile}
            networkProfileIds={networkProfileIds}
            setNetworkProfileIds={setNetworkProfileIds}
            listMode={listNetworkProfiles && listNetworkProfiles?.length > 0}
            setOutputs={setOutputs}
            searchQuery={searchQuery}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    listNetworkProfiles &&
      setSortingOptions({ order: "desc", attribute: sortBy });
  }, [searchQuery.sort]);

  useEffect(() => {
    setSortedCollection(listNetworkProfiles);
  }, [listNetworkProfiles]);

  const getTableContent = (): JSX.Element => {
    if (sortedCollection) {
      return profileRows(sortedCollection);
    }

    if (data && data.pages[0].network_profiles.length > 0) {
      return (
        <>
          {data &&
            data.pages.map((group, i) => (
              <RowsWrapper key={i}>
                {profileRows(group.network_profiles)}
              </RowsWrapper>
            ))}
          {hasNextPage && (
            <LoadMoreTableBottom onClick={onLoadMoreHandle}>
              <DownOutlined rev="" />
              {t("load_more")}
            </LoadMoreTableBottom>
          )}

          <Modal
            visible={!!outputs}
            heading={t("outputs")}
            close={() => setOutputs(null)}
            side="right"
            renderChildren={() => {
              return <OutputsModal outputs={outputs} />;
            }}
          />
        </>
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return (
      <TableContentWrapper blocksAreRows={true}>
        <NoResults illustrationSize={350} message={t("no_results.heading")} />
        <Spacer direction="vertical" />
        <Paragraph align="center" paragraph={t("no_results.paragraph")} />
        <Spacer direction="vertical" />
        <Button
          type="primary"
          icon={<PlusOutlined rev="" />}
          onClick={onAddNewProfileHandle}
        >
          {t("no_results.add")}
        </Button>
      </TableContentWrapper>
    );
  };

  return (
    <>
      <StickyHeader>
        <TableHeader
          currentCount={(data && data.pages[0].meta.total) || 0}
          totalCount={totalsCount[searchQuery.provider_eq]}
          searchQuery={searchQuery}
          setFilterSearchQuery={setFilterSearchQuery}
          networkProfileIds={networkProfileIds}
          setNetworkProfileIds={setNetworkProfileIds}
          listProfilesCount={listNetworkProfiles?.length || 0}
        />
        <LoaderContainer>{dataIsLoading && <Loader />}</LoaderContainer>
      </StickyHeader>
      {getTableContent()}
    </>
  );
};

export default Table;
