import * as React from "react";
import { FC } from "react";
import { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { rem } from "../../../../../styling/theme";
import TableColumn from "../../../../../ui/table/TableColumn";

type MentionsColumnT = {
  mentions?: string[];
};

const MentionsColumn: FC<MentionsColumnT> = ({ mentions }) => {
  return (
    <TableColumn
      columnWidth={CustomWidthE.custom30}
      padding={rem(16)}
      overflow="hidden"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
      cursor="inherit"
    >
      <Paragraph
        paragraph={
          mentions && mentions.length > 0 ? `@${mentions.join(", @")}` : "-"
        }
        wordBreak="break-word"
        paragraphSize="small"
      />
    </TableColumn>
  );
};

export default MentionsColumn;
