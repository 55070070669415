import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import { formatTableValue } from "../../../../../helpers/formaters";
import { theme } from "../../../../../styling/theme";
import { EngagementRateMedianT } from "../../../types";

const Wrapper = styled(FlexBox)<{ color: string }>(({ color }) => ({
  color: color,
}));

type EngagementBenchmarkT = {
  value: number | null;
  median?: EngagementRateMedianT;
};

const EngagementBenchmark: FC<EngagementBenchmarkT> = ({ value, median }) => {
  const colorTier = (): string => {
    if (median) {
      if (value === null) {
        return theme.color.blackColor;
      }

      const { min, max } = median;
      const low = min * 0.7;
      const avg = (min + max) / 2;

      switch (true) {
        case value < low:
          return theme.color.errorColor;
        case value >= low && value < avg:
          return theme.color.yellowPressedColor;
        default:
          return theme.color.successColor;
      }
    }

    return "";
  };

  return (
    <Wrapper color={colorTier()}>
      {formatTableValue(value)}
      {value !== null && "%"}
    </Wrapper>
  );
};

export default EngagementBenchmark;
