export enum QueryKeys {
  APPROVAL = "APPROVAL",
  APPROVAL_REQUEST = "APPROVAL_REQUEST",
  APPROVALS = "APPROVALS",
  AUTHORIZATION = "AUTHORIZATION",
  CAMPAIGN_DETAIL = "CAMPAIGN_DETAIL",
  CAMPAIGN_MEDIA_GROUPS = "CAMPAIGN_MEDIA_GROUPS",
  CAMPAIGN_MEDIA_GROUPS_ADDED = "CAMPAIGN_MEDIA_GROUPS_ADDED",
  CAMPAIGN_PARTNERSHIPS = "CAMPAIGN_PARTNERSHIPS",
  CAMPAIGNS = "CAMPAIGNS",
  CAMPAIGNS_ANALYTICS = "CAMPAIGNS_ANALYTICS",
  CLIENTS = "CLIENTS",
  COMPETITIVE_ANALYSES = "COMPETITIVE_ANALYSES",
  COMPETITIVE_ANALYSIS_ALL_OUTPUTS = "COMPETITIVE_ANALYSIS_ALL_OUTPUTS",
  COMPETITIVE_ANALYSIS_DETAIL = "COMPETITIVE_ANALYSIS_DETAIL",
  COMPETITIVE_ANALYSIS_OUTPUTS = "COMPETITIVE_ANALYSIS_OUTPUTS",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  FILTER_HASHTAGS = "FILTER_HASHTAGS",
  FILTER_MENTIONS = "FILTER_MENTIONS",
  IG_HANDLES = "IG_HANDLES",
  LISTS = "LISTS",
  LISTS_DETAIL = "LISTS_DETAIL",
  MEDIA_GROUPS = "MEDIA_GROUPS",
  MEDIA_GROUPS_ADDED = "MEDIA_GROUPS_ADDED",
  NETWORK_PROFILE_BRAND_AFFINITY = "NETWORK_PROFILE_BRAND_AFFINITY",
  NETWORK_PROFILE_CONTENT_OUTPUTS = "NETWORK_PROFILE_CONTENT_OUTPUTS",
  NETWORK_PROFILE_DETAIL = "NETWORK_PROFILE_DETAIL",
  NETWORK_PROFILE_SUGGESTIONS = "NETWORK_PROFILE_SUGGESTIONS",
  NETWORK_PROFILES = "NETWORK_PROFILES",
  NETWORK_PROFILES_CONTACT = "NETWORK_PROFILES_CONTACT",
  NETWORK_PROFILES_FILTER = "NETWORK_PROFILES_FILTER",
  OVERLAP_ANALYSES = "OVERLAP_ANALYSES",
  OVERLAP_ANALYSIS_DETAIL = "OVERLAP_ANALYSIS_DETAIL",
  PARTNERSHIPS = "PARTNERSHIPS",
  PPTXJSON = "PPTXJSON",
  SESSION = "SESSION",
  SUBSCRIPTIONS_PLANS = "SUBSCRIPTIONS_PLANS",
  SUBSCRIPTIONS_PROPOSAL = "SUBSCRIPTIONS_PROPOSAL",
  TAGS = "TAGS",
  USERS = "USERS",
}
