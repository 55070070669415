import { CheckCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { SubscriptionEntitlementT } from "../../../types";

const EntitlementWrapper = styled(FlexBox)({
  width: "90%",
  justifyContent: "left",
  paddingBottom: rem(15),
});

const Check = styled(CheckCircleOutlined)({
  marginRight: rem(10),
});

const ValueText = styled(Paragraph)({
  paddingRight: rem(5),
  fontWeight: 600,
});

type EntitlementT = {
  entitlement: SubscriptionEntitlementT;
  showValue: boolean;
  translationValue: boolean;
};

const Entitlement: FC<EntitlementT> = ({
  entitlement,
  showValue,
  translationValue,
}) => {
  const translationKey = translationValue
    ? entitlement.value.toString()
    : entitlement.feature_id;
  const { t } = useTranslate("brands.subscription_plans.entitlements");

  return (
    <EntitlementWrapper justifyContent="space-between">
      <Check style={{ color: designToken.green5 }} />
      {showValue && <ValueText paragraph={entitlement.value.toString()} />}
      <Paragraph
        paragraph={t(translationKey.replace("-", "_"), {
          count: entitlement.value,
        })}
      />
    </EntitlementWrapper>
  );
};

export default Entitlement;
