import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Segmented, SegmentedProps } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import { Route, Routes, useMatch } from "react-router-dom";
import FlexBox from "../../../../components/FlexBox";
import { ClientsContext } from "../../../../context-providers/ClientsContextProvider";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import ReportingSubmenu from "../components/ReportingContainer";
import Cards from "./Cards";
import Table from "./Table";

const SegmentedWrapper = styled(FlexBox)({
  width: rem(210),
  marginBottom: rem(32),
});

export type SectionTypeT = "cards" | "table";

const OutputsContainer: FC = () => {
  const { currentClientId: clientId } = useContext(ClientsContext) as {
    currentClientId: string;
  };

  const match = useMatch(`${NavigationKeyE.MediaGroups}/:tab`);
  const activeTab = match ? (match.params.tab as SectionTypeT) : "cards";

  const navigate = useRouterNavigate();
  const { t } = useTranslate("brands.reportings.outputs");

  const options: SegmentedProps["options"] = [
    {
      value: "cards",
      label: t("cards"),
      icon: <AppstoreOutlined />,
    },
    {
      value: "table",
      label: t("table.title"),
      icon: <BarsOutlined />,
    },
  ];

  return (
    <ReportingSubmenu>
      <SegmentedWrapper>
        <Segmented
          style={{ width: "100%" }}
          options={options}
          onChange={(key) => navigate(NavigationKeyE.MediaGroups, key)}
          value={activeTab}
        />
      </SegmentedWrapper>
      <Routes>
        <Route path="cards" element={<Cards clientId={clientId} />} />
        <Route path="table" element={<Table clientId={clientId} />} />
      </Routes>
    </ReportingSubmenu>
  );
};

export default OutputsContainer;
