import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Button, { ButtonTypeE } from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Modal from "../../../../../components/Modal";
import ModalDialog from "../../../../../components/ModalDialog";
import NoResults from "../../../../../components/NoResults";
import Panel from "../../../../../components/Panel";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../../../hooks/useTranslate";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import TableContentWrapper from "../../../../../ui/table/TableContentWrapper";
import { removeCampaignMutation } from "../../../data-access/mutation/removeCampaignMutation";
import { removeCampaign } from "../../../data-access/removeCampaign";
import { CampaignT } from "../../../types";
import TableHead from "./TableHead";
import TableRow from "./TableRow";

type TableT = {
  clientId: string;
  dataIsLoading: boolean;
  onAddNewCampaignClickHandle: () => void;
  campaigns?: CampaignT[];
};

export type CampaignToRemoveT = {
  id: string;
  removed: boolean;
};

const Table: FC<TableT> = ({
  clientId,
  dataIsLoading,
  onAddNewCampaignClickHandle,
  campaigns,
}) => {
  const [removeCampaignDisable, setRemoveCampaignDisable] =
    useState<boolean>(false);
  const [campaignToRemove, setCampaignToRemove] = useState<
    CampaignToRemoveT | undefined
  >(undefined);

  const { t } = useTranslate("brands.reportings");

  const queryClient = useQueryClient();
  const { mutate } = useMutation(removeCampaign, {
    onMutate: () => {
      setRemoveCampaignDisable(true);
      setCampaignToRemove((prevState) => {
        if (prevState) {
          return { ...prevState, removed: true };
        }

        return prevState;
      });
    },
    onSuccess: () => {
      if (campaignToRemove) {
        setTimeout(() => {
          removeCampaignMutation(
            queryClient,
            campaignToRemove.id,
            clearStatesAfterRemoving,
          );
        }, 300);
      }
    },

    onError: () => {
      clearStatesAfterRemoving();
    },
  });

  const clearStatesAfterRemoving = (): void => {
    setCampaignToRemove(undefined);
    setRemoveCampaignDisable(false);
  };

  const removeCampaignHandle = (): void => {
    if (campaignToRemove) {
      const data = {
        campaignId: campaignToRemove.id,
        clientId,
      };

      mutate(data);
    }
  };

  const getTableContent = (): JSX.Element => {
    if (campaigns && campaigns.length > 0) {
      return (
        <>
          <TableHead />
          {campaigns.map((item, index) => (
            <TableRow
              key={item.id}
              campaign={item}
              clientId={clientId}
              last={campaigns.length - 1 === index}
              hide={
                !!(
                  campaignToRemove &&
                  campaignToRemove.id === item.id &&
                  campaignToRemove.removed
                )
              }
              setCampaignToRemove={setCampaignToRemove}
            />
          ))}
        </>
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return (
      <TableContentWrapper blocksAreRows={true}>
        <NoResults
          illustrationSize={350}
          message={t("index.sections.campaigns.empty_title")}
        />
        <Spacer direction="vertical" />
        <Paragraph
          align="center"
          paragraph={t("index.sections.campaigns.empty_text")}
        />
        <Spacer direction="vertical" />
        <Button onClick={onAddNewCampaignClickHandle}>
          <Icon icon={IconTypeE.plus} size="mini" />
          <Spacer size="micro" />
          {t("campaigns.new")}
        </Button>
      </TableContentWrapper>
    );
  };

  return (
    <>
      <Panel flexDirection="column" position="relative">
        {getTableContent()}
      </Panel>

      <Modal
        visible={!!campaignToRemove}
        close={() => setCampaignToRemove(undefined)}
        slim={true}
        renderChildren={() => (
          <ModalDialog
            buttonCloseTitle={t("campaigns.close")}
            buttonConfirmTitle={t("campaigns.remove_modal.confirm")}
            confirmButtonType={ButtonTypeE.error}
            disabledConfirmButton={removeCampaignDisable}
            onCloseHandle={() => setCampaignToRemove(undefined)}
            onConfirmHandle={removeCampaignHandle}
            subtitle={t("partnerships.remove_modal.question")}
            title={t("partnerships.remove_modal.title")}
          />
        )}
      />
    </>
  );
};

export default Table;
