import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Radio,
  Segmented,
  Select,
  Tag,
} from "antd";
import { SegmentedLabeledOption } from "antd/es/segmented";
import { checkVAT, countries } from "jsvat";
import { postcodeValidator } from "postcode-validator";
import * as React from "react";
import { FC, useState } from "react";
import BlueLink from "../../../../components/BlueLink";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { putSpacesToThousand } from "../../../../helpers/formaters";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import {
  ChargebeeLogo,
  LogoLetterDotIcon,
} from "../../../../ui/icons/CustomIcons";
import { IconTypeE } from "../../../../ui/icons/Icon";
import { SubscriptionProposalT } from "../../types";
import SubscriptionPlanEntitlements from "../subscription-plans/SubscriptionPlanEntitlements";
import { validateEmptyString } from "./helpers";
import { PlanPriceStateT } from "./WizardContainer";

const Wrapper = styled(FlexBox)({
  alignItems: "flex-start",
  width: "100%",

  [theme.media.maxMd]: {
    flexDirection: "column",
  },
});

const ContentWrapper = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "flex-start",
  width: "50%",
  padding: rem(32),

  [theme.media.maxMd]: {
    width: "100%",
  },
});

const LogoWrapper = styled(FlexBox)({
  width: rem(32),
  height: rem(32),
  backgroundColor: hexToRgb(designToken.colorPrimaryBg, 0.24),
  borderRadius: rem(16),

  svg: {
    height: rem(16),
  },
});

const StyledSegmented = styled(Segmented)({
  ".ant-segmented-item-label": {
    height: "100%",
  },
});

const StyledDivider = styled(Divider)({
  margin: 0,
});

const SegmentedLabelWrapper = styled(FlexBox)({
  padding: rem(8),
  flexDirection: "column",
  alignItems: "flex-start",
  height: "100%",
  cursor: "pointer",
});

const StyledTag = styled(Tag)({
  marginLeft: rem(6),
});

const EntitlementsWrapper = styled(FlexBox)<{
  open: boolean;
  marginLeft?: number;
}>(({ open, marginLeft }) => ({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: open ? rem(224) : 0,
  transition: "height 0.5s",
  overflow: "hidden",
  marginLeft: marginLeft && rem(marginLeft),
}));

const ImageWrapper = styled(ContentWrapper)({
  justifyContent: "space-between",
  alignSelf: "stretch",
  backgroundColor: hexToRgb(designToken.colorPrimaryBg, 0.24),
  borderRadius: `0 ${rem(designToken.borderRadiusLG)} ${rem(
    designToken.borderRadiusLG,
  )} 0`,
});

const TermsWrapper = styled.div({
  a: {
    color: theme.color.primaryColor,

    ":hover": {
      textDecoration: "underline",
    },
  },
});

const PoweredByWrapper = styled(FlexBox)({
  marginTop: rem(36),
});

type FieldType = {
  company: string;
  address: string;
  city: string;
  zip: string;
  country: string;
  vat: string;
  tac: boolean;
  invoice_emails?: string[];
};

type Option = {
  label: string;
  value: string;
};

type CheckoutFormPlanT = {
  planPriceState: PlanPriceStateT;
  setPlanPriceState: (planPriceState: PlanPriceStateT | undefined) => void;
  payment_type: "card" | "invoice";
  subscriptionProposal?: never;
};

type CheckoutFormProposalT = {
  subscriptionProposal: SubscriptionProposalT;
  payment_type: "card" | "invoice";
  planPriceState?: never;
  setPlanPriceState?: never;
};

type CheckoutFormT = CheckoutFormPlanT | CheckoutFormProposalT;

const CheckoutForm: FC<CheckoutFormT> = ({
  planPriceState,
  setPlanPriceState,
  subscriptionProposal,
  payment_type,
}) => {
  const [planDetail, setPlanDetail] = useState(false);

  const plan = planPriceState?.plan;
  const planPrice = planPriceState?.price;

  const planName = planPriceState
    ? planPriceState.plan.external_name
    : subscriptionProposal.plan;
  const entitlements = planPriceState
    ? planPriceState.plan.entitlements
    : subscriptionProposal.entitlements;
  const amount = planPriceState
    ? planPriceState.price.price.amount
    : subscriptionProposal.price_without_vat.amount;
  const currency = planPriceState
    ? planPriceState.price.price.currency
    : subscriptionProposal.price_without_vat.currency;
  const period = planPriceState
    ? planPriceState.price.period_unit
    : subscriptionProposal.billing_period_unit;
  const vatPercentage = 0.21;
  const vatAmount = amount * vatPercentage;
  const amountWithVat = amount + vatAmount;

  const countriesOptions: Option[] = countries.map((country) => {
    return { value: country.name, label: country.name };
  });

  const { t } = useTranslate("brands.wizard.checkout");
  const formattedAmountWithVat = `${putSpacesToThousand(
    amountWithVat.toFixed(2),
  )} ${t(`currencies.${currency.toLowerCase()}`)}`;

  const onSegmentedChangeHandle = (val: string): void => {
    if (plan) {
      const newPrice = plan.prices.find(
        (price) => price.period_unit === val && price.id.match(currency),
      );
      newPrice &&
        setPlanPriceState &&
        setPlanPriceState({ plan, price: newPrice });
    }
  };

  const onChangePlanHandle = (): void => {
    setPlanPriceState && setPlanPriceState(undefined);
  };

  const validateZip = (value: string, country: string): Promise<any> => {
    const selectedCountry = countries.find((c) => c.name === country);

    if (
      value &&
      selectedCountry &&
      !postcodeValidator(value, selectedCountry.codes[0])
    ) {
      return Promise.reject(t("form.errors.zip"));
    }

    return Promise.resolve();
  };

  const validateVatId = (value: string, country: string): Promise<any> => {
    const errMessage = t("form.errors.vat");
    const selected = countries.find((c) => c.name === country);

    if (value && selected) {
      if (selected && checkVAT(value, [selected]).isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(errMessage);
      }
    }

    return Promise.resolve();
  };

  const segmentedOptions: SegmentedLabeledOption[] | undefined =
    plan && planPrice
      ? plan.prices
          .filter((price) => price.id.match(currency))
          .map((price) => ({
            label: (
              <SegmentedLabelWrapper>
                <Radio
                  checked={planPrice.period_unit === price.period_unit}
                  onChange={(e) => onSegmentedChangeHandle(e.target.value)}
                  value={price.period_unit}
                >
                  {t(`billing.${price.period_unit}`)}
                  {price.period_unit === "year" && (
                    <StyledTag color="green">{t("save20")}</StyledTag>
                  )}
                </Radio>
              </SegmentedLabelWrapper>
            ),
            value: price.period_unit,
          }))
      : undefined;

  return (
    <Wrapper>
      <ContentWrapper>
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          customWidth="100%"
          gap={rem(16)}
        >
          <Paragraph paragraph={t("title")} fontWeight={600} />
          <FlexBox customWidth="100%" justifyContent="space-between">
            <FlexBox gap={rem(8)}>
              <LogoWrapper>
                <LogoLetterDotIcon />
              </LogoWrapper>
              <Paragraph
                paragraph={t("plan", {
                  title: t(`form.plans.${planName.toLowerCase()}`),
                })}
                paragraphSize="small"
                fontWeight={600}
              />
            </FlexBox>
            {planPriceState && (
              <Button type="link" onClick={onChangePlanHandle}>
                {t("change_plan")}
              </Button>
            )}
          </FlexBox>
          {planPriceState ? (
            <>
              {segmentedOptions && planPrice && (
                <StyledSegmented
                  block
                  value={planPrice.period_unit}
                  onChange={(val) => onSegmentedChangeHandle(val as string)}
                  style={{ width: "100%" }}
                  options={segmentedOptions}
                />
              )}
              <BlueLink
                text={t("plan_details")}
                onClick={() => setPlanDetail((prev) => !prev)}
                paragraphSize="small"
                type="grey"
                icon={planDetail ? IconTypeE.arrowBottom : IconTypeE.arrowRight}
                iconPosition="after"
                iconColor="grey"
              />
              <EntitlementsWrapper open={planDetail}>
                <SubscriptionPlanEntitlements entitlements={entitlements} />
              </EntitlementsWrapper>
            </>
          ) : (
            <EntitlementsWrapper open={true} marginLeft={48}>
              <SubscriptionPlanEntitlements entitlements={entitlements} />
            </EntitlementsWrapper>
          )}
        </FlexBox>
        <Divider />
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          customWidth="100%"
          gap={rem(24)}
        >
          <Paragraph paragraph={t("billing_details")} fontWeight={600} />
          <FlexBox
            flexDirection="column"
            customWidth="100%"
            alignItems="stretch"
          >
            <Form.Item<FieldType>
              label={t("form.company_name")}
              name="company"
              rules={[
                {
                  required: true,
                  message: t("form.errors.base"),
                },
                () => ({
                  validator: (_rule, value) =>
                    validateEmptyString(value, t("form.errors.format")),
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item<FieldType>
              label={t("form.address")}
              labelCol={{ span: 24 }}
              name="address"
              rules={[
                { required: true, message: t("form.errors.base") },
                () => ({
                  validator: (_rule, value) =>
                    validateEmptyString(value, t("form.errors.format")),
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <FlexBox customWidth="100%" gap={rem(16)}>
              <Form.Item<FieldType>
                style={{ width: "70%" }}
                label={t("form.city")}
                name="city"
                rules={[
                  { required: true, message: t("form.errors.base") },
                  () => ({
                    validator: (_rule, value) =>
                      validateEmptyString(value, t("form.errors.format")),
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item<FieldType>
                style={{ width: "30%" }}
                labelCol={{ span: 24 }}
                label={t("form.zip")}
                name="zip"
                rules={[
                  {
                    required: true,
                    message: t("form.errors.base"),
                  },
                  () => ({
                    validator: (_rule, value) =>
                      validateEmptyString(value, t("form.errors.format")),
                  }),
                  ({ getFieldValue }) => ({
                    validator: (_rule, value) =>
                      validateZip(value, getFieldValue("country")),
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </FlexBox>
            <Form.Item<FieldType>
              label={t("form.country")}
              name="country"
              rules={[
                {
                  required: true,
                  message: t("form.errors.base"),
                },
                () => ({
                  validator: (_rule, value) =>
                    validateEmptyString(value, t("form.errors.format")),
                }),
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                options={countriesOptions}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label={t("form.vat")}
              name="vat"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_rule, value) =>
                    validateVatId(value, getFieldValue("country")),
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </FlexBox>
        </FlexBox>
        {/* <Divider />
        <FlexBox customWidth="100%" gap={rem(16)} justifyContent="flex-start">
          <Form.Item<FieldType>
            label={t("form.invoice_emails")}
            name="invoice_emails"
            labelCol={{ span: 24 }}
            tooltip={t("form.invoice_emails_tooltip")}
            style={{ width: "100%" }}
          >
            <Select mode="tags" />
          </Form.Item>
        </FlexBox> */}
      </ContentWrapper>
      <ImageWrapper>
        <FlexBox
          flexDirection="column"
          customWidth="100%"
          alignItems="flex-start"
          gap={rem(24)}
        >
          <Paragraph paragraph={t("summary")} fontWeight={600} />
          <FlexBox
            flexDirection="column"
            alignItems="flex-start"
            customWidth="100%"
          >
            <FlexBox customWidth="100%" justifyContent="space-between">
              <Paragraph
                paragraph={`${t("plan", {
                  title: t(`form.plans.${planName.toLowerCase()}`),
                })} - ${t(`billing.${period}`)}`}
                paragraphSize="small"
              />
              <Paragraph
                paragraph={`${putSpacesToThousand(amount.toFixed(2))} ${t(
                  `currencies.${currency.toLowerCase()}`,
                )}`}
                paragraphSize="small"
                fontWeight={600}
              />
            </FlexBox>
            <FlexBox customWidth="100%" justifyContent="space-between">
              <Paragraph
                paragraph={`${t("form.vat_amount")} (${vatPercentage * 100}%)`}
                paragraphSize="small"
              />
              <Paragraph
                paragraph={`${putSpacesToThousand(vatAmount.toFixed(2))} ${t(
                  `currencies.${currency.toLowerCase()}`,
                )}`}
                paragraphSize="small"
                fontWeight={600}
              />
            </FlexBox>
          </FlexBox>
          <StyledDivider />
          <FlexBox customWidth="100%" justifyContent="space-between">
            <Paragraph paragraph={t("total")} fontWeight={600} />
            <Paragraph paragraph={formattedAmountWithVat} fontWeight={600} />
          </FlexBox>
        </FlexBox>
        <FlexBox
          flexDirection="column"
          customWidth="100%"
          alignItems="flex-start"
        >
          <Form.Item<FieldType>
            style={{ marginBottom: rem(16) }}
            name="tac"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: t("form.errors.base"),
              },
            ]}
          >
            <Checkbox>
              <TermsWrapper>
                <Paragraph paragraph={t("form.tac")} paragraphSize="small" />
              </TermsWrapper>
            </Checkbox>
          </Form.Item>
          <Form.Item
            style={{ width: "100%", marginBottom: rem(16) }}
            labelCol={{ span: 24 }}
          >
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              {t(`form.save_${payment_type}`, {
                price: formattedAmountWithVat,
              })}
            </Button>
          </Form.Item>
          {payment_type === "invoice" && (
            <Paragraph
              paragraph={t("form.submit_description")}
              color="grey"
              paragraphSize="small"
            />
          )}
          <PoweredByWrapper gap={rem(12)} customWidth="100%">
            <Paragraph
              paragraph="Powered by"
              paragraphSize="small"
              color="grey"
            />
            <ChargebeeLogo />
          </PoweredByWrapper>
        </FlexBox>
      </ImageWrapper>
    </Wrapper>
  );
};

export default CheckoutForm;
