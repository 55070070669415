import styled from "@emotion/styled";
import debounce from "lodash/debounce";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { RemoveInputStyles } from "../../../../../components/GeneralStyles";
import { useMutateMedia } from "../../../../../hooks/useMutateMedia";
import { rem, theme } from "../../../../../styling/theme";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { FieldTypeT } from "../../../types";
import InsightItemWrapper from "../../../ui/InsightItemWrapper";

const StyledInput = styled.input({
  ...RemoveInputStyles,
  padding: 0,
  margin: 0,
  fontSize: rem(15),
  fontWeight: 600,
  color: theme.color.textColor,
  zIndex: 1,
});

type InputColumnT = {
  id: string;
  inputKey: FieldTypeT;
  label: string;
  value: number | null;
  permanentDisabled?: boolean;
  liveAddedMedia?: boolean;
};

const InputColumn: React.FC<InputColumnT> = ({
  id,
  inputKey,
  label,
  value,
  permanentDisabled = false,
  liveAddedMedia,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [focus, setFocus] = useState(false);
  const [valueState, setValueState] = useState<number | null>(value || null);

  const { clientId, queryStack, addedQueryStack } = useContext(
    MediaGroupsHelperContext,
  );

  const { isDisabled, mutateMedia } = useMutateMedia(
    id,
    clientId,
    liveAddedMedia ? addedQueryStack : queryStack,
  );

  const input = useRef<HTMLInputElement>(null);

  const forceFocus = (): void => {
    if (input.current) {
      input.current.focus();
    }
  };

  type onChangeHandleT = (value: string) => void;
  const onChangeHandle: onChangeHandleT = (value) => {
    const regExp = /^[0-9]*$/;
    const filteredValue = value.replace(/-/g, "");

    const typedValue = filteredValue === "" ? "" : parseInt(filteredValue);
    const initLimit = 2147483647;
    const initLimitCondition =
      typeof typedValue === "number" && typedValue > initLimit;

    if (regExp.exec(filteredValue) && !isDisabled) {
      setValueState(() => {
        if (initLimitCondition) {
          return initLimit;
        }

        return typedValue === "" ? null : typedValue;
      });
    }
  };

  const mutateMediaHandle = useRef(
    debounce((value) => mutateMedia(value), 500),
  ).current;

  useEffect(() => {
    return () => {
      mutateMediaHandle.cancel();
    };
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    mutateMediaHandle({ [inputKey]: valueState });
  }, [valueState]);

  useEffect(() => {
    if (!firstLoad) {
      setValueState(value);
    }
  }, [value]);

  return (
    <InsightItemWrapper
      onClick={forceFocus}
      label={label}
      disabled={permanentDisabled}
    >
      <StyledInput
        ref={input}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={valueState ? valueState : !focus ? "-" : ""}
        disabled={permanentDisabled}
        onChange={(e) => onChangeHandle(e.target.value)}
      />
    </InsightItemWrapper>
  );
};

export default InputColumn;
