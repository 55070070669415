import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Button, { ButtonTypeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Spacer from "../../../components/Spacer";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import Input from "../../../features/Input";
import { getStringDate } from "../../../helpers/date";
import { QueryKeys } from "../../../helpers/queryKeys";
import { useTranslate } from "../../../hooks/useTranslate";
import InputWrapper from "../../../ui/InputWrapper";
import { CampaignModalContext } from "../context-providers/CampaignModalContextProvider";
import { createCampaign } from "../data-access/createCampaign";
import { getPartnerships } from "../data-access/getPartnerships";
import { updateCampaignsMutation } from "../data-access/mutation/updateCampaignsMutation";
import { updateCampaign } from "../data-access/updateCampaign";
import { ModalCampaignT } from "../types";
import FilterDate, { DateStateT } from "./outputs/FilterDate";
import FilterInfluencers from "./outputs/FilterInfluencers";
import HashtagsTags from "./outputs/HashtagsTags";

type CampaignModalT = {
  visible: boolean;
  clientId: string;
  close: () => void;
  setCampaignDataRefetch: Dispatch<SetStateAction<boolean>>;
};

const CampaignModal: FC<CampaignModalT> = ({
  clientId,
  close,
  visible,
  setCampaignDataRefetch,
}) => {
  const [title, setTitle] = useState<string>("");
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [partnershipIds, setPartnershipIds] = useState<string[]>([]);
  const [dateState, setDateState] = useState<DateStateT>();

  const { campaignModal } = useContext(CampaignModalContext);
  const campaignValues =
    campaignModal && typeof campaignModal === "object" ? campaignModal : false;

  const { session } = useContext(ApplicationContext);
  const client = session?.clients.find((client) => client.id === clientId);
  const client_mentions =
    client?.instagram_mentions.concat(client?.tiktok_mentions) || [];

  const campaignId = campaignValues && campaignValues.id;
  const type = campaignId ? "edit" : "create";

  const { t } = useTranslate("brands.reportings");

  const {
    data: partnerships,
    isLoading: partnershipsAreLoading,
    refetch,
  } = useQuery(QueryKeys.PARTNERSHIPS, () => getPartnerships(clientId));

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    () => {
      const campaignPayload = {
        campaign: {
          title,
          ...(dateState?.endDate && { to: getStringDate(dateState.endDate) }),
          ...(dateState?.startDate && {
            from: getStringDate(dateState.startDate),
          }),
          hashtags,
          partnership_ids: partnershipIds,
        },
      };

      if (campaignId) {
        return updateCampaign(campaignId, campaignPayload, clientId);
      }

      return createCampaign(campaignPayload, clientId);
    },
    {
      onSuccess: (data) => {
        setCampaignDataRefetch((prevState) => !prevState);
        updateCampaignsMutation(queryClient, data, close);
      },
    },
  );

  const clearModalState = (): void => {
    setTitle("");
    setHashtags([]);
    setPartnershipIds([]);
    setDateState(undefined);
  };

  const setAvailablePartnerships = (ids: string[]): void => {
    if (partnerships) {
      const availableIds = partnerships.map((partnership) => partnership.id);
      const intersectedIds = ids.filter((id) => availableIds.includes(id));
      setPartnershipIds(intersectedIds);
    }
  };

  const updateInitialState = (values: ModalCampaignT): void => {
    if (values.title) {
      setTitle(values.title);
    }

    if (values.hashtags) {
      setHashtags(values.hashtags);
    }

    if (values.partnership_ids) {
      setAvailablePartnerships(values.partnership_ids);
    }

    if (values.from && values.to) {
      setDateState({
        startDate: new Date(values.from),
        endDate: new Date(values.to),
      });
    }
  };

  useEffect(() => {
    if (campaignValues) {
      updateInitialState(campaignValues);
    }
  }, [campaignValues]);

  useEffect(() => {
    if (!visible && !campaignModal) {
      clearModalState();
    }
  }, [visible]);

  useEffect(() => {
    refetch();
  }, [clientId]);

  return (
    <FlexBox
      flexDirection="column"
      alignItems="stretch"
      customWidth={CustomWidthE.full}
    >
      <Heading
        heading={t(`campaigns.${type}.title`)}
        headingType={HeadingTypeT.h2}
      />
      <FlexBox customWidth={CustomWidthE.full} alignItems="stretch">
        <FlexBox
          customWidth={CustomWidthE.half}
          flexDirection="column"
          justifyContent="flex-start"
        >
          <Input
            value={title}
            setValue={setTitle}
            label={t(`campaigns.${type}.name`)}
          />
          <InputWrapper label={t("outputs.filter.date")}>
            <FilterDate dateState={dateState} setDateState={setDateState} />
          </InputWrapper>
          <HashtagsTags
            hashtags={hashtags}
            setHashtags={setHashtags}
            clientId={clientId}
            label={t("outputs.filter.hashtags.title")}
            placeholder={t("outputs.filter.hashtags.placeholder")}
            isStripped={false}
          />
          <InputWrapper label={t("outputs.filter.mentions.title")}>
            @{client_mentions.join(", @")}
          </InputWrapper>
        </FlexBox>
        <Spacer />
        <FlexBox
          customWidth={CustomWidthE.half}
          flexDirection="column"
          justifyContent="flex-start"
        >
          <InputWrapper
            label={t("outputs.filter.influencers")}
            fullHeight={true}
          >
            <FilterInfluencers
              partnerships={partnerships}
              isLoading={partnershipsAreLoading}
              setPartnerships={setPartnershipIds}
            />
          </InputWrapper>
        </FlexBox>
      </FlexBox>
      <Spacer size="big" direction="vertical" />
      <FlexBox flexDirection="row">
        <Button
          onClick={close}
          loading={isLoading}
          customWidth={CustomWidthE.full}
          type={ButtonTypeE.grey}
          disabled={title.length === 0 || isLoading}
        >
          {t(`campaigns.close`)}
        </Button>
        <Spacer />
        <Button
          disabled={title.length === 0 || isLoading}
          onClick={mutate}
          loading={isLoading}
          customWidth={CustomWidthE.full}
        >
          {t(`campaigns.${type}.submit`)}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default CampaignModal;
