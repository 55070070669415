import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

type removeCampaignPropsT = {
  campaignId: string;
  clientId: string;
};

export const removeCampaign = async ({
  clientId,
  campaignId,
}: removeCampaignPropsT): Promise<{}> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .delete(getApiUrl(ApiRoutesE.CAMPAIGN, campaignId), config)
    .then((res) => res.data);
};
