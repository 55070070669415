import { ManOutlined, RightOutlined, WomanOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Checkbox, theme as antTheme, Tooltip } from "antd";
import * as React from "react";
import { Dispatch, FC, MouseEvent, SetStateAction } from "react";
import { useQuery } from "react-query";
import AvatarTile, { AvatarSizeE } from "../../../../../components/AvatarTile";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { numberToText } from "../../../../../helpers/formaters";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../../styling/theme";
import Icon from "../../../../../ui/icons/Icon";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableItemWrapper from "../../../../../ui/table/TableItemWrapper";
import { NetworkProfileT } from "../../../../types";
import { getNetworkProfileContentOutputs } from "../../../data-access/getNetworkProfileContentOutputs";
import { NavigationKeyE } from "../../../router";
import { ExistedOutputT } from "../../../types";
import { credibilityColor, credibilityIcon } from "../credibilityHelpers";
import { SearchQueryT } from "../types";

const { getDesignToken } = antTheme;

const designToken = getDesignToken();

const Wrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  backgroundColor: theme.color.whiteColor,
  margin: `${rem(6)} 0`,
  borderRadius: rem(8),

  ":hover": {
    backgroundColor: hexToRgb(theme.color.backgroundColor, 0.4),
  },

  [theme.media.sm]: {
    flexDirection: "row",
    height: rem(88),
    margin: 0,
    borderRadius: 0,
  },
});

const Section = styled(FlexBox)<{ width: string }>(({ width }) => ({
  width: "100%",

  div: {
    cursor: "pointer",
  },

  [theme.media.sm]: {
    width: width,
  },
}));

const OutputsSection = styled(Section)<{ width: string }>(({ width }) => ({
  overflowX: "auto",
  justifyContent: "flex-start",
}));

const NameColumn = styled(TableColumn)({
  WebkitMaskImage: "linear-gradient(90deg, #000 85%, transparent)",
  paddingLeft: 0,
});

const ContentButton = styled(FlexBox)({
  width: "100%",
  justifyContent: "flex-start",
  WebkitMaskImage: "linear-gradient(90deg, #000 85%, transparent)",
  overflowX: "hidden",
});

const CredibilityWrapper = styled(FlexBox)<{ color: string }>(({ color }) => ({
  "p:hover": {
    color: color,
  },
}));

const ThumbnailsColumn = styled(TableColumn)({
  gap: rem(0),

  [`@media screen and (min-width: ${1200}px)`]: {
    gap: rem(8),
  },

  [theme.media.maxSm]: {
    justifyContent: "space-around",
  },
});

const StyledParagraph = styled(Paragraph)({
  wordBreak: "normal",
});

const Thumbnail = styled.img({
  width: rem(64),
  height: rem(64),
  borderRadius: rem(4),
  overflow: "hidden",
  objectFit: "cover",
});

export type PartnershipToRemoveT = {
  id: string;
  removed: boolean;
};

type ProfileRowT = {
  networkProfile: NetworkProfileT;
  networkProfileIds: string[];
  setNetworkProfileIds: Dispatch<SetStateAction<string[]>>;
  listMode: boolean | undefined;
  setOutputs: Dispatch<SetStateAction<ExistedOutputT[] | null>>;
  searchQuery: SearchQueryT;
};

const ProfileRow: FC<ProfileRowT> = ({
  networkProfile,
  networkProfileIds,
  setNetworkProfileIds,
  listMode,
  setOutputs,
  searchQuery,
}) => {
  const {
    id,
    profile_id,
    content_search,
    credibility,
    main_age_group,
    main_country,
    main_gender,
  } = networkProfile;
  const { t } = useTranslate("brands.influencer_database");

  const { isLoading, refetch } = useQuery(
    `${QueryKeys.NETWORK_PROFILE_CONTENT_OUTPUTS}-${profile_id}`,
    () => getNetworkProfileContentOutputs(profile_id, searchQuery),
    {
      enabled: false,
      onSuccess: (data) => {
        setOutputs(data);
      },
    },
  );

  const onCheckboxChange = (value: boolean): void => {
    if (value) {
      setNetworkProfileIds((prev) => [...prev, id]);
    } else {
      setNetworkProfileIds((prev) => prev.filter((i) => i !== id));
    }

    trackEvent(MixpanelEventT.databaseListCheckboxInfluencerClick, {
      handle: profile_id,
      checked: value,
    });
  };

  const externalProviderLink = (): string => {
    const sites = {
      instagram: "https://www.instagram.com/",
      tiktok: "https://www.tiktok.com/@",
      youtube: "https://www.youtube.com/@",
    };

    return `${sites[searchQuery.provider_eq]}${networkProfile.profile_id}`;
  };

  const onRowClickHandle = (): void => {
    window.open(
      `${window.location.origin}${NavigationKeyE.Database}/${id}/overview`,
      "_blank",
    );
  };

  const onShowOutputsHandle = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
  ): void => {
    e.stopPropagation();
    refetch();
  };

  return (
    <TableItemWrapper
      key={id}
      customHeight="auto"
      last={true}
      renderChildren={() => (
        <Wrapper>
          <Section width="30%">
            <TableColumn
              columnWidth={listMode ? "0%" : "17%"}
              justifyContent="center"
            >
              {!listMode && (
                <Checkbox
                  disabled={listMode}
                  checked={networkProfileIds.includes(id)}
                  onChange={(e) => onCheckboxChange(e.target.checked)}
                />
              )}
            </TableColumn>
            <NameColumn
              columnWidth={listMode ? "100%" : "83%"}
              justifyContent="flex-start"
              onClick={onRowClickHandle}
            >
              <AvatarTile
                handle={networkProfile.profile_id}
                name={networkProfile.display_name}
                imageUrl={networkProfile.avatar_url}
                externalLink={externalProviderLink()}
                avatarSize={AvatarSizeE.big}
                network={listMode ? networkProfile.provider : undefined}
              />
            </NameColumn>
          </Section>
          <Section width="50%" onClick={onRowClickHandle}>
            <TableColumn
              columnWidth="20%"
              justifyContent="center"
              flexDirection="column"
              gap={rem(4)}
            >
              <Paragraph
                paragraph={numberToText(networkProfile.people_count)}
                fontWeight={600}
              />
              <StyledParagraph
                paragraph={t("table.labels.followers")}
                color={designToken.colorTextQuaternary}
              />
            </TableColumn>
            <TableColumn
              columnWidth="20%"
              justifyContent="center"
              flexDirection="column"
              gap={rem(4)}
            >
              {main_age_group && (
                <>
                  <Paragraph
                    align="center"
                    fontWeight={600}
                    paragraph={t(
                      `table.main_age_groups.${main_age_group.value}`,
                    )}
                  />
                  <Paragraph
                    paragraph={t("table.labels.main_age_group")}
                    color={designToken.colorTextQuaternary}
                  />
                </>
              )}
            </TableColumn>
            <TableColumn
              columnWidth="20%"
              justifyContent="center"
              flexDirection="column"
              padding={`${rem(10)} ${rem(8)}`}
              gap={rem(4)}
            >
              {main_gender && (
                <>
                  <FlexBox gap={rem(4)}>
                    {main_gender.value === "female" ? (
                      <WomanOutlined
                        style={{
                          color: designToken["magenta-6"],
                          fontSize: rem(14),
                        }}
                      />
                    ) : (
                      <ManOutlined
                        style={{
                          color: designToken["geekblue-6"],
                          fontSize: rem(14),
                        }}
                      />
                    )}
                    <Paragraph
                      align="center"
                      fontWeight={600}
                      paragraph={`${main_gender.percentage} %`}
                    />
                  </FlexBox>
                  <Paragraph
                    align="center"
                    color={designToken.colorTextQuaternary}
                    paragraph={t(
                      `table.labels.main_gender.${main_gender.value}`,
                    )}
                  />
                </>
              )}
            </TableColumn>
            <TableColumn
              columnWidth="20%"
              justifyContent="center"
              flexDirection="column"
              padding={`${rem(10)} ${rem(8)}`}
              gap={rem(4)}
            >
              {main_country && (
                <>
                  <StyledParagraph
                    align="center"
                    fontWeight={600}
                    paragraph={`${main_country.value.toUpperCase()} ${main_country.percentage.toFixed(
                      0,
                    )} %`}
                  />
                  <Paragraph
                    align="center"
                    color={designToken.colorTextQuaternary}
                    paragraph={t("table.labels.main_country")}
                  />
                </>
              )}
            </TableColumn>
            <TableColumn
              color={credibilityColor(credibility)}
              columnWidth="20%"
              justifyContent="center"
              padding={`${rem(10)} ${rem(8)}`}
            >
              <>
                {credibility ? (
                  <Tooltip title={t("filter.credibility.tooltip")}>
                    <FlexBox gap={rem(4)} flexDirection="column">
                      <CredibilityWrapper
                        color={credibilityColor(credibility)}
                        gap={rem(4)}
                      >
                        <Icon
                          icon={credibilityIcon(credibility)}
                          size={14}
                          color={credibilityColor(credibility)}
                        />
                        <Paragraph
                          align="center"
                          fontWeight={600}
                          paragraph={`${credibility.toFixed(0)} %`}
                        />
                      </CredibilityWrapper>
                      <StyledParagraph
                        align="center"
                        color={designToken.colorTextQuaternary}
                        paragraph={t("table.labels.credibility")}
                      />
                    </FlexBox>
                  </Tooltip>
                ) : (
                  <Paragraph paragraph="-" color={theme.color.textGreyColor} />
                )}
              </>
            </TableColumn>
          </Section>
          <OutputsSection width={"20%"} onClick={onRowClickHandle}>
            {content_search ? (
              <ContentButton>
                <Button
                  type="link"
                  loading={isLoading}
                  size="small"
                  onClick={(e) => onShowOutputsHandle(e)}
                >
                  {t("table.show_outputs", { conditions: content_search })}
                  <RightOutlined rev="" />
                </Button>
              </ContentButton>
            ) : (
              <ThumbnailsColumn
                columnWidth="100%"
                justifyContent="center"
                padding={`${rem(10)} ${rem(4)}`}
              >
                {networkProfile.top_post_urls?.map((url, index) => (
                  <Thumbnail src={url} key={index} alt="top post" />
                ))}
              </ThumbnailsColumn>
            )}
          </OutputsSection>
        </Wrapper>
      )}
    />
  );
};

export default ProfileRow;
