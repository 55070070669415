export const validateEmptyString = (
  value: string | undefined,
  error: string,
): Promise<any> => {
  if (
    value === undefined ||
    value.length === 0 ||
    value.replace(/\s/g, "").length > 0
  ) {
    return Promise.resolve();
  }

  return Promise.reject(error);
};
