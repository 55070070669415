import styled from "@emotion/styled";
import { Segmented } from "antd";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import LoaderComponent from "../../../../components/Loader";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { designToken } from "../../../../helpers/antDesign";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { getSubscriptionPlans } from "../../data-access/getSubscriptionPlans";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { SubscriptionPlanT } from "../../types";
import { PlanPriceStateT } from "../wizard/WizardContainer";
import SubscriptionPlan from "./SubscriptionPlan";

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  backgroundColor: theme.color.whiteColor,
  borderRadius: designToken.borderRadiusLG,
});

const PlansWrapper = styled(FlexBox)({
  alignItems: "flex-end",
  gap: rem(20),

  [theme.media.maxLg]: {
    flexDirection: "column",
  },
});

const BottomArrow = styled(Icon)({
  margin: `${rem(-15)} 0 0 ${rem(110)}`,
});

type PeriodT = "month" | "year";

type SubscriptionPlansT = {
  planPriceState: PlanPriceStateT | undefined;
  setPlanPriceState: (planPriceState: PlanPriceStateT | undefined) => void;
};

const SubscriptionPlans: FC<SubscriptionPlansT> = ({
  planPriceState,
  setPlanPriceState,
}) => {
  const navigate = useRouterNavigate();
  const { session } = useContext(ApplicationContext);
  const [period, setPeriod] = useState<PeriodT>("year");

  const { t } = useTranslate("brands.subscription_plans");

  const name = session?.nick_or_name?.split(" ")[0] || "";

  const { data: plans, isLoading } = useQuery(
    QueryKeys.SUBSCRIPTIONS_PLANS,
    () => getSubscriptionPlans(),
  );

  const getPlan = (planId: string): SubscriptionPlanT | undefined => {
    if (plans && plans.length) {
      const available = plans.filter((plan) => plan.enabled_for_checkout);
      return available.find((plan) => plan.id === planId);
    }
  };

  const litePlan = getPlan("lite");
  const proPlan = getPlan("professional");
  const enterPlan = getPlan("enterprise");

  const planForPrice = (priceId: string): SubscriptionPlanT | undefined => {
    if (plans && plans.length) {
      return plans.find((plan) =>
        plan.prices.find((price) => price.id === priceId),
      );
    }
  };

  const chosePlanHandle = (priceId: string): void => {
    const selectedPlan = planForPrice(priceId);
    const selectedPrice = selectedPlan?.prices.find(
      (price) => price.id === priceId,
    );
    if (selectedPrice && selectedPlan) {
      setPlanPriceState({ plan: selectedPlan, price: selectedPrice });
    }
  };

  useEffect(() => {
    planPriceState && navigate(NavigationKeyE.Wizard, "checkout");
  }, [planPriceState]);

  const getPlans = (): JSX.Element => {
    if (isLoading) {
      return <LoaderComponent />;
    }

    return (
      <PlansWrapper alignItems="flex-start">
        {litePlan && (
          <SubscriptionPlan
            plan={litePlan}
            chosePlanHandle={chosePlanHandle}
            period={period}
          />
        )}
        {proPlan && (
          <SubscriptionPlan
            plan={proPlan}
            chosePlanHandle={chosePlanHandle}
            reccomended={true}
            period={period}
          />
        )}
        {enterPlan && (
          <SubscriptionPlan
            plan={enterPlan}
            chosePlanHandle={chosePlanHandle}
            period={period}
          />
        )}
      </PlansWrapper>
    );
  };

  return (
    <>
      <Wrapper
        customWidth={CustomWidthE.full}
        alignItems="center"
        flexDirection="column"
      >
        <FlexBox gap={rem(12)} flexDirection="column">
          <Paragraph paragraph={t("title", { name: name })} />
          <Paragraph
            paragraph={t("subtitle")}
            paragraphSize="large"
            fontWeight={600}
          />
        </FlexBox>
        <Spacer size="big" direction="vertical" />
        <FlexBox gap={rem(36)}>
          <Segmented
            options={[
              { label: t("periods.monthly"), value: "month" },
              { label: t("periods.yearly"), value: "year" },
            ]}
            value={period}
            onChange={(value) => {
              setPeriod(value as PeriodT);
            }}
          />
          <Paragraph
            color={designToken["green-6"]}
            fontWeight={600}
            paragraph={t("save_up_to")}
          />
        </FlexBox>
        <BottomArrow
          icon={IconTypeE.bottomArrow}
          size="monster"
          color={designToken["green-6"]}
        />
        {getPlans()}
      </Wrapper>
    </>
  );
};

export default SubscriptionPlans;
