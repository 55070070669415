import { DatePicker } from "antd";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

const MyDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = MyDatePicker;

export type DateStateT = {
  startDate: Date;
  endDate: Date;
};

type FilterDateT = {
  dateState: DateStateT | undefined;
  setDateState: Dispatch<SetStateAction<DateStateT | undefined>>;
};

const FilterDate: FC<FilterDateT> = ({ dateState, setDateState }) => {
  const [startDate, setStartDate] = useState<Date | null>(
    dateState?.startDate || null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    dateState?.endDate || null,
  );

  useEffect(() => {
    if (startDate && endDate) {
      setDateState({ startDate, endDate });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (dateState) {
      setStartDate(dateState.startDate || null);
      setEndDate(dateState.endDate || null);
    }
  }, [dateState]);

  return (
    <RangePicker
      style={{ width: "100%" }}
      onChange={(range) => {
        setStartDate(range ? range[0] : null);
        setEndDate(range ? range[1] : null);
      }}
      value={[startDate, endDate]}
    />
  );
};

export default FilterDate;
