import * as React from "react";
import { createContext, FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  SessionT,
  SubscriptionCurrentUsageT,
  SubscriptionEntitlementFeaturesT,
  SubscriptionT,
} from "../../types";
import { generatePortalSession } from "../data-access/generatePortalSession";
import { createSubscriptionMutation } from "../data-access/mutation/createSubscriptionMutation";
import { syncSubscription } from "../data-access/syncSubscription";

type SectionsT =
  | "edit_subscription"
  | "portal_billing_history"
  | "portal_edit_billing_address"
  | "portal_payment_methods";
type openPortalT = (section?: SectionsT) => void;
type SubscriptionModalContextT = {
  chargebee: any;
  openPortal: openPortalT;
  getFeatureUsage: (
    session: SessionT | undefined,
    featureId: SubscriptionEntitlementFeaturesT,
  ) => SubscriptionCurrentUsageT | undefined;
  subscription: SubscriptionT | undefined;
  setSubscription: (subscription: SubscriptionT | undefined) => void;
};

export const SubscriptionContext = createContext<SubscriptionModalContextT>({
  chargebee: undefined,
  openPortal: () => {},
  getFeatureUsage: () => undefined,
  subscription: undefined,
  setSubscription: () => {},
});

export const SubscriptionContextProvider: FC = ({ children }) => {
  const [subscription, setSubscription] = useState<SubscriptionT | undefined>();
  const chargebee = window.Chargebee.getInstance();

  const queryClient = useQueryClient();
  const { mutate: sync } = useMutation(() => syncSubscription(), {
    onSuccess: (data) => {
      createSubscriptionMutation(queryClient, data);
    },
  });

  const openPortal: openPortalT = (section) => {
    const chargebeePortalInstance = chargebee.createChargebeePortal();
    const sectionOptions =
      section && subscription
        ? {
            sectionType: section,
            params: { subscriptionId: subscription.remote_id },
          }
        : undefined;
    chargebeePortalInstance.open(
      {
        subscriptionChanged: () => sync(),
        subscriptionCancelled: () => sync(),
      },
      sectionOptions,
    );
  };

  const getFeatureUsage = (
    session: SessionT | undefined,
    featureId: SubscriptionEntitlementFeaturesT,
  ): SubscriptionCurrentUsageT | undefined => {
    if (session && session.subscription) {
      return session.subscription.current_period_usage.usage.find(
        (usage) => usage.feature_id === featureId,
      );
    }

    return undefined;
  };

  useEffect(() => {
    if (subscription) {
      chargebee.setPortalSession(() => {
        return generatePortalSession(subscription.remote_id);
      });
    }
  }, [subscription]);

  return (
    <SubscriptionContext.Provider
      value={{
        chargebee,
        openPortal,
        getFeatureUsage,
        subscription,
        setSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
