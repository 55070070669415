import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import SortLink from "../../../../../../components/SortLink";
import { useLocalSort } from "../../../../../../hooks/useLocalSort";
import { rem } from "../../../../../../styling/theme";
import TableColumnWrapper from "../../../../../../ui/table/TableColumnWrapper";
import TableHeadWrapper from "../../../../../../ui/table/TableHeadWrapper";
import { MediaGroupT, MediaT } from "../../../../types";
import TableRow from "./TableRow";

const Wrapper = styled(FlexBox)({
  width: "100%",
  minWidth: "100%",
});

export type MediaWithGroupT = MediaT & {
  mediaGroup: MediaGroupT;
};

type MediaGroupTableT = {
  mediaGroups: MediaGroupT[];
};

const MediaGroupTable: FC<MediaGroupTableT> = ({ mediaGroups }) => {
  const medias = mediaGroups.flatMap((group) =>
    group.medias.map((media) => {
      const item: MediaWithGroupT = { ...media, mediaGroup: group };
      return item;
    }),
  );
  const { sortedCollection, setSortingOptions, sortingOptions } =
    useLocalSort<MediaWithGroupT>("impressions", medias);

  const getTableContent = (sortedMedias: MediaWithGroupT[]): JSX.Element => {
    return (
      <Wrapper flexDirection="column">
        <TableHeadWrapper justifyContent="stretch" padding={rem(8)}>
          <TableColumnWrapper columnWidth="33%" justifyContent="center">
            <SortLink
              attribute={"mediaGroup.partnership.handle"}
              sortingOptions={sortingOptions}
              setSortingOptions={setSortingOptions}
            />
          </TableColumnWrapper>
          <TableColumnWrapper columnWidth="33%" justifyContent="center">
            <SortLink
              attribute={"impressions"}
              sortingOptions={sortingOptions}
              setSortingOptions={setSortingOptions}
            />
          </TableColumnWrapper>
          <TableColumnWrapper columnWidth="33%" justifyContent="center">
            <SortLink
              attribute={"engagement_rate"}
              sortingOptions={sortingOptions}
              setSortingOptions={setSortingOptions}
            />
          </TableColumnWrapper>
        </TableHeadWrapper>
        {sortedMedias.map((item, index) => (
          <TableRow key={index} media={item} />
        ))}
      </Wrapper>
    );
  };

  return (
    <>
      {sortedCollection &&
        sortedCollection.length > 0 &&
        getTableContent(sortedCollection)}
    </>
  );
};

export default MediaGroupTable;
