import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { CampaignT } from "../../types";

export const updateCampaignsMutation = (
  queryClient: QueryClient,
  data: CampaignT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<CampaignT[]>(QueryKeys.CAMPAIGNS, (prevState) => {
    if (prevState) {
      const findIndex = prevState.findIndex(
        (campaign) => campaign.id === data.id,
      );

      // campaign was not found, so it was just created
      if (findIndex === -1) {
        return [...prevState, data];
      }

      prevState[findIndex] = data;
      return prevState;
    }

    return [data];
  });

  afterMutationHandle && afterMutationHandle();
};
