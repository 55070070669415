import { Input, Modal } from "antd";
import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Container from "../../../../components/Container";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import { createCompetitiveAnalysis } from "../../data-access/createCompetitiveAnalysis";
import { getCompetitiveAnalyses } from "../../data-access/getCompetitiveAnalyses";
import { createCompetitiveAnalysisMutation } from "../../data-access/mutation/createCompetitiveAnalysisMutation";
import LimitHeader from "../../ui/LimitHeader";
import Table from "./Table";

const CompetitiveAnalysesContainer: FC = () => {
  const [addNewModal, setAddNewModal] = useState<boolean>(false);
  const [handle, setTitle] = useState<string>("");

  const { t } = useTranslate("brands.competitive_analyses.index");

  const clearStates = (): void => {
    setAddNewModal(false);
    setTitle("");
  };

  const {
    data: competitiveAnalyses,
    isLoading,
    isRefetching,
  } = useQuery(QueryKeys.COMPETITIVE_ANALYSES, () => getCompetitiveAnalyses());

  const queryClient = useQueryClient();
  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    () =>
      createCompetitiveAnalysis({
        competitive_analysis: {
          handle: handle,
        },
      }),
    {
      onSuccess: (data) => {
        createCompetitiveAnalysisMutation(
          queryClient,
          data.competitive_analysis,
          clearStates,
        );
      },
    },
  );

  return (
    <Container>
      <LimitHeader
        title={t("title")}
        onClickHandle={setAddNewModal}
        submitTitle={t("create")}
        entitlementFeature="competitive-analyses"
      />
      <Table
        competitiveAnalyses={competitiveAnalyses}
        dataIsLoading={isLoading || isRefetching}
        onAddNewCampaignClickHandle={() => setAddNewModal(true)}
      />

      <Modal
        open={addNewModal}
        onCancel={() => setAddNewModal(false)}
        onOk={() => create()}
        title={t("modal.header")}
        okText={t("modal.create")}
        cancelText={t("modal.cancel")}
        okButtonProps={{
          disabled: handle.length < 3 || isCreateLoading,
        }}
      >
        <Paragraph
          paragraph={t("modal.description")}
          color={theme.color.textGreyColor}
        />
        <Spacer direction="vertical" />
        <Paragraph paragraph={t("modal.handle")} fontWeight={600} />
        <Spacer direction="vertical" />
        <Input
          value={handle}
          onChange={(e) => setTitle(e.target.value)}
          addonBefore="@"
        />
        <Spacer direction="vertical" />
      </Modal>
    </Container>
  );
};

export default CompetitiveAnalysesContainer;
