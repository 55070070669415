import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Spacer from "../../../components/Spacer";
import { useResponsive } from "../../../hooks/useResponsive";
import { breakpoints, rem, theme } from "../../../styling/theme";

const Wrapper = styled(FlexBox)({
  maxWidth: rem(700),

  [theme.media.maxSm]: {
    textAlign: "center",
  },
});

type ApprovalContentT = {
  heading: string;
  text: JSX.Element;
};

const ApprovalContent: FC<ApprovalContentT> = ({ heading, text, children }) => {
  const { breakePoint } = useResponsive(breakpoints.sm);

  return (
    <Wrapper
      position="relative"
      flexDirection="column"
      alignItems={breakePoint ? "center" : "flex-start"}
    >
      <Heading
        heading={heading}
        fontWeight={600}
        headingType={HeadingTypeT.h2}
        spaceBottom="micro"
      />
      <Spacer direction="vertical" />
      {text}
      <Spacer direction="vertical" />
      {children}
    </Wrapper>
  );
};

export default ApprovalContent;
