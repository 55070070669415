import {
  RiseOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Progress } from "antd";
import * as React from "react";
import { FC } from "react";
import BlueLink from "../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import {
  SubscriptionCurrentUsageT,
  SubscriptionEntitlementFeaturesT,
} from "../../../types";

type UsageItemT = {
  usage: SubscriptionCurrentUsageT;
  action?: { title: string; onClickhandle: () => void };
};

const UsageItem: FC<UsageItemT> = ({ usage, action }) => {
  const { feature_id, used, quota } = usage;

  const percentage = used / (quota / 100);

  const { t } = useTranslate("brands.manage_subscription");

  const icon = (featureId: SubscriptionEntitlementFeaturesT): JSX.Element => {
    switch (featureId) {
      case "users":
        return <UsergroupAddOutlined />;
      case "competitive-analyses":
        return <RiseOutlined />;
      case "reporting-influencers":
        return <UserOutlined />;
      case "overlap-analyses":
        return <SyncOutlined />;
    }
  };

  return (
    <FlexBox
      flexDirection="column"
      alignItems="flex-start"
      customWidth={CustomWidthE.full}
    >
      <FlexBox gap={rem(8)}>
        {icon(feature_id)}
        <Paragraph paragraph={t(`usages.${feature_id.replace(/-/g, "_")}`)} />
      </FlexBox>
      <Progress
        percent={percentage}
        showInfo={false}
        status={percentage >= 100 ? "exception" : "normal"}
      />
      <FlexBox
        customWidth={CustomWidthE.full}
        justifyContent="space-between"
        gap={rem(4)}
      >
        <FlexBox gap={rem(4)}>
          <Paragraph
            paragraph={used.toString()}
            color={used >= quota ? designToken.red4 : theme.color.blackColor}
            fontWeight={600}
          />
          <Paragraph
            paragraph={`/ ${quota} ${t("left")}`}
            color={designToken.colorTextTertiary}
          />
        </FlexBox>
        {action && (
          <BlueLink
            type="grey"
            text={action.title}
            onClick={action.onClickhandle}
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default UsageItem;
