import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { getExistedOutputs } from "../../../data-access/getExistedOutputs";
import { getMediaGroups } from "../../../data-access/getMediaGroups";
import {
  ExistedOutputT,
  MediaGroupKindT,
  MediaGroupT,
  MediaKindT,
  MediaT,
} from "../../../types";
import ExistedModalScroll from "./ExistedModalScroll";

const ContentWrapper = styled(FlexBox)({
  height: rem(500),
});

const TabLink = styled(FlexBox)<{ active: boolean }>(({ active }) => ({
  cursor: "pointer",
  borderBottom: `1px solid ${active ? theme.color.yellowColor : "none"}`,

  ["p"]: {
    fontWeight: active ? 600 : 500,
    padding: rem(5),
  },
}));

export type ExistedOutputsParamsModalT = {
  type: MediaKindT;
  partnership_id: string;
};

export type ExistedMediaGroupParamsModalT = {
  type: MediaKindT;
  kinds: MediaGroupKindT[];
  campaign_id: string;
  partnership_ids: string[];
  exclude_ids: string[];
};

export type ExistedOutputsModalVariantT = "output" | "media_group" | "media";

export type ExistedModalAttributesT = {
  variant: ExistedOutputsModalVariantT;
  requestParams: ExistedOutputsParamsModalT | ExistedMediaGroupParamsModalT;
};

export type ExistedOutputsModalStateT = {
  modalAttributes: ExistedModalAttributesT[];
  setExistedOutput?: Dispatch<SetStateAction<ExistedOutputT | undefined>>;
  setExistedMediaGroup?: Dispatch<SetStateAction<MediaGroupT | undefined>>;
  setExistedMedia?: Dispatch<SetStateAction<MediaT | undefined>>;
};

type ExistedOutputsModalT = {
  outputsState: ExistedOutputsModalStateT;
  closeModal: () => void;
};

export const isMediaGroup = (
  value: ExistedOutputT | MediaGroupT | MediaT,
): value is MediaGroupT => {
  return (value as MediaGroupT).partnership !== undefined;
};

export const isOutput = (
  value: ExistedOutputT | MediaGroupT | MediaT,
): value is ExistedOutputT => {
  return (value as ExistedOutputT).owner !== undefined;
};

export const isMediaGroupParams = (
  value: ExistedOutputsParamsModalT | ExistedMediaGroupParamsModalT,
): value is ExistedMediaGroupParamsModalT => {
  return (value as ExistedMediaGroupParamsModalT).campaign_id !== undefined;
};

export const isMedia = (
  value: ExistedOutputT | MediaGroupT | MediaT,
): value is MediaT => {
  return (value as MediaT).url !== undefined;
};

const ExistedOutputsModal: FC<ExistedOutputsModalT> = ({
  outputsState,
  closeModal,
}) => {
  const [localExistedOutput, setLocalExistedOutput] = useState<
    ExistedOutputT | MediaGroupT | MediaT | undefined
  >();

  const { clientId } = useContext(MediaGroupsHelperContext);
  const {
    modalAttributes,
    setExistedOutput,
    setExistedMediaGroup,
    setExistedMedia,
  } = outputsState;
  const kind: MediaKindT = modalAttributes[0].requestParams.type;

  const [activeTab, setActiveTab] = useState<ExistedOutputsModalVariantT>(
    modalAttributes.length > 1 ? "output" : "media",
  );

  const { t } = useTranslate("brands.reportings.media_groups.existed");

  const getData = (
    variant: ExistedOutputsModalVariantT,
    params: ExistedOutputsParamsModalT | ExistedMediaGroupParamsModalT,
    pageParam: number,
  ): Promise<ExistedOutputT[] | MediaGroupT[]> => {
    if (
      ["media_group", "media"].includes(variant) &&
      isMediaGroupParams(params)
    ) {
      return getMediaGroups({
        clientId,
        apiUrl: getUrlWithQuery(
          getApiUrl(ApiRoutesE.CAMPAIGN_MEDIA_GROUPS, params.campaign_id),
          {
            ...params,
            page: pageParam,
            per_page: 15,
          },
        ),
      });
    }

    return getExistedOutputs(
      clientId,
      getUrlWithQuery(getApiUrl(ApiRoutesE.OUTPUTS), {
        ...params,
        page: pageParam,
        per_page: 15,
      }),
    );
  };

  const onClickHandle = (): void => {
    if (localExistedOutput) {
      if (isOutput(localExistedOutput)) {
        setExistedOutput && setExistedOutput(localExistedOutput);
      }
      if (isMediaGroup(localExistedOutput)) {
        setExistedMediaGroup && setExistedMediaGroup(localExistedOutput);
      }
      if (isMedia(localExistedOutput)) {
        setExistedMedia && setExistedMedia(localExistedOutput);
      }
      closeModal();
    }
  };

  return (
    <FlexBox
      flexDirection="column"
      customWidth={CustomWidthE.full}
      fullHeight={true}
      justifyContent="space-between"
    >
      <ContentWrapper
        customWidth={CustomWidthE.full}
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <Heading
          heading={t(`title_${kind}`)}
          headingType={HeadingTypeT.h2}
          spaceBottom="mini"
        />
        <Spacer direction="vertical" size="small" />
        {modalAttributes.length > 1 && (
          <FlexBox>
            <TabLink
              active={activeTab == "output"}
              onClick={() => setActiveTab("output")}
            >
              <Paragraph paragraph={t("tabs.all")} />
            </TabLink>
            <Spacer direction="horizontal" size="small" />
            <TabLink
              active={activeTab == "media"}
              onClick={() => setActiveTab("media")}
            >
              <Paragraph paragraph={t("tabs.campaign")} />
            </TabLink>
          </FlexBox>
        )}
        <Spacer direction="vertical" size="mini" />
        {modalAttributes.map((modalAttribute) => (
          <ExistedModalScroll
            key={modalAttribute.variant}
            modalAttributes={modalAttribute}
            hidden={
              modalAttributes.length > 1 && activeTab !== modalAttribute.variant
            }
            subtitle={t(`subtitles.${modalAttribute.variant}`)}
            getData={getData}
            localExistedOutput={localExistedOutput}
            setLocalExistedOutput={setLocalExistedOutput}
          />
        ))}
        <Spacer direction="vertical" />
      </ContentWrapper>
      <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-end">
        <Button
          size={ButtonSizeE.small}
          type={ButtonTypeE.grey}
          onClick={closeModal}
        >
          {t("close_button")}
        </Button>
        <Spacer size="mini" />
        <Button
          size={ButtonSizeE.small}
          disabled={!localExistedOutput}
          onClick={onClickHandle}
        >
          {modalAttributes[0].variant === "media_group"
            ? t("move_button")
            : t("add_button")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default ExistedOutputsModal;
