import styled from "@emotion/styled";
import { Button, Form, FormProps, Input } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Navigate } from "react-router-dom";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { updateSessionMutation } from "../../../../data-access/mutations/updateSessionMutation";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import { updateUser } from "../../data-access/updateUser";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { validateEmptyString } from "./helpers";

const img = require("../../../../assets/personal-details-bg.png");

const Wrapper = styled(FlexBox)({
  width: "100%",
  height: "100%",

  [theme.media.maxMd]: {
    flexDirection: "column",
  },
});

const ContentWrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "50%",
  padding: rem(64),
  height: "100%",
  gap: rem(32),

  [theme.media.maxMd]: {
    width: "100%",
  },
});

const ImageWrapper = styled(ContentWrapper)({
  backgroundColor: hexToRgb(designToken.colorPrimaryBg, 0.24),
  borderRadius: `0 ${rem(designToken.borderRadiusLG)} ${rem(
    designToken.borderRadiusLG,
  )} 0`,

  [theme.media.maxMd]: {
    display: "none",
  },
});

const Image = styled(FlexBox)({
  backgroundImage: `url("${img}")`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundPositionY: "center",
  width: rem(335),
  height: rem(380),
});

type FieldType = {
  name: string;
  company: string;
};

const PersonalDetails: FC = () => {
  const { session } = useContext(ApplicationContext);

  const areAttributesFilled = !!(session?.name && session?.brand?.company_name);

  const navigate = useRouterNavigate();

  const { t } = useTranslate("brands.wizard.personal_details");

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (params: FieldType) => updateUser(params.name, params.company),
    {
      onSuccess: (data) => {
        updateSessionMutation(queryClient, data);
        navigate(NavigationKeyE.Wizard, "plan");
      },
    },
  );

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    mutate(values);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo,
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Wrapper>
      {!areAttributesFilled ? (
        <>
          <ContentWrapper>
            <FlexBox flexDirection="column" gap={rem(16)}>
              <Paragraph paragraph={t("title")} />
              <Paragraph paragraph={t("description")} fontWeight={600} />
            </FlexBox>
            <FlexBox flexDirection="column" customWidth="100%">
              <Form
                name="basic"
                layout="vertical"
                labelCol={{ span: 8 }}
                style={{ width: "100%" }}
                initialValues={{
                  name: session?.name,
                  company: session?.brand?.company_name,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                requiredMark={false}
              >
                <Form.Item<FieldType>
                  label={t("form.name")}
                  name="name"
                  rules={[
                    { required: true, message: t("form.errors.base") },
                    () => ({
                      validator: (_rule, value) =>
                        validateEmptyString(value, t("form.errors.format")),
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item<FieldType>
                  label={t("form.company")}
                  name="company"
                  rules={[
                    { required: true, message: t("form.errors.base") },
                    () => ({
                      validator: (_rule, value) =>
                        validateEmptyString(value, t("form.errors.format")),
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    {t("form.submit")}
                  </Button>
                </Form.Item>
              </Form>
            </FlexBox>
          </ContentWrapper>
          <ImageWrapper>
            <Image />
          </ImageWrapper>
        </>
      ) : (
        <Navigate to={`${NavigationKeyE.Wizard}/plan`} replace />
      )}
    </Wrapper>
  );
};

export default PersonalDetails;
