import React, { Dispatch, FC, SetStateAction } from "react";
import AvatarTile from "../../../../../../components/AvatarTile";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../../components/Button";
import FlexBox from "../../../../../../components/FlexBox";
import { formatTableValue } from "../../../../../../helpers/formaters";
import Icon, { IconTypeE } from "../../../../../../ui/icons/Icon";
import TableColumn from "../../../../../../ui/table/TableColumn";
import TableItemWrapper from "../../../../../../ui/table/TableItemWrapper";
import { CampaignPartnershipT } from "../../../../types";
import EngagementBenchmark from "../../../partnerships/table/EngagementBenchmark";
import { CampaignPartnershipToRemoveT } from "./CampaignPartershipsTable";
import LiveInput from "./LiveInput";

type TableRowT = {
  campaignId: string;
  campaignPartnership: CampaignPartnershipT;
  clientId: string;
  last: boolean;
  hide: boolean;
  setCampaignPartnershipToRemove: Dispatch<
    SetStateAction<CampaignPartnershipToRemoveT | undefined>
  >;
};

const TableRow: FC<TableRowT> = ({
  campaignPartnership,
  clientId,
  last,
  campaignId,
  hide,
  setCampaignPartnershipToRemove,
}) => {
  const {
    id,
    partnership,
    price,
    visits,
    conversions,
    cpm,
    cost_per_visit,
    cost_per_conversion,
    impressions,
    media_groups_count,
    engagement_rate,
  } = campaignPartnership;

  const { avatar_url, handle, display_name, network, engagement_rate_median } =
    partnership;

  return (
    <TableItemWrapper
      last={last}
      temporaryHide={hide}
      renderChildren={() => (
        <>
          <TableColumn columnWidth="24%">
            <AvatarTile
              handle={handle}
              network={network}
              name={display_name}
              imageUrl={avatar_url}
            />
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            {media_groups_count || "0"}
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            {formatTableValue(impressions)}
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            <EngagementBenchmark
              value={engagement_rate}
              median={engagement_rate_median}
            />
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            {formatTableValue(cpm)}
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            {formatTableValue(cost_per_visit)}
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            {formatTableValue(cost_per_conversion)}
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            <LiveInput
              attribute={"price"}
              campaignId={campaignId}
              campaignPartnershipId={id}
              clientId={clientId}
              value={price}
            />
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            <LiveInput
              attribute={"visits"}
              campaignId={campaignId}
              campaignPartnershipId={id}
              clientId={clientId}
              value={visits}
            />
          </TableColumn>
          <TableColumn columnWidth="8%" justifyContent="flex-end">
            <LiveInput
              attribute={"conversions"}
              campaignId={campaignId}
              campaignPartnershipId={id}
              clientId={clientId}
              value={conversions}
            />
          </TableColumn>
          <TableColumn columnWidth="4%" justifyContent="flex-end">
            <FlexBox>
              <Button
                onClick={() =>
                  setCampaignPartnershipToRemove({
                    id,
                    campaignId,
                    removed: false,
                  })
                }
                type={ButtonTypeE.grey}
                size={ButtonSizeE.micro}
              >
                <Icon icon={IconTypeE.bin} />
              </Button>
            </FlexBox>
          </TableColumn>
        </>
      )}
    />
  );
};

export default TableRow;
