import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ButtonTypeE } from "../../../../../../components/Button";
import ModalDialog from "../../../../../../components/ModalDialog";
import { getUrlWithQuery } from "../../../../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../../../../helpers/routes";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { removeCampaignPartnershipMutation } from "../../../../data-access/mutation/removeCampaignPartnershipMutation";
import { removeCampaignPartnership } from "../../../../data-access/removeCampaignPartnership";
import { CampaignPartnershipToRemoveT } from "../Table";

type RemoveModalT = {
  clientId: string;
  campaignPartnershipToRemove: CampaignPartnershipToRemoveT | undefined;
  setCampaignPartnershipToRemove: Dispatch<
    SetStateAction<CampaignPartnershipToRemoveT | undefined>
  >;
};

const RemoveModal: FC<RemoveModalT> = ({
  clientId,
  campaignPartnershipToRemove,
  setCampaignPartnershipToRemove,
}) => {
  const [removeDisable, setRemoveDisable] = useState<boolean>(false);

  const { t } = useTranslate("brands.reportings.campaigns");

  const queryClient = useQueryClient();
  const { mutate } = useMutation(removeCampaignPartnership, {
    onMutate: () => {
      setRemoveDisable(true);
      setCampaignPartnershipToRemove((prevState) => {
        if (prevState) {
          return { ...prevState, removed: true };
        }

        return prevState;
      });
    },

    onSuccess: () => {
      if (campaignPartnershipToRemove) {
        setTimeout(() => {
          removeCampaignPartnershipMutation(
            queryClient,
            campaignPartnershipToRemove.id,
            clearStatesAfterRemoving,
          );
        }, 300);
      }
    },

    onError: () => {
      clearStatesAfterRemoving();
    },
  });

  const clearStatesAfterRemoving = (): void => {
    setCampaignPartnershipToRemove(undefined);
    setRemoveDisable(false);
  };

  const removeCampaignPartnershipHandle = (): void => {
    if (campaignPartnershipToRemove) {
      const data = {
        url: getUrlWithQuery(
          getApiUrl(
            ApiRoutesE.CAMPAIGN_PARTNERSHIP_ITEM,
            campaignPartnershipToRemove.id,
          ),
          { campaign_id: campaignPartnershipToRemove.campaignId },
        ),
        clientId,
      };

      mutate(data);
    }
  };

  return (
    <ModalDialog
      buttonCloseTitle={t("buttons.modal_close")}
      buttonConfirmTitle={t("buttons.confrim_remove_influencer")}
      confirmButtonType={ButtonTypeE.error}
      disabledConfirmButton={removeDisable}
      onCloseHandle={() => setCampaignPartnershipToRemove(undefined)}
      onConfirmHandle={removeCampaignPartnershipHandle}
      subtitle={t("modal.remove_question")}
      title={t("modal.remove_title")}
    />
  );
};

export default RemoveModal;
