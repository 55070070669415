import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { PartnershipT } from "../types";

type createPartnershipPayloadT = {
  partnership: {
    instagram_handles: string;
    tiktok_handles: string;
  };
};

export const createPartnership = async (
  payload: createPartnershipPayloadT,
  clientId: string,
): Promise<{ partnerships: PartnershipT[] }> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .post(getApiUrl(ApiRoutesE.PARTNERSHIPS), payload, config)
    .then((res) => res.data);
};
