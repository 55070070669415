import axios from "axios";
import { MediaGroupT } from "../types";

type getCampaingMediaGroupsPropsT = {
  apiUrl: string;
  clientId: string;
};

export const getCampaignMediaGroups = async ({
  apiUrl,
  clientId,
}: getCampaingMediaGroupsPropsT): Promise<MediaGroupT[]> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.get(apiUrl, config).then((res) => res.data.media_groups);
};
