import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CampaignT } from "../types";

export const getCampaign = async (
  clientId: string,
  id: string,
): Promise<CampaignT> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .get(getApiUrl(ApiRoutesE.CAMPAIGN, id), config)
    .then((res) => res.data.campaign);
};
