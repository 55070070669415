import axios from "axios";
import { CampaignPartnershipT } from "../types";

export const getCampaignPartnerships = async (
  clientId: string,
  url: string,
): Promise<CampaignPartnershipT[]> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios.get(url, config).then((res) => res.data.campaign_partnerships);
};
