import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Modal from "../../../components/Modal";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import { ClientsContext } from "../../../context-providers/ClientsContextProvider";
import { CampaignModalContext } from "../context-providers/CampaignModalContextProvider";
import { NavigationKeyE } from "../router";
import CampaignModal from "./CampaignModal";

const ReportingRoot: FC = () => {
  const [clientIdIsStored, setClientIdIsStored] = useState(false);
  const { session } = useContext(ApplicationContext);
  const { currentClientId, setAndStoreClientId } = useContext(ClientsContext);
  const { campaignModal, setCampaignModal, setCampaignDataRefetch } =
    useContext(CampaignModalContext);

  useEffect(() => {
    session && session.clients && setAndStoreClientId(session.clients);
    setClientIdIsStored(true);
  }, [session]);

  const getContent = (): JSX.Element => {
    if (!clientIdIsStored) {
      return <></>;
    }

    return (
      <>
        {currentClientId ? (
          <>
            <Outlet />

            {currentClientId && (
              <Modal
                visible={!!campaignModal}
                close={() => setCampaignModal(undefined)}
                renderChildren={(visible, close) => (
                  <CampaignModal
                    visible={visible}
                    close={close}
                    clientId={currentClientId}
                    setCampaignDataRefetch={setCampaignDataRefetch}
                  />
                )}
              />
            )}
          </>
        ) : (
          <Navigate to={NavigationKeyE.EmptyClients} replace />
        )}
      </>
    );
  };

  return getContent();
};

export default ReportingRoot;
