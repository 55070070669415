import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../components/FlexBox";
import Panel from "../../../../components/Panel";
import Spacer from "../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { rem } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { CampaignT } from "../../types";
import TableRow from "../campaigns/table/TableRow";

const BackArrow = styled(FlexBox)({
  height: rem(80),
  cursor: "pointer",
});

type HeaderT = {
  clientId: string;
  isLoading: boolean;
  campaign?: CampaignT;
};

const Header: FC<HeaderT> = ({ clientId, campaign, isLoading }) => {
  const navigate = useRouterNavigate();

  const getContent = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.table_item_no_avatar}
          count={1}
          direction="column"
          withBorder={false}
        />
      );
    }

    if (campaign) {
      return (
        <TableRow
          clientId={clientId}
          campaign={campaign}
          last={true}
          hide={false}
        />
      );
    }

    return null;
  };

  return (
    <Panel position="relative" marginBottom={"mini"}>
      <BackArrow onClick={() => navigate(NavigationKeyE.Campaigns)}>
        <Spacer />
        <Icon icon={IconTypeE.arrowLeft} size="small" />
      </BackArrow>
      {getContent()}
    </Panel>
  );
};

export default Header;
