import Modal from "antd/es/modal/Modal";
import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Container from "../../../../components/Container";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import Input from "../../../../features/Input";
import Select from "../../../../features/Select";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { createOverlapAnalysis } from "../../data-access/createOverlapAnalysis";
import { getNetworkProfileSuggestions } from "../../data-access/getNetworkProfileSuggestions";
import { getOverlapAnalyses } from "../../data-access/getOverlapAnalyses";
import { createOverlapAnalysisMutation } from "../../data-access/mutation/createOverlapAnalysisMutation";
import LimitHeader from "../../ui/LimitHeader";
import Table from "./Table";

const OverlapAnalysesContainer: FC = () => {
  const [title, setTitle] = useState<string>("");
  const [handles, setHandles] = useState<string[]>([]);
  const [term, setTerm] = useState<string>("");
  const [addNewModal, setAddNewModal] = useState<boolean>(false);

  const { t } = useTranslate("brands.overlap_analyses.index");

  const clearStates = (): void => {
    setAddNewModal(false);
    setTitle("");
    setHandles([]);
  };

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    () =>
      createOverlapAnalysis({
        overlap_analysis: {
          title: title,
          handles: handles,
        },
      }),
    {
      onSuccess: (data) => {
        createOverlapAnalysisMutation(
          queryClient,
          data.overlap_analysis,
          clearStates,
        );
      },
    },
  );

  const { data: suggestions, refetch } = useQuery(
    QueryKeys.NETWORK_PROFILE_SUGGESTIONS,
    () =>
      getNetworkProfileSuggestions({
        term: term,
        provider: "instagram",
      }),
  );

  const {
    data: overlapAnalyses,
    isLoading,
    isRefetching,
  } = useQuery(QueryKeys.OVERLAP_ANALYSES, () => getOverlapAnalyses());

  const onTypeChangeHandle = (value: string): void => {
    if (value.length > 1) {
      setTerm(value);
      refetch();
    }
  };

  return (
    <Container>
      <LimitHeader
        title={t("title")}
        onClickHandle={setAddNewModal}
        submitTitle={t("create")}
        entitlementFeature="overlap-analyses"
      />
      <Table
        overlapAnalyses={overlapAnalyses}
        dataIsLoading={isLoading || isRefetching}
        onAddNewCampaignClickHandle={() => setAddNewModal(true)}
      />

      <Modal
        open={addNewModal}
        onCancel={() => setAddNewModal(false)}
        onOk={() => mutate()}
        title={t("modal.header")}
        okText={t("modal.create")}
        cancelText={t("modal.cancel")}
        okButtonProps={{
          disabled:
            title.length === 0 ||
            handles.length < 2 ||
            handles.length > 30 ||
            isLoading,
        }}
      >
        <Input value={title} setValue={setTitle} label={t("modal.title")} />
        <Paragraph paragraph={t("modal.handles")} fontWeight={600} />
        <Spacer direction="vertical" />
        <Select
          mode="tags"
          placeholder={t("modal.handles_placeholder")}
          onChange={setHandles}
          value={handles}
          onSearch={onTypeChangeHandle}
          addonBefore="@"
          tokenSeparators={[","]}
          options={
            suggestions?.map((s) => ({
              label: s.profile_id,
              value: s.profile_id,
            })) || []
          }
          style={{ width: "100%" }}
        />
        <Spacer direction="vertical" />
      </Modal>
    </Container>
  );
};

export default OverlapAnalysesContainer;
