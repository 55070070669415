import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import Popover from "../../../../../../components/Popover";
import SortLink from "../../../../../../components/SortLink";
import { SortingOptionsT } from "../../../../../../hooks/useLocalSort";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../../ui/icons/Icon";
import TableColumnWrapper from "../../../../../../ui/table/TableColumnWrapper";
import TableHeadWrapper from "../../../../../../ui/table/TableHeadWrapper";
import EngagementRatePopover from "../../../partnerships/table/EngagementRatePopover";

type TableHeadT = {
  sortingOptions: SortingOptionsT;
  setSortingOptions: Dispatch<SetStateAction<SortingOptionsT>>;
};

const TableHead: FC<TableHeadT> = ({ sortingOptions, setSortingOptions }) => {
  const { t } = useTranslate("brands.reportings.table");

  return (
    <TableHeadWrapper padding={rem(8)}>
      <TableColumnWrapper columnWidth="24%" justifyContent="center">
        <SortLink
          attribute={"partnership.handle"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"media_groups_count"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"impressions"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"engagement_rate"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
        <EngagementRatePopover />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"cpm"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
        <Popover
          renderTrigger={() => (
            <FlexBox>
              <Icon
                icon={IconTypeE.infoCircle}
                color={theme.color.textGreyColor}
                size="small"
                zIndex={"auto"}
              />
            </FlexBox>
          )}
          content={t("popovers.cpm")}
          width={200}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"cost_per_visit"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
        <Popover
          renderTrigger={() => (
            <FlexBox>
              <Icon
                icon={IconTypeE.infoCircle}
                color={theme.color.textGreyColor}
                size="small"
                zIndex={20}
              />
            </FlexBox>
          )}
          content={t("popovers.cost_per_visit")}
          width={200}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"cost_per_conversion"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
        <Popover
          renderTrigger={() => (
            <FlexBox>
              <Icon
                icon={IconTypeE.infoCircle}
                color={theme.color.textGreyColor}
                size="small"
                zIndex={20}
              />
            </FlexBox>
          )}
          content={t("popovers.cost_per_conversion")}
          width={200}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"price"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"visits"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="8%">
        <SortLink
          attribute={"conversions"}
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
      </TableColumnWrapper>
      <TableColumnWrapper columnWidth="4%"></TableColumnWrapper>
    </TableHeadWrapper>
  );
};

export default TableHead;
