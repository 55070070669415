import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

type removePartnershipPropsT = {
  partnershipId: string;
  clientId: string;
};

export const removePartnership = async ({
  clientId,
  partnershipId,
}: removePartnershipPropsT): Promise<{}> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .delete(getApiUrl(ApiRoutesE.PARTNERSHIPS_ITEM, partnershipId), config)
    .then((res) => res.data);
};
