import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { numberToText } from "../../../../../helpers/formaters";
import { rem, theme } from "../../../../../styling/theme";
import { MediaT } from "../../../types";

const Wrapper = styled(FlexBox)({
  backgroundColor: theme.color.whiteColor,
  border: `1px solid ${theme.color.greyLightColor}`,
  borderRadius: rem(8),
  padding: rem(10),
});

const MetricValue = styled(Paragraph)({
  fontWeight: 600,
});

type MetricsT = "impressions" | "likes" | "comments";

type MetricCardT = {
  medias: MediaT[];
  metric: MetricsT;
  label: string;
};

const MetricCard: FC<MetricCardT> = ({ medias, metric, label }) => {
  const getMetric = (metric: MetricsT): string => {
    if (medias.length < 1) return "0";

    const notNull = (value: number | null): value is number => {
      return value !== null;
    };
    const value = medias
      .map((media) => media[metric])
      .filter(notNull)
      .reduce((a, b) => a + b, 0);

    return numberToText(value);
  };

  return (
    <Wrapper flexDirection="column">
      <MetricValue paragraphSize="big" paragraph={getMetric(metric)} />
      <Paragraph paragraph={label} color={theme.color.textGreyColor} />
    </Wrapper>
  );
};

export default MetricCard;
