import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { theme } from "../../../../../styling/theme";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableHeadWrapper from "../../../../../ui/table/TableHeadWrapper";

const TableHead: FC<{}> = () => {
  const { t } = useTranslate("brands.reportings.campaigns.table");

  return (
    <TableHeadWrapper padding={0}>
      <FlexBox customWidth={CustomWidthE.custom80}>
        <TableColumn columnWidth={CustomWidthE.custom40}>
          <Paragraph
            paragraph={t("campaign")}
            color={theme.color.textGreyColor}
            paragraphSize="small"
          />
        </TableColumn>
        <TableColumn columnWidth={CustomWidthE.custom30}>
          <Paragraph
            paragraph={t("mentions")}
            color={theme.color.textGreyColor}
            paragraphSize="small"
          />
        </TableColumn>
        <TableColumn columnWidth={CustomWidthE.custom30}>
          <Paragraph
            paragraph={t("hashtags")}
            color={theme.color.textGreyColor}
            paragraphSize="small"
          />
        </TableColumn>
      </FlexBox>
      <TableColumn columnWidth={CustomWidthE.fifth}></TableColumn>
    </TableHeadWrapper>
  );
};

export default TableHead;
