import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Route, Routes, useMatch, useParams } from "react-router-dom";
import { ClientsContext } from "../../../../context-providers/ClientsContextProvider";
import { getUrlWithQuery } from "../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { CampaignModalContext } from "../../context-providers/CampaignModalContextProvider";
import { getCampaign } from "../../data-access/getCampaign";
import { getCampaignMediaGroups } from "../../data-access/getCampaignMediaGroups";
import { getCampaignPartnerships } from "../../data-access/getCampaignPartnerships";
import { setMediaGroupPendingState } from "../../data-access/mutation/setMediaGroupPendingState";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { CampaignOutputsFilterStateT, SortingStateT } from "../../types";
import ReportingContainer from "../components/ReportingContainer";
import MediaGroupsContainer from "../media-groups/MediaGoupsContainer";
import Dashboard from "./analytics/Dashboard";
import Header from "./Header";
import OptionsSection from "./OptionsSection";
import { SectionTypeT } from "./SectionsSwitcher";

const CampaignDetailContainer: FC = () => {
  const { id } = useParams() as { id: string };

  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingState, setSortingState] = useState<SortingStateT>({
    sort: "published_at:desc",
  });
  const [filterState, setFilterState] = useState<
    CampaignOutputsFilterStateT | undefined
  >(undefined);

  const match = useMatch(`${NavigationKeyE.Campaigns}/${id}/:tab`);
  const activeTab = match ? (match.params.tab as SectionTypeT) : "media_groups";

  const { campaignDataRefetch } = useContext(CampaignModalContext);
  const { currentClientId: clientId } = useContext(ClientsContext) as {
    currentClientId: string;
  };

  const navigate = useRouterNavigate();

  const queryClient = useQueryClient();

  const {
    data: campaign,
    isLoading,
    isRefetching,
    refetch: campaignRefetch,
  } = useQuery(QueryKeys.CAMPAIGN_DETAIL, () => getCampaign(clientId, id));

  const {
    data: campaignPartnerships,
    isLoading: campaignPartnershipsAreLoading,
    refetch: partnershipsRefetch,
  } = useQuery(QueryKeys.CAMPAIGN_PARTNERSHIPS, () =>
    getCampaignPartnerships(
      clientId,
      getUrlWithQuery(getApiUrl(ApiRoutesE.CAMPAIGN_PARTNERSHIP), {
        campaign_id: id,
      }),
    ),
  );

  const {
    data: campaignMediaGroups,
    isRefetching: campaignMediaGroupsAreFetching,
    isLoading: campaignMediaGroupsAreLoading,
    refetch: refetchCampaignMediaGroups,
  } = useQuery(QueryKeys.CAMPAIGN_MEDIA_GROUPS, () =>
    getCampaignMediaGroups({
      apiUrl: getUrlWithQuery(getApiUrl(ApiRoutesE.CAMPAIGN_MEDIA_GROUPS, id), {
        ...filterState,
        ...sortingState,
      }),
      clientId,
    }),
  );

  const onTabChangeHandle = (key: string): void => {
    navigate(NavigationKeyE.Campaigns, `${id}/${key}`);
  };

  useEffect(() => {
    if (!firstLoad) {
      refetchCampaignMediaGroups();
      setMediaGroupPendingState(queryClient, false);
      return;
    }

    setFirstLoad(false);
  }, [filterState, sortingState]);

  useEffect(() => {
    if (!isLoading) {
      partnershipsRefetch();
      campaignRefetch();
      refetchCampaignMediaGroups();
      setMediaGroupPendingState(queryClient, false);
    }
  }, [clientId, campaignDataRefetch, id]);

  return (
    <ReportingContainer>
      <Header
        clientId={clientId}
        campaign={campaign}
        isLoading={isLoading || isRefetching}
      />

      <OptionsSection
        activeTab={activeTab}
        onTabChangeHandle={onTabChangeHandle}
        filterState={filterState}
        setFilterState={setFilterState}
        sortingState={sortingState}
        setSortingState={setSortingState}
        clientId={clientId}
        campaignId={id}
      />

      <Routes>
        <Route
          path="media_groups"
          element={
            <MediaGroupsContainer
              clientId={clientId}
              campaignId={id}
              mediaGroups={campaignMediaGroups}
              queryStack={QueryKeys.CAMPAIGN_MEDIA_GROUPS}
              addedQueryStack={QueryKeys.CAMPAIGN_MEDIA_GROUPS_ADDED}
              loading={
                campaignMediaGroupsAreFetching || campaignMediaGroupsAreLoading
              }
            />
          }
        />
        <Route
          path="analytics"
          element={
            campaign &&
            campaignPartnerships &&
            campaignMediaGroups && (
              <Dashboard
                clientId={clientId}
                campaign={campaign}
                campaignPartnerships={campaignPartnerships}
                mediaGroups={campaignMediaGroups}
                dataIsLoading={campaignPartnershipsAreLoading}
              />
            )
          }
        />
      </Routes>
    </ReportingContainer>
  );
};

export default CampaignDetailContainer;
