import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CampaignT } from "../types";

export const getCampaigns = async (clientId: string): Promise<CampaignT[]> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .get(getApiUrl(ApiRoutesE.CAMPAIGNS), config)
    .then((res) => res.data.campaigns);
};
