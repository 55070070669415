import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Button, { ButtonTypeE } from "../../components/Button";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../components/Heading";
import IconText from "../../components/IconText";
import Spacer from "../../components/Spacer";
import { ClientsContext } from "../../context-providers/ClientsContextProvider";
import { createClient } from "../../data-access/createClient";
import { deleteClientsMutation } from "../../data-access/mutations/deleteClientsMutation";
import { updateClientsMutation } from "../../data-access/mutations/updateClientsMutation";
import { removeClient } from "../../data-access/removeClient";
import { updateClient } from "../../data-access/updateClient";
import { ClientBodyT, ClientT } from "../../entry-files/types";
import { useTranslate } from "../../hooks/useTranslate";
import { theme } from "../../styling/theme";
import { IconTypeE } from "../../ui/icons/Icon";
import Input from "../Input";
import TagsInput from "../TagsInput";

const HorizontalLine = styled.div({
  borderBottom: `1px solid ${theme.color.greyColor}`,
  width: "100%",
});

export type ClientModalStateT = {
  type: "update" | "create";
  client?: ClientT;
};

type ClientModalT = {
  clientModalState: ClientModalStateT;
  close: () => void;
  visible: boolean;
};

const ClientModal: FC<ClientModalT> = ({
  clientModalState,
  close,
  visible,
}) => {
  const { type, client } = clientModalState;

  const [name, setName] = useState<string>(client ? client.name : "");
  const [instagramMentions, setInstagramMentions] = useState<string[]>(
    client ? client.instagram_mentions : [],
  );
  const [instagramHashtags, setInstagramHashtags] = useState<string[]>(
    client ? client.instagram_hashtags : [],
  );
  const [tiktokMentions, setTiktokMentions] = useState<string[]>(
    client ? client.tiktok_mentions : [],
  );
  const [tiktokHashtags, setTiktokHashtags] = useState<string[]>(
    client ? client.tiktok_hashtags : [],
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const { t } = useTranslate(`brands.reportings.clients.modal`);
  const { currentClientId, setCurrentClientId, setClientUpdated } =
    useContext(ClientsContext);

  const updatedClient: ClientBodyT = {
    name,
    instagram_mentions: instagramMentions,
    instagram_hashtags: instagramHashtags,
    tiktok_mentions: tiktokMentions,
    tiktok_hashtags: tiktokHashtags,
  };

  const isDisalbed =
    name.length < 3 ||
    (instagramMentions.length === 0 &&
      instagramHashtags.length === 0 &&
      tiktokMentions.length === 0 &&
      tiktokHashtags.length === 0);
  const allowToDelete =
    client && client.id !== currentClientId && type === "update";

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    client && type === "update"
      ? () => updateClient(client.id, updatedClient)
      : () => createClient(updatedClient),
    {
      onSuccess: (data) => {
        const onSuccessHandle = (): void => {
          if (type === "create") {
            setCurrentClientId(data.client.id);
          }
          close();
        };

        updateClientsMutation(queryClient, data.client, type, onSuccessHandle);
        setClientUpdated((prevState) => !prevState);
      },
    },
  );

  const { mutate: removeMutation, isLoading: isRemoveLoading } = useMutation(
    removeClient,
    {
      onSuccess: (data) => {
        deleteClientsMutation(queryClient, data.clientId, close);
      },
    },
  );

  const onClickDeleteHandle = (): void => {
    if (client) {
      setDeleteConfirmation(false);
      removeMutation(client.id);
    }
  };

  useEffect(() => {
    if (client) {
      setName(client.name);
    }
  }, [client]);

  return (
    <>
      <FlexBox
        flexDirection="column"
        alignItems="stretch"
        customWidth={CustomWidthE.full}
      >
        <Heading heading={t(`${type}.title`)} headingType={HeadingTypeT.h2} />
        <Input value={name} setValue={setName} label={`${t("name")} *`} />
        <IconText
          icon={IconTypeE.socInstagram}
          paragraph={t("networks.instagram")}
        />
        <Spacer direction="vertical" size="mini" />
        <HorizontalLine />
        <Spacer direction="vertical" />
        <TagsInput
          setValues={setInstagramMentions}
          values={instagramMentions}
          label={t("mentions.label")}
          labelDescription={t("mentions.label_after")}
          placeholder={t("mentions.placeholder")}
          addonBefore={"@"}
        />
        <TagsInput
          setValues={setInstagramHashtags}
          values={instagramHashtags}
          label={t("hashtags.label")}
          labelDescription={t("hashtags.label_after")}
          placeholder={t("hashtags.placeholder")}
          addonBefore={"#"}
        />
        <IconText icon={IconTypeE.socTiktok} paragraph={t("networks.tiktok")} />
        <Spacer direction="vertical" size="mini" />
        <HorizontalLine />
        <Spacer direction="vertical" />
        <TagsInput
          setValues={setTiktokMentions}
          values={tiktokMentions}
          label={t("mentions.label")}
          labelDescription={t("mentions.label_after")}
          placeholder={t("mentions.placeholder")}
          addonBefore={"@"}
        />
        <TagsInput
          setValues={setTiktokHashtags}
          values={tiktokHashtags}
          label={t("hashtags.label")}
          labelDescription={t("hashtags.label_after")}
          placeholder={t("hashtags.placeholder")}
          addonBefore={"#"}
        />
        <Spacer size="big" direction="vertical" />
        <FlexBox flexDirection="row">
          {allowToDelete && (
            <>
              {deleteConfirmation ? (
                <Button
                  customWidth={CustomWidthE.half}
                  type={ButtonTypeE.error}
                  onClick={onClickDeleteHandle}
                >
                  {t("confirm")}
                </Button>
              ) : (
                <Button
                  customWidth={CustomWidthE.half}
                  type={ButtonTypeE.grey}
                  onClick={() => setDeleteConfirmation(true)}
                  loading={isRemoveLoading}
                >
                  {t("delete")}
                </Button>
              )}
              <Spacer />
            </>
          )}
          <Button
            onClick={mutate}
            disabled={isDisalbed}
            loading={isLoading}
            customWidth={allowToDelete ? CustomWidthE.half : CustomWidthE.full}
          >
            {t(`${type}.confirm`)}
          </Button>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default ClientModal;
