import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { useQuery } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { DropdownItem } from "../../../../ui/Dropdown";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { getCampaignPPTX } from "../../data-access/getCampaignPPTX";

type PPTExportT = {
  campaignId: string;
  clientId: string;
  pptxIsGenerating: boolean;
  setPptxIsGenerating: Dispatch<SetStateAction<boolean>>;
};

const PPTExport: FC<PPTExportT> = ({ children, campaignId, clientId }) => {
  const { openToast } = useContext(ToastContext);

  const { refetch } = useQuery(
    `${QueryKeys.PPTXJSON}-${campaignId}`,
    () => getCampaignPPTX(clientId, campaignId),
    {
      enabled: false,
    },
  );

  const { t } = useTranslate("brands.reportings.campaigns.table");

  const onClickHandle = (): void => {
    refetch();
    openToast({ type: "success", message: t("export.requested") });
  };

  return <DropdownItem onClick={onClickHandle}>{children}</DropdownItem>;
};

export default PPTExport;
