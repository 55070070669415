import * as React from "react";
import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { CampaignModalState } from "../types";

type CampaignModalContextT = {
  campaignModal: CampaignModalState | undefined;
  setCampaignModal: Dispatch<SetStateAction<CampaignModalState | undefined>>;
  campaignDataRefetch: boolean;
  setCampaignDataRefetch: Dispatch<SetStateAction<boolean>>;
};

export const CampaignModalContext = createContext<CampaignModalContextT>({
  campaignModal: undefined,
  setCampaignModal: () => {},
  campaignDataRefetch: false,
  setCampaignDataRefetch: () => {},
});

export const CampaignModalContextProvider: FC = ({ children }) => {
  const [campaignDataRefetch, setCampaignDataRefetch] = useState(false);
  const [campaignModal, setCampaignModal] = useState<
    CampaignModalState | undefined
  >(undefined);

  return (
    <CampaignModalContext.Provider
      value={{
        campaignModal,
        setCampaignModal,
        campaignDataRefetch,
        setCampaignDataRefetch,
      }}
    >
      {children}
    </CampaignModalContext.Provider>
  );
};
