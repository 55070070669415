import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import AvatarTile from "../../../../../../components/AvatarTile";
import FlexBox from "../../../../../../components/FlexBox";
import { rem, theme } from "../../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../../ui/icons/Icon";
import TableColumn from "../../../../../../ui/table/TableColumn";
import TableItemWrapper from "../../../../../../ui/table/TableItemWrapper";
import { MediaWithGroupT } from "./MediaGroupTable";

const imgSize = rem(150);

const ImageWrapper = styled(FlexBox)({
  width: imgSize,
  height: imgSize,
  backgroundColor: theme.color.greyLightColor,
});

const Img = styled.img({
  width: imgSize,
  height: imgSize,
  objectFit: "cover",
});

const ImgTableColumn = styled(TableColumn)({
  gap: rem(20),
});

type TableRowT = {
  media: MediaWithGroupT;
};

const TableRow: FC<TableRowT> = ({ media }) => {
  const {
    mediaGroup: { partnership },
  } = media;
  const image = media.media_files.filter((media) => media.kind === "image")[0];
  const url = image?.thumbnail_url || image?.file_url;

  return (
    <TableItemWrapper
      customHeight={imgSize}
      renderChildren={() => (
        <>
          <ImgTableColumn columnWidth="33%" justifyContent="flex-start">
            <ImageWrapper>
              {url ? (
                <Img src={url} />
              ) : (
                <Icon
                  icon={IconTypeE.eyeStrike}
                  color={theme.color.textLightGreyColor}
                  size="big"
                />
              )}
            </ImageWrapper>
            <AvatarTile
              handle={partnership.handle}
              network={partnership.network}
              name={partnership.display_name}
              imageUrl={partnership.avatar_url}
            />
          </ImgTableColumn>
          <TableColumn columnWidth="33%" justifyContent="center">
            {media.impressions}
          </TableColumn>
          <TableColumn columnWidth="33%" justifyContent="center">
            {media.engagement_rate}
          </TableColumn>
        </>
      )}
    />
  );
};

export default TableRow;
