import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { CampaignPartnershipT } from "../../types";

type updateCampaignPartnershipMutationT = (
  queryClient: QueryClient,
  data: CampaignPartnershipT,
  afterMutationHandle?: () => void,
) => void;

export const updateCampaignPartnershipMutation: updateCampaignPartnershipMutationT =
  (queryClient, data, afterMutationHandle) => {
    queryClient.setQueryData<CampaignPartnershipT[] | undefined>(
      QueryKeys.CAMPAIGN_PARTNERSHIPS,
      (prevState) => {
        if (prevState) {
          const currentPartnership = prevState.findIndex(
            (item) => item.id === data.id,
          );

          if (currentPartnership > -1) {
            const currentData = prevState[currentPartnership];
            prevState[currentPartnership] = {
              ...currentData,
              ...data,
            };
          }
        }

        return prevState;
      },
    );

    afterMutationHandle && afterMutationHandle();
  };
