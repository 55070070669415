import { Callback, Dict, RequestOptions } from "mixpanel-browser";

export enum MixpanelEventT {
  databaseDetailBrandAffinityShowDetail = "DatabaseDetail BrandAffinity ShowDetail",
  databaseDetailDownloadCopy = "DatabaseDetail Download Copy",
  databaseDetailDownloadFile = "DatabaseDetail Download File",
  databaseDetailSocialMediaProfileClick = "DatabaseDetail Social Media Profile Click",
  databaseListCheckboxInfluencerClick = "DatabaseList Checkbox Influencer click",
  profileAnalysisDetailInfluencersImpression = "ProfileAnalysisDetailInfluencers Impression",
  ProfileAnalysisDetailPostsImpression = "ProfileAnalysisDetailPosts Impression",
  DatabaseListSort = "DatabaseList Sort",
  SubscriptionManagementImpression = "Subscription Management Impression",
}

export const trackEvent = (
  event_name: MixpanelEventT,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,
): void => {
  window.mixpanel.track(event_name, properties, optionsOrCallback, callback);
};

export const mixpanel = window.mixpanel;
