import I18n, { Scope, TranslateOptions } from "i18n-js";
import * as React from "react";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export type TranslationsLocaleT = "en" | "cs";

export type TranslationsT = {
  setTranslationsReady: Dispatch<SetStateAction<boolean>>;
  translations: Record<TranslationsLocaleT, JSON> | null;
  locale: "en" | "cs";
};

export type TranslationsContextT = {
  t: (scope: Scope, options?: TranslateOptions) => string;
};

export const TranslationsContext = createContext<TranslationsContextT>({
  t: () => "",
});

const TranslationsContextProvider: FC<TranslationsT> = ({
  setTranslationsReady,
  translations,
  locale,
  children,
}) => {
  // prepare for change language option
  const [localState, _] = useState<TranslationsLocaleT>(locale);
  const pluralRules = new Intl.PluralRules("cs");

  useEffect(() => {
    I18n.translations = translations || {};
    I18n.defaultLocale = localState;
    I18n.locale = localState;
    I18n.pluralization["cs"] = (count: number) => {
      const pluralForm = pluralRules.select(count);
      return [count.toString(), pluralForm];
    };

    I18n.currentLocale();

    setTranslationsReady(true);
  }, [localState]);

  return (
    <TranslationsContext.Provider value={I18n}>
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsContextProvider;
