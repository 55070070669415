import { ConfigProvider } from "antd";
import * as React from "react";
import { RouterProvider } from "react-router-dom";
import { defaults } from "../../helpers/antDesign";
import { CampaignModalContextProvider } from "./context-providers/CampaignModalContextProvider";
import { SubscriptionContextProvider } from "./context-providers/SubscriptionsContextProvider";
import { ToastContextProvider } from "./context-providers/ToastContextProvider";
import { router } from "./router";

const CompanyRoot: React.FC = () => {
  return (
    <>
      <CampaignModalContextProvider>
        <ConfigProvider theme={defaults}>
          <ToastContextProvider>
            <SubscriptionContextProvider>
              <RouterProvider router={router} />
            </SubscriptionContextProvider>
          </ToastContextProvider>
        </ConfigProvider>
      </CampaignModalContextProvider>
    </>
  );
};

export default CompanyRoot;
