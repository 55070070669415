import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Panel from "../../../../../components/Panel";
import Spacer from "../../../../../components/Spacer";
import VisibleWrapper from "../../../../../components/VisibleWrapper";
import { getStringDate } from "../../../../../helpers/date";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { getCampaignPartnerships } from "../../../data-access/getCampaignPartnerships";
import {
  CampaignOutputsFilterStateT,
  MediaGroupKindT,
  SortingStateT,
} from "../../../types";
import FilterSection from "../../../ui/FilterSection";
import FilterInfluencers from "../../outputs/FilterInfluencers";
import FilterDate, { DateStateT } from "./FilterDate";
import FilterPostType from "./FilterPostType";

const FilterWrapper = styled(VisibleWrapper)({
  position: "absolute",
  top: rem(50),
  height: "auto",
  right: 0,
  width: rem(320),
  zIndex: 2,
});

const Close = styled(Icon)({
  position: "absolute",
  right: rem(10),
  top: rem(10),
  cursor: "pointer",
});

const Overlay = styled.div({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
});

const Devider = styled(FlexBox)({
  marginLeft: rem(8),
  paddingLeft: rem(8),
  height: rem(16),
  borderLeft: `1px solid ${theme.color.greyColor}`,
});

type SortAndFilterT = {
  clientId: string;
  campaignId: string;
  filterState: CampaignOutputsFilterStateT | undefined;
  setFiliterState: Dispatch<
    SetStateAction<CampaignOutputsFilterStateT | undefined>
  >;
  sortingState: SortingStateT;
  setSortingState: Dispatch<SetStateAction<SortingStateT>>;
};

const SortAndFilter: FC<SortAndFilterT> = ({
  filterState,
  setFiliterState,
  sortingState,
  setSortingState,
  clientId,
  campaignId,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [postTypeState, setPostTypeState] = useState<MediaGroupKindT[]>([]);
  const [dateState, setDateState] = useState<DateStateT | undefined>();
  const [partnershipIds, setPartnershipIds] = useState<string[]>([]);

  const { t } = useTranslate("brands.reportings.outputs");

  const isActive = filterState && Object.keys(filterState).length > 0;

  const {
    data: campaignPartnerships,
    isLoading,
    refetch,
  } = useQuery(QueryKeys.CAMPAIGN_PARTNERSHIPS, () =>
    getCampaignPartnerships(
      clientId,
      getUrlWithQuery(getApiUrl(ApiRoutesE.CAMPAIGN_PARTNERSHIP), {
        campaign_id: campaignId,
      }),
    ),
  );

  useEffect(() => {
    setFiliterState({
      ...(dateState?.startDate && { from: getStringDate(dateState.startDate) }),
      ...(dateState?.endDate && { to: getStringDate(dateState.endDate) }),
      ...(postTypeState.length > 0 && { kinds: postTypeState }),
      ...(partnershipIds.length > 0 && {
        partnership_ids: partnershipIds,
      }),
    });
  }, [postTypeState, dateState, partnershipIds]);

  const setSortingStateHandle = (): void => {
    setSortingState((prevState) => {
      return {
        sort:
          prevState.sort === "published_at:desc"
            ? "handle:asc,published_at:desc"
            : "published_at:desc",
      };
    });
  };

  return (
    <>
      {isFilterOpen && <Overlay onClick={() => setIsFilterOpen(false)} />}
      <FlexBox customWidth="max-content">
        <Button
          onClick={setSortingStateHandle}
          size={ButtonSizeE.small}
          type={ButtonTypeE.clear}
        >
          <Icon
            icon={
              sortingState.sort === "published_at:desc"
                ? IconTypeE.person
                : IconTypeE.sort
            }
            size="small"
          />
          <Spacer size="micro" />
          {sortingState.sort === "published_at:desc"
            ? t("filter.sort.sort_asc")
            : t("filter.sort.sort_desc")}
        </Button>
        <Devider />
        <Button
          onClick={() => setIsFilterOpen((prevState) => !prevState)}
          size={ButtonSizeE.small}
          type={ButtonTypeE.clear}
        >
          <Icon
            icon={isActive ? IconTypeE.filterFilled : IconTypeE.filter}
            size="small"
          />
          <Spacer size="micro" />
          {t("filter.title")}
        </Button>
        <FilterWrapper visible={isFilterOpen}>
          <Panel
            withShadow={true}
            flexDirection="column"
            marginBottom={0}
            justifyContent="flex-start"
            position="relative"
          >
            <Close
              onClick={() => setIsFilterOpen(false)}
              icon={IconTypeE.cross}
              size="mini"
            />
            <FilterPostType
              postTypeState={postTypeState}
              setPostTypeState={setPostTypeState}
            />
            <FilterDate dateState={dateState} setDateState={setDateState} />
            <FilterSection
              label={t("filter.influencers")}
              withBorder={false}
              withWrapper={true}
              customWidth={CustomWidthE.full}
            >
              <FilterInfluencers
                partnerships={campaignPartnerships}
                isLoading={isLoading}
                setPartnerships={setPartnershipIds}
                partnershipIds={partnershipIds}
              />
            </FilterSection>
          </Panel>
        </FilterWrapper>
      </FlexBox>
    </>
  );
};

export default SortAndFilter;
