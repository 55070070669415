import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Popover from "../../../../../components/Popover";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";

const ColorBox = styled(FlexBox)<{
  color: string;
}>(({ color }) => ({
  backgroundColor: color,
  width: rem(20),
  height: rem(20),
}));

const LevelItem = styled(FlexBox)({
  gap: rem(5),
});

const LevelsWrapper = styled(FlexBox)({
  gap: rem(5),
});

const EngagementRatePopover: FC = () => {
  const { t } = useTranslate("brands.reportings.table");

  return (
    <Popover
      width={360}
      renderTrigger={() => (
        <FlexBox>
          <Icon
            icon={IconTypeE.infoCircle}
            color={theme.color.textGreyColor}
            size="small"
            zIndex={"auto"}
          />
        </FlexBox>
      )}
      content={
        <LevelsWrapper flexDirection="column" alignItems="flex-start">
          <Paragraph
            paragraph={t("popovers.engagement_rate")}
            color={theme.color.textGreyDarkColor}
            paragraphSize={"small"}
          />
          <Paragraph
            paragraph={t("popovers.engagement_rate_median")}
            color={theme.color.textGreyDarkColor}
            paragraphSize={"small"}
          />
          <LevelItem>
            <ColorBox color={theme.color.successColor} />
            <Paragraph
              paragraph={t("median.great")}
              color={theme.color.textGreyDarkColor}
              paragraphSize={"small"}
            />
          </LevelItem>
          <LevelItem>
            <ColorBox color={theme.color.yellowPressedColor} />
            <Paragraph
              paragraph={t("median.good")}
              color={theme.color.textGreyDarkColor}
              paragraphSize={"small"}
            />
          </LevelItem>
          <LevelItem>
            <ColorBox color={theme.color.errorColor} />
            <Paragraph
              paragraph={t("median.bad")}
              color={theme.color.textGreyDarkColor}
              paragraphSize={"small"}
            />
          </LevelItem>
          <LevelItem>
            <ColorBox color={theme.color.blackColor} />
            <Paragraph
              paragraph={t("median.no_data")}
              color={theme.color.textGreyDarkColor}
              paragraphSize={"small"}
            />
          </LevelItem>
        </LevelsWrapper>
      }
    />
  );
};

export default EngagementRatePopover;
