import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import uniqid from "uniqid";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Spacer from "../../../../../components/Spacer";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../../styling/theme";
import CheckBigIcon from "../../../../../ui/CheckBigIcon";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { createMediaGroup } from "../../../data-access/createMediaGroup";
import { getCampaignPartnerships } from "../../../data-access/getCampaignPartnerships";
import { createMediaGroupMutation } from "../../../data-access/mutation/createMediaGroupMutation";
import { setMediaGroupPendingState } from "../../../data-access/mutation/setMediaGroupPendingState";
import { contentTypes, outputsTypeIcons } from "../../../dataConstants";
import {
  ExistedOutputT,
  MediaGroupKindT,
  MediaKindT,
  PartnershipNetworkT,
} from "../../../types";
import FilterSection from "../../../ui/FilterSection";
import OutputOwnership from "./OutputOwnership";

const Wrapper = styled(FlexBox)({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  padding: rem(24),
  borderRadius: rem(8),
  backdropFilter: "blur(3.5px)",
  backgroundColor: hexToRgb(theme.color.whiteColor, 0.7),
  zIndex: 2,
});

const AddNewMediaGroup: FC<{ network: PartnershipNetworkT }> = ({
  network,
}) => {
  const defaultKind = network === "tiktok" ? "video" : undefined;
  const [kindState, setKindState] = useState<MediaGroupKindT | undefined>(
    defaultKind,
  );
  const [partnershipId, setPartnershipId] = useState<string>("");
  const [existedOutput, setExistedOutput] = useState<
    ExistedOutputT | undefined
  >();

  const { clientId, campaignId, setExistedOutputsState } = useContext(
    MediaGroupsHelperContext,
  );
  const { t } = useTranslate("brands.reportings");

  const {
    data: campaignPartnerships,
    isLoading: campaignPartnershipsAreLoading,
    refetch: partnershipsRefetch,
  } = useQuery(QueryKeys.CAMPAIGN_PARTNERSHIPS, () =>
    getCampaignPartnerships(
      clientId,
      getUrlWithQuery(getApiUrl(ApiRoutesE.CAMPAIGN_PARTNERSHIP), {
        campaign_id: campaignId ? campaignId : "",
      }),
    ),
  );

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(createMediaGroup, {
    onSuccess: (data) => {
      createMediaGroupMutation(queryClient, data);
    },
  });

  const networkCampaignPartnerships = campaignPartnerships?.filter(
    (item) => item.partnership.network == network,
  );

  const createIsDisabled =
    isLoading || !kindState || partnershipId.length < 1 || !campaignId;

  const addNewContentHandle = (): void => {
    if (!createIsDisabled) {
      const mediaKind: MediaKindT =
        kindState === "story_set" ? "story" : kindState;

      const payload = {
        media_group: {
          kind: kindState,
          temporary_id: uniqid(),
          partnership_id: partnershipId,
          medias_attributes: [{ kind: mediaKind, origin: "manual" }],
        },
      };

      mutate({ clientId, campaignId, payload });
    }
  };

  const onClickFromExistedHandle = (): void => {
    if (!createIsDisabled) {
      setExistedOutputsState({
        modalAttributes: [
          {
            variant: "output",
            requestParams: {
              type: kindState === "story_set" ? "story" : kindState,
              partnership_id: partnershipId,
            },
          },
        ],
        setExistedOutput,
      });
    }
  };

  useEffect(() => {
    setPartnershipId("");
    setKindState(defaultKind);
  }, [network]);

  useEffect(() => {
    if (existedOutput && !createIsDisabled) {
      const {
        media_url,
        timestamp,
        comments,
        likes,
        impressions,
        reach,
        permalink,
      } = existedOutput;

      const payload = {
        media_group: {
          kind: kindState,
          temporary_id: uniqid(),
          partnership_id: partnershipId,
          medias_attributes: [
            {
              kind: (kindState === "story_set"
                ? "story"
                : kindState) as MediaKindT,
              published_at: timestamp,
              url: permalink,
              image_urls: media_url ? [media_url] : undefined,
              likes: likes,
              comments: comments,
              reach: reach,
              impressions: impressions,
            },
          ],
        },
      };

      mutate({ clientId, campaignId, payload });
    }
  }, [existedOutput]);

  return (
    <Wrapper flexDirection="column">
      <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-start">
        <Heading
          heading={`${t("media_groups.add_new_card.add_new_output")}`}
          headingType={HeadingTypeT.h3}
        />
      </FlexBox>
      <Spacer direction="vertical" />
      {network === "instagram" && (
        <>
          <FilterSection
            label={t("index.filter.post_type")}
            customWidth={CustomWidthE.full}
            withWrapper={false}
          >
            <FlexBox customWidth="100%" justifyContent="space-between">
              {contentTypes.map((kind, index) => (
                <CheckBigIcon
                  key={kind}
                  icon={outputsTypeIcons[kind]}
                  active={kind === kindState}
                  label={t(`labels.${kind}`)}
                  onClickHandle={() => setKindState(kind)}
                  marginRight={index !== contentTypes.length - 1}
                  background={theme.color.whiteColor}
                />
              ))}
            </FlexBox>
          </FilterSection>
          <Spacer direction="vertical" size="big" />
        </>
      )}
      <FilterSection
        label={t("outputs.filter.influencer")}
        customWidth={CustomWidthE.full}
        withWrapper={false}
        withBorder={false}
      >
        <OutputOwnership
          clientId={clientId}
          campaignPartnerships={networkCampaignPartnerships}
          isLoading={campaignPartnershipsAreLoading}
          refetch={partnershipsRefetch}
          partnershipId={partnershipId}
          setPartnershipId={setPartnershipId}
        />
      </FilterSection>
      <Spacer direction="vertical" size="big" />
      <FlexBox customWidth={CustomWidthE.full}>
        <Button
          disabled={createIsDisabled}
          size={ButtonSizeE.micro}
          customWidth={CustomWidthE.half}
          onClick={addNewContentHandle}
        >
          {`${t("media_groups.add_new_card.create")}`}
        </Button>
        <Spacer size="small" />
        <Button
          size={ButtonSizeE.micro}
          customWidth={CustomWidthE.half}
          disabled={createIsDisabled}
          onClick={onClickFromExistedHandle}
        >
          {`${t("media_groups.add_new_card.from_existing")}`}
        </Button>
      </FlexBox>
      <Spacer size="small" direction="vertical" />
      <Button
        size={ButtonSizeE.micro}
        customWidth={CustomWidthE.half}
        type={ButtonTypeE.grey}
        onClick={() => setMediaGroupPendingState(queryClient, false)}
      >
        {`${t("media_groups.add_new_card.close")}`}
      </Button>
    </Wrapper>
  );
};

export default AddNewMediaGroup;
