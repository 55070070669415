import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { ActionCableContext } from "../../..";
import LoaderComponent from "../../../../../components/Loader";
import Modal from "../../../../../components/Modal";
import { ApplicationContext } from "../../../../../context-providers/ApplicationContextProvider";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { ApprovalRequestWithAuthorT } from "../../../../types";
import { getApprovalRequestAuthorization } from "../../../data-access/getApprovalRequestAuthorization";
import { ApprovalPageStateT, OathResponseObjectT } from "../../../types";
import { Heading } from "../../../ui/Header";
import { ModalAttributesT } from "../ApprovalRequestContainer";
import ApprovedStatement from "../ApprovedStatement";
import EmailMissing from "../EmailMissing";
import ErrorMessage from "../ErrorMessage";
import FacebookApproval from "../FacebookApproval";
import UpdateEmailModal from "../UpdateEmailModal";

type ApprovalT = {
  approvalRequest: ApprovalRequestWithAuthorT;
  loginToFb?: string;
  setModalAttributes: Dispatch<SetStateAction<ModalAttributesT | undefined>>;
};

const Approval: FC<ApprovalT> = ({
  approvalRequest,
  loginToFb,
  setModalAttributes,
}) => {
  const {
    id: approvalRequestId,
    author: { email, nick_or_name },
    network_profile: { profile_id: influencerHandle },
    company_name,
    mentions,
    status,
  } = approvalRequest;

  const mentionAsString =
    mentions.length > 0 ? `@${mentions.join(", @")}` : "-";

  const cable = useContext<any>(ActionCableContext);
  const { session } = useContext(ApplicationContext);

  const [approvalPageState, setApprovalPageState] =
    useState<ApprovalPageStateT>("loading");
  const [urlForOath, setUrlForOath] = useState<string>();
  const [emailFromFacebook, setEmailFromFacebook] = useState("");
  const [forceOathClose, setForceOathClose] = useState(false);
  const [tacCheckBoxResetHelper, setTacCheckBoxResetHelper] = useState(false);

  const isAlreadyApproved = status === "processed";
  const globalDisable = approvalPageState === "session_mismatch";

  const { t } = useTranslate("influencer_root.approval_request.approval");

  const {
    data: autorizationResponse,
    refetch: authorizationCall,
    isLoading,
  } = useQuery(
    QueryKeys.AUTHORIZATION,
    () =>
      getApprovalRequestAuthorization(
        getUrlWithQuery(
          getApiUrl(ApiRoutesE.APPROVAL_REQUESTS_AUTHORIZATION_NEW),
          {
            approval_request_id: approvalRequestId,
            approved_mentions: mentions,
          },
        ),
      ),
    { enabled: false },
  );

  const getSessionInfluencerHandle = (): string | undefined => {
    const networkProfiles = session?.network_profiles || [];
    const networkHandle =
      networkProfiles.length > 0 &&
      networkProfiles.find(
        (network) =>
          (network.provider === "fb_instagram" ||
            network.provider === "instagram") &&
          network.profile_id,
      )?.profile_id;

    if (networkHandle) {
      return networkHandle;
    }

    return undefined;
  };

  useEffect(() => {
    authorizationCall();
  }, []);

  useEffect(() => {
    if (autorizationResponse) {
      const { uri, reason } = autorizationResponse;
      const sessionInfluencerHandle = getSessionInfluencerHandle();

      if (uri) {
        setUrlForOath(uri);
      }

      if (
        sessionInfluencerHandle &&
        sessionInfluencerHandle !== influencerHandle
      ) {
        setApprovalPageState("session_mismatch");
        return;
      }

      if (reason) {
        if (reason === "handle_mismatch") {
          setApprovalPageState("session_mismatch");
          return;
        }

        setApprovalPageState(reason);
        return;
      }

      setApprovalPageState("not_approved");
    }
  }, [autorizationResponse]);

  useEffect(() => {
    if (isLoading) {
      setApprovalPageState("loading");
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      approvalPageState === "email_missing" ||
      approvalPageState === "scopes_missing" ||
      approvalPageState === "handle_mismatch" ||
      approvalPageState === "user_cancelled"
    ) {
      setForceOathClose((prevState) => !prevState);
      return;
    }

    if (approvalPageState === "email_updated") {
      window.location.reload();
      return;
    }
  }, [approvalPageState]);

  useEffect(() => {
    const channelOptions = {
      channel: "ApprovalRequestChannel",
      approval_request_id: approvalRequestId,
    };

    const channel = cable.subscriptions.create(channelOptions, {
      received: (data: OathResponseObjectT) => {
        const { message, detail, authorization } = data;

        if (message === "authorization_success") {
          setApprovalPageState("already_connected");

          setForceOathClose((prevState) => !prevState);
          setTimeout(() => {
            window.location.reload();
          }, 50);

          return;
        }

        if (message === "authorization_error") {
          if (
            detail === "scopes_missing" ||
            detail === "handle_mismatch" ||
            detail === "user_cancelled" ||
            detail === "email_missing"
          ) {
            if (authorization) {
              setEmailFromFacebook(authorization.unconfirmed_email);
            }

            setTacCheckBoxResetHelper((prevState) => !prevState);
            setApprovalPageState(detail);
            return;
          }
        }
      },
    });

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const getContent = (): JSX.Element => {
    if (approvalPageState === "loading") {
      return <LoaderComponent />;
    }

    if (
      approvalPageState === "email_rejected" ||
      approvalPageState === "email_missing"
    ) {
      return <EmailMissing setApprovalPageState={setApprovalPageState} />;
    }

    if (
      (approvalPageState === "already_connected" && isAlreadyApproved) ||
      (approvalPageState === "token_expired" && isAlreadyApproved)
    ) {
      return (
        <ApprovedStatement
          mentions={mentionAsString}
          email={email || nick_or_name}
        />
      );
    }

    return (
      <FacebookApproval
        approvalRequestId={approvalRequestId}
        url={urlForOath}
        mentions={mentions}
        handle={influencerHandle}
        disableAllow={globalDisable}
        forceClose={forceOathClose}
        email={email || nick_or_name}
        company_name={company_name}
        resetTacCheckBox={tacCheckBoxResetHelper}
        setModalAttributes={setModalAttributes}
      />
    );
  };

  return (
    <>
      <Heading>{t("title")}</Heading>
      <ErrorMessage
        approvalRequestId={approvalRequestId}
        approvalPageState={approvalPageState}
        currentHandle={getSessionInfluencerHandle() || ""}
        influencerHandle={influencerHandle}
      />
      {getContent()}

      <Modal
        visible={approvalPageState === "email_missing"}
        close={() => setApprovalPageState("email_rejected")}
        slim={true}
        renderChildren={() => {
          return (
            <UpdateEmailModal
              approvalRequestId={approvalRequestId}
              initialEmail={emailFromFacebook}
              setApprovalPageState={setApprovalPageState}
            />
          );
        }}
      />
    </>
  );
};

export default Approval;
