import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ApprovalRequestWithAuthorT } from "../../types";

export const createApprovalRequests = async (
  payload: {
    approval_request: {
      partnership_id: string;
      mentions: string[];
      email?: string;
    };
  },
  clientId: string,
): Promise<ApprovalRequestWithAuthorT> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .post(getApiUrl(ApiRoutesE.APPROVAL_REQUESTS), payload, config)
    .then((res) => res.data.approval_request);
};
