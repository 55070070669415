import styled from "@emotion/styled";
import { Steps } from "antd";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import Container from "../../../../components/Container";
import FlexBox from "../../../../components/FlexBox";
import Loader from "../../../../components/Loader";
import SubMenuContainer from "../../../../components/SubMenuContainer";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { designToken } from "../../../../helpers/antDesign";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { getSubscriptionPlans } from "../../data-access/getSubscriptionPlans";
import { getSubscriptionProposal } from "../../data-access/getSubscriptionProposal";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import {
  SubscriptionPlanPriceT,
  SubscriptionPlanT,
  SubscriptionProposalT,
} from "../../types";
import SubscriptionPlans from "../subscription-plans/SubscriptionPlans";
import Checkout from "./Checkout";
import PersonalDetails from "./PersonalDetails";
import SubscriptionProposal from "./SubscriptionProposal";

const StyledSteps = styled(Steps)({
  "&.ant-steps": {
    height: rem(400),
  },

  ".ant-steps-item-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  ".ant-steps-item-tail": {
    display: "none",
    visibility: "hidden",
  },

  ".ant-steps-item:not(:last-child):after": {
    content: '" "',
    position: "absolute",
    backgroundColor: theme.color.greyColor,
    width: rem(1),
    height: rem(20),
    top: rem(86),
    left: rem(62),
  },
});

const Wrapper = styled(FlexBox)({
  minHeight: `calc(100dvh - ${rem(36)})`,
  alignItems: "stretch",
});

const StepWrapper = styled(FlexBox)({
  width: "100%",
  minHeight: "100%",
  backgroundColor: theme.color.whiteColor,
  borderRadius: designToken.borderRadiusLG,
});

const CheckoutWrapper = styled(StepWrapper)({
  alignItems: "flex-start",
});

enum WizardStepE {
  "personal-details" = 0,
  "plan" = 1,
  "checkout" = 2,
}

export type PlanPriceStateT = {
  plan: SubscriptionPlanT;
  price: SubscriptionPlanPriceT;
};

const WizardContainer: FC = () => {
  const [planPriceState, setPlanPriceState] = useState<PlanPriceStateT>();
  const [subscriptionProposal, setSubscriptionProposal] =
    useState<SubscriptionProposalT>();
  const [showSubmenu, setShowSubmenu] = useState<boolean>(true);

  const { session } = useContext(ApplicationContext);
  const subscription = session?.subscription;

  const match = useMatch(`${NavigationKeyE.Wizard}/:key`);
  const activeKey = match
    ? match.params.key || "personal-details"
    : "personal-details";

  const navigate = useRouterNavigate();
  const { t } = useTranslate("brands.wizard");

  useQuery(QueryKeys.SUBSCRIPTIONS_PLANS, () => getSubscriptionPlans());

  const { isLoading } = useQuery(
    QueryKeys.SUBSCRIPTIONS_PROPOSAL,
    () => getSubscriptionProposal(),
    {
      onSuccess: (data) => {
        data && setSubscriptionProposal(data);
      },
    },
  );

  const submenu = (width: number): JSX.Element => {
    return (
      <SubMenuContainer width={width}>
        <FlexBox fullHeight>
          <StyledSteps
            direction="vertical"
            labelPlacement="vertical"
            current={WizardStepE[activeKey as keyof typeof WizardStepE]}
            items={[
              {
                title: t("steps.personal_details"),
              },
              {
                title: t("steps.plan"),
              },
              {
                title: t("steps.checkout"),
              },
            ]}
          />
        </FlexBox>
      </SubMenuContainer>
    );
  };

  useEffect(() => {
    if (subscriptionProposal) {
      setShowSubmenu(false);
      navigate(NavigationKeyE.Wizard, "proposal", { replace: true });
    }
  }, [subscriptionProposal]);

  const getContent = (): JSX.Element => {
    if (isLoading) {
      return <Loader />;
    }
    return (
      <Container
        renderSubmenu={showSubmenu ? (width) => submenu(width) : undefined}
      >
        <Wrapper>
          <Routes>
            <Route
              path="personal-details"
              element={
                <StepWrapper>
                  <PersonalDetails />
                </StepWrapper>
              }
            />
            <Route
              path="plan"
              element={
                <StepWrapper>
                  <SubscriptionPlans
                    planPriceState={planPriceState}
                    setPlanPriceState={setPlanPriceState}
                  />
                </StepWrapper>
              }
            />
            <Route
              path="checkout"
              element={
                <CheckoutWrapper>
                  {planPriceState ? (
                    <Checkout
                      planPriceState={planPriceState}
                      setPlanPriceState={setPlanPriceState}
                    />
                  ) : (
                    <Navigate to={`${NavigationKeyE.Wizard}/plan`} replace />
                  )}
                </CheckoutWrapper>
              }
            />
            {subscriptionProposal && (
              <Route
                path="proposal"
                element={
                  <StepWrapper>
                    <SubscriptionProposal
                      subscriptionProposal={subscriptionProposal}
                    />
                  </StepWrapper>
                }
              />
            )}
          </Routes>
        </Wrapper>
      </Container>
    );
  };

  return (
    <>
      {subscription ? <Navigate to={NavigationKeyE.Database} /> : getContent()}
    </>
  );
};

export default WizardContainer;
