import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { PartnershipT } from "../types";

export const getPartnerships = async (
  clientId: string,
): Promise<PartnershipT[]> => {
  const config = {
    headers: {
      "Client-Id": clientId,
    },
  };

  return axios
    .get(getApiUrl(ApiRoutesE.PARTNERSHIPS), config)
    .then((res) => res.data.partnerships);
};
